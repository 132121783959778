import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import CoursesForm from './CoursesForm'
import CourseModuleForm from './CourseModuleForm'
import {CourseSectionForm} from './CourseSectionForm'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useDeleteCourse} from '../../../queries/courses'
import {useAuth} from '../../../modules/auth'

interface CourseDetailHeaderProps {
  course?: any
}

const CourseDetailHeader: React.FC<CourseDetailHeaderProps> = ({course}) => {
  const navigate = useNavigate()
  const {selectedCommunity} = useAuth()
  const {mutateAsync: deleteCourse, isPending: isDeletingCourse} = useDeleteCourse()
  const [showSectionFormModal, setShowSectionFormModal] = useState(false)
  const [showDeleteCourseModal, setShowDeleteCourseModal] = useState(false)
  const [showEditCourseModal, setShowEditCourseModal] = useState(false)
  const [showModuleFormModal, setShowModuleFormModal] = useState(false)
  const [selectedSectionId, setSelectedSectionId] = useState<any>(null)

  const handleDeleteCourse = async () => {
    try {
      await deleteCourse(course.uuid)
      navigate('/courses')
      setShowDeleteCourseModal(false)
    } catch {}
  }

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <p className='text-fs-28 fw-bold p-0 m-0' title={course.title}>
            {course.title?.length > 24 ? course.title?.slice(0, 24) + '...' : course.title}
          </p>
        </div>
        {selectedCommunity?.permissions.canCreateEditCourse && (
          <div className='d-flex gap-2'>
            {selectedCommunity?.permissions.canDeleteCourse && (
              <button
                className='px-5 py-1 bg-transparent border rounded border-secondary d-flex gap-2 flex-row align-items-center'
                onClick={() => setShowDeleteCourseModal(true)}
              >
                <img src='/media/svg/general/delete.svg' alt='' width={'20px'} height={'20px'} />
                <span className='text-fs-13 text-black fw-bold'>Delete Course</span>
              </button>
            )}
            <button
              className='px-5 py-1 bg-transparent border rounded border-secondary d-flex gap-2 flex-row align-items-center'
              onClick={() => setShowEditCourseModal(true)}
            >
              <img src='/media/svg/general/edit.svg' alt='' width={'20px'} height={'20px'} />
              <span className='text-fs-13 text-black fw-bold'>Edit Course</span>
            </button>
            <button
              className='btn btn-primary d-flex flex-row align-items-center'
              onClick={() => setShowSectionFormModal(true)}
            >
              <i className='fa-solid fa-add add-pipeline-icon m-0'></i>
              <span className='text-fs-13 text-white fw-bold'>Add Section</span>
            </button>
            <button
              className='btn btn-primary d-flex flex-row align-items-center'
              onClick={() => setShowModuleFormModal(true)}
            >
              <i className='fa-solid fa-add add-pipeline-icon m-0'></i>
              <span className='text-fs-13 text-white fw-bold'>Add Module</span>
            </button>
          </div>
        )}
      </div>
      {showSectionFormModal && (
        <CourseSectionForm
          showModal={showSectionFormModal}
          onHideModal={() => setShowSectionFormModal(false)}
          course={course}
        />
      )}
      {showDeleteCourseModal && (
        <ConfirmationModal
          showModal={showDeleteCourseModal}
          subtitle='Are you sure you want to delete this course?'
          onHideModal={() => setShowDeleteCourseModal(false)}
          disableBtns={isDeletingCourse}
          onConfirm={handleDeleteCourse}
          onCancel={() => setShowDeleteCourseModal(false)}
        />
      )}
      {showEditCourseModal && (
        <CoursesForm
          course={course}
          show={showEditCourseModal}
          handleClose={() => setShowEditCourseModal(false)}
        />
      )}
      {showModuleFormModal && (
        <CourseModuleForm
          show={showModuleFormModal}
          handleClose={() => {
            setSelectedSectionId(null)
            setShowModuleFormModal(false)
          }}
          course={course}
          sectionId={selectedSectionId}
        />
      )}
    </div>
  )
}

export default CourseDetailHeader
