import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import {useAuth} from '../../../modules/auth'
import {AsideMenuItem} from './AsideMenuItem'
import SaveSearchIndex from './saveSearch/SaveSearchIndex'

import './aside.css'

export function AsideMenuMain() {
  const {selectedCommunity, unreadPipelineNotificationsCount} = useAuth()
  const isPremiumUser = selectedCommunity?.isPremiumUser

  const renderSvgIconWithTooltip = (iconPath: string, tooltipText: string) => (
    <OverlayTrigger
      placement='bottom'
      overlay={<Tooltip id={`tooltip-${tooltipText.toLowerCase()}`}>{tooltipText}</Tooltip>}
    >
      <img src={iconPath} alt={tooltipText} />
    </OverlayTrigger>
  )

  return (
    <>
      <AsideMenuItem
        to='/feeds/public'
        title='Community'
        svgIcon={renderSvgIconWithTooltip('/media/svg/general/users.svg', 'Community')}
      />
      {isPremiumUser && (
        <>
          <AsideMenuItem
            to='/pipeline'
            title='Pipelines'
            titleChildren={
              unreadPipelineNotificationsCount > 0 && (
                <span
                  className='notification-dot bg-danger pipeline-notification'
                  style={{marginTop: '-15px'}}
                ></span>
              )
            }
            className='position-relative'
            svgIcon={renderSvgIconWithTooltip('/media/svg/general/chart.svg', 'Pipelines')}
          />
          <AsideMenuItem
            to='/courses'
            title='Classroom'
            svgIcon={renderSvgIconWithTooltip('/media/svg/general/course.svg', 'Classroom')}
          />
        </>
      )}
      <AsideMenuItem
        to='/calendar'
        title='Calendar'
        svgIcon={renderSvgIconWithTooltip('/media/svg/general/calendar.svg', 'Calendar')}
      />
      {isPremiumUser && (
        <>
          {!selectedCommunity?.isTeamMember && (
            <AsideMenuItem
              to='/support'
              title='Support'
              svgIcon={renderSvgIconWithTooltip('/media/svg/general/information.svg', 'Support')}
            />
          )}
        </>
      )}
      <AsideMenuItem
        to='/notes'
        title='Notes'
        svgIcon={renderSvgIconWithTooltip('/media/svg/general/medal-star.svg', 'Notes')}
      />
      <AsideMenuItem
        to='/resources'
        title='Resources'
        svgIcon={renderSvgIconWithTooltip('/media/svg/general/scan-barcode.svg', 'Resources')}
      />
      {isPremiumUser && (
        <AsideMenuItem
          to='/govchat'
          title='Govchat'
          svgIcon={renderSvgIconWithTooltip('/media/svg/general/chat.svg', 'Govchat')}
        />
      )}
      <AsideMenuItem
        to='/community'
        title='Member Directory'
        svgIcon={renderSvgIconWithTooltip(
          '/media/svg/general/address-book.svg',
          'Member Directory'
        )}
      />
      <AsideMenuItem
        to='/leaderboard'
        title='Leaderboard'
        svgIcon={renderSvgIconWithTooltip('/media/svg/general/barcode.svg', 'Leaderboard')}
      />
      <div className='menu-item'>
        <div className='menu-content d-flex flex-row justify-content-between align-items-center'>
          <p className='text-gray-700 m-0 p-0'>Saved Search</p>
        </div>
      </div>

      <div className='menu-item'>
        <SaveSearchIndex />
      </div>
    </>
  )
}
