import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import parse from 'html-react-parser'
import {Card, ListGroup, Spinner} from 'react-bootstrap'

import FeedDetailModal from './FeedDetailModal'
import {useGetTrendingFeeds} from '../../../queries/feeds'
import Image from '../../../modules/common/Image'

import '../feeds.css'

const TrendingFeeds = () => {
  const [selectedFeed, setSelectedFeed] = useState<any>(null)
  const [showFeedDetailModal, setShowFeedDetailModal] = useState(false)
  const {data: trendingFeeds, isFetching} = useGetTrendingFeeds()

  const handleFeedClick = (event: React.MouseEvent, feed: any) => {
    event.preventDefault()
    setSelectedFeed(feed)
    setShowFeedDetailModal(true)
  }

  return (
    <>
      <Card
        style={{minWidth: '22rem', maxWidth: '420px'}}
        className='rounded card-moving border border-2'
      >
        <Card.Header className='bg-gradient-primary min-h40 py-3 rounded'>
          <h4 className='m-0 text-light'>Trending Posts (7-Days)</h4>
        </Card.Header>
        <ListGroup variant='flush' className='bg-transparent sidebar-widget-content'>
          {trendingFeeds?.length === 0 && (
            <ListGroup.Item className='fs-5 text-start text-gray-700'>
              No Record Found
            </ListGroup.Item>
          )}
          {!trendingFeeds && isFetching && (
            <Spinner animation='border' className='m-3' variant='primary' />
          )}
          {trendingFeeds?.map((feed: any, index: number) => (
            <ListGroup.Item key={index} className='d-flex py-3 mx-4'>
              <Image
                src={`${feed.user?.absoluteProfilePath}`}
                className=' img-fluid w50 h50 rounded-circle object-cover'
              />
              <div className='mx-4'>
                <p
                  className={`text-fs-12 fw-bolder text-start feed-title-highlighter m-0`}
                  onClick={(event) => handleFeedClick(event, feed)}
                >
                  {feed.title.substring(0, 30)}
                </p>
                <Link
                  to={`/view-profile/${feed.user?.uuid}`}
                  className='text-fs-12 fw-bold text-gray-700'
                >
                  {feed.user?.firstName} {feed.user?.lastName}
                </Link>
                {feed?.description && (
                  <p className={`text-fs-12 text-start m-0 text-gray-700`}>
                    {feed.description?.length > 20
                      ? parse(feed.description.slice(0, 20) + '...')
                      : parse(feed.description)}
                  </p>
                )}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card>
      {showFeedDetailModal && (
        <FeedDetailModal
          feed={selectedFeed}
          showFeedDetailModal={showFeedDetailModal}
          handleModalClose={() => setShowFeedDetailModal(false)}
        />
      )}
    </>
  )
}

export default TrendingFeeds
