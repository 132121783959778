export const RESOURCES_QUERY_KEY = 'resources'
export const RESOURCE_BY_ID_QUERY_KEY = 'resourceById'
export const THREAD_USERS_QUERY_KEY = 'thread_users'
export const THREADS_QUERY_KEY = 'threads'
export const USER_DETAIL_QUERY_KEY = 'user_detail'
export const THREAD_MESSAGES_QUERY_KEY = 'get_thread_messages'
export const COURSES_QUERY_KEY = 'courses'
export const COURSE_BY_ID_QUERY_KEY = 'course_by_id'
export const EVENTS_QUERY_KEY = 'events'
export const USER_QUERY_KEY = 'users'
export const USER_STRIPE_PORTAL_QUERY_KEY = 'users_stripe_portal'
export const MESSAGES_QUERY_KEY = 'messages'
export const FEEDS_QUERY_KEY = 'feeds'
export const FEED_LIKE_QUERY_KEY = 'feeds_likes'
export const COMMENTS_QUERY_KEY = 'comments'
export const CATEGORIES_QUERY_KEY = 'categories'
export const INVITES_QUERY_KEY = 'invites'
export const NOTES_QUERY_KEY = 'notes'
export const COMMUNITY_QUERY_KEY = 'communities'
export const COMMUNITY_LEADERBOARD_QUERY_KEY = 'communities_leaderboards'
export const COMMUNITY_LEADERBOARD_TYPE_QUERY_KEY = 'communities_leaderboards_type'
export const AI_CHAT_QUERY_KEY = 'ai_chat'
export const AI_CHAT_MESSAGE_QUERY_KEY = 'ai_chat_message'
export const NOTIFICATIONS_QUERY_KEY = 'notifications'
export const NOTIFICATIONS_SETTINGS_QUERY_KEY = 'notifications_settings'
export const NOTIFICATIONS_COUNT_QUERY_KEY = 'notifications_count'
export const PIPELINES_QUERY_KEY = 'pipelines'
export const PIPELINES_HISTORY_QUERY_KEY = 'pipelines_history'
export const PIPELINES_COMMENTS_QUERY_KEY = 'pipelines_comments'
export const TEAM_MEMBER_QUERY_KEY = 'team_members'
export const PIPELINE_SHARE_QUERY_KEY = 'pipeline_shares'
export const PIPELINE_NOTIFICATIONS_COUNT_QUERY_KEY = 'pipeline_notifications_count'
export const PIPELINE_NOTIFICATIONS_QUERY_KEY = 'pipeline_notifications'
export const USER_SEARCHES_QUERY_KEY = 'user_searches'
