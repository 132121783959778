import {useState, FC} from 'react'
import {useDropzone} from 'react-dropzone'
import {toast} from 'react-toastify'
import {ProgressBar} from 'react-bootstrap'
import {useQueryClient} from '@tanstack/react-query'
import {useSearchParams} from 'react-router-dom'

import {uploadResource} from '../../../apis/resources'
import {RESOURCES_QUERY_KEY} from '../../../constants/reactQueryKeys'
import RenderFileIcon from '../../../modules/common/RenderFileIcon'

const acceptedFileTypes = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'image/gif',
  'text/csv',
]

interface UploadResourceProps {
  isUploading: any
  setIsUploading: any
}

const UploadResource: FC<UploadResourceProps> = ({isUploading, setIsUploading}) => {
  const [searchParams] = useSearchParams()

  const [progress, setProgress] = useState<
    {filename: string; progress: number; uploaded: boolean; error: boolean; fileType: string}[]
  >([])
  const queryClient = useQueryClient()

  const onUploadProgress = (progressEvent: ProgressEvent, filename: string) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    setProgress((prevProgress) => {
      const updatedProgress = [...prevProgress]
      const index = updatedProgress.findIndex((item) => item.filename === filename)
      if (index !== -1) {
        updatedProgress[index].progress = percentCompleted
      }
      return updatedProgress
    })
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: acceptedFileTypes as any,
    onDrop: async (acceptedFiles, fileRejections) => {
      const validFiles = acceptedFiles.filter((file) => acceptedFileTypes.includes(file.type))
      if (validFiles.length === 0) {
        toast.error('Invalid file type(s)', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        return
      }

      if (validFiles.length > 10) {
        toast.error('You can only upload up to 10 files at a time.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        return
      }

      setIsUploading(true)
      setProgress(
        acceptedFiles.map((file) => ({
          filename: file.name,
          progress: 0,
          uploaded: false,
          error: false,
          fileType: file.type,
        }))
      )

      for (const file of acceptedFiles) {
        try {
          const folderId = searchParams.get('folderId')
          await uploadResource({file, folderId}, (progressEvent) =>
            onUploadProgress(progressEvent, file.name)
          )
          setProgress((prevProgress) => {
            const updatedProgress = [...prevProgress]
            const index = updatedProgress.findIndex((item) => item.filename === file.name)
            if (index !== -1) {
              updatedProgress[index].uploaded = true
            }
            return updatedProgress
          })
          queryClient.invalidateQueries({queryKey: [RESOURCES_QUERY_KEY]})
        } catch (error) {
          setProgress((prevProgress) => {
            const updatedProgress = [...prevProgress]
            const index = updatedProgress.findIndex((item) => item.filename === file.name)
            if (index !== -1) {
              updatedProgress[index].error = true
            }
            return updatedProgress
          })
        }
      }
      setIsUploading(false)
    },
    disabled: isUploading,
  })

  return (
    <div className='card p-12 py-3 text-center d-flex justify-content-center align-items-center'>
      <div>
        <h1 className='text-primary'>Upload files</h1>
      </div>
      {isUploading ? (
        <div
          {...getRootProps()}
          style={{opacity: 0.5}}
          className='resources-card rounded w-75 cursor-not-allow'
        >
          <input {...getInputProps()} />
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='#0095E8'
            className='bi bi-cloud-arrow-up-fill w-50'
            viewBox='0 0 16 16'
          >
            <path d='M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z' />
          </svg>
          <div>
            {isDragActive && <h3 className='text-primary'>Drop the files here ...</h3>}
            {!isDragActive && !isUploading && (
              <h3 className='text-primary'>Drag and drop your files here</h3>
            )}
            {!isDragActive && isUploading && (
              <h3 className='text-primary'>
                Please wait until the current uploads <br /> are complete before adding more files.
              </h3>
            )}
          </div>
        </div>
      ) : (
        <div {...getRootProps()} className='resources-card rounded cursor-pointer w-75'>
          <input {...getInputProps()} />
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='#0095E8'
            className='bi bi-cloud-arrow-up-fill w-50'
            viewBox='0 0 16 16'
          >
            <path d='M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z' />
          </svg>
          <div>
            {isDragActive && <h3 className='text-primary'>Drop the files here ...</h3>}
            {!isDragActive && !isUploading && (
              <h3 className='text-primary'>Drag and drop your files here</h3>
            )}
            {!isDragActive && isUploading && (
              <h3 className='text-primary'>
                Please wait until the current uploads <br /> are complete before adding more files.
              </h3>
            )}
          </div>
        </div>
      )}
      {progress.length > 0 && (
        <div style={{width: '70%'}} className='bg-light resources-card py-12 px-7 rounded my-4'>
          <div>
            {progress.map((item, index) => (
              <div
                style={{backgroundColor: 'white', borderRadius: '15px'}}
                className='p-5 my-3'
                key={index}
              >
                <div className='d-flex justify-content-between align-items-center'>
                  <div style={{color: 'black'}}>
                    {item.fileType && <RenderFileIcon fileType={item.fileType} className={''} />}
                    {item.filename.length > 25 ? `${item.filename.slice(0, 25)}...` : item.filename}
                  </div>
                  <div>
                    {!item.uploaded && !item.error && isUploading && (
                      <div className='spinner-border text-primary' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    )}
                    {item.error && <i className='fa-solid fs-2 mx-2 fa-x'></i>}
                    {item.uploaded && <i className='fa-solid fs-2 mx-2 fa-check'></i>}
                  </div>
                </div>
                <div className='mt-4'>
                  {!item.uploaded && !item.error && (
                    <ProgressBar
                      style={{width: '100%'}}
                      now={100}
                      animated
                      label={'Uploading...'}
                    />
                  )}
                  {item.uploaded && !item.error && (
                    <ProgressBar
                      style={{width: '100%'}}
                      variant='success'
                      now={100}
                      label={'Uploaded'}
                    />
                  )}
                  {item.error && (
                    <ProgressBar
                      style={{width: '100%'}}
                      variant='danger'
                      now={100}
                      label={'Upload Failed'}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default UploadResource
