import {useState, useEffect} from 'react'
import {useAuth} from '../../modules/auth'
import {useNavigate, useSearchParams} from 'react-router-dom'
import CategoriesIndex from '../categories/CategoriesIndex'
import InvitesIndex from '../invites/InvitesIndex'
import CommunitySettingForm from './components/CommunitySettingForm'
import CommunityGamification from './components/CommunityGamification'

function CommunitySettingsIndex() {
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')
  const navigate = useNavigate()
  const {selectedCommunity} = useAuth()
  const [currentTab, setCurrentTab] = useState(tab || 'general')
  const canManageCategories = selectedCommunity?.isCommunityAdmin
  const canManageInvites = selectedCommunity?.isCommunityAdmin

  useEffect(() => {
    setCurrentTab(tab || 'general')
  }, [tab])

  useEffect(() => {
    if (currentTab === 'categories' && !canManageCategories) {
      navigate('?tab=profile')
    }
  }, [currentTab, canManageCategories])

  return (
    <div className='d-flex' id='kt_profile_details_view'>
      <div className='p-9 rounded w300 h-fit mr10 bg-white'>
        <h3 className='text-center mb-3'>Community Settings</h3>
        <ul className='nav flex-column'>
          <li className='nav-item'>
            <button
              onClick={() => navigate('?tab=general')}
              className={`btn rounded-0 m-1 p-4 w-100 ${
                currentTab === 'general' ? 'btn-secondary' : 'btn-transparent'
              }`}
            >
              General
            </button>
          </li>
          {canManageInvites && (
            <li className='nav-item'>
              <button
                onClick={() => navigate('?tab=invites')}
                className={`btn rounded-0 m-1 p-4 w-100 ${
                  currentTab === 'invites' ? 'btn-secondary' : 'btn-transparent'
                }`}
              >
                Invites
              </button>
            </li>
          )}
          {canManageCategories && (
            <li className='nav-item'>
              <button
                onClick={() => navigate('?tab=categories')}
                className={`btn rounded-0 m-1 p-4 w-100 ${
                  currentTab === 'categories' ? 'btn-secondary' : 'btn-transparent'
                }`}
              >
                Categories
              </button>
            </li>
          )}
          <li className='nav-item'>
            <button
              onClick={() => navigate('?tab=gamification')}
              className={`btn rounded-0 m-1 p-4 w-100 ${
                currentTab === 'gamification' ? 'btn-secondary' : 'btn-transparent'
              }`}
            >
              Gamification
            </button>
          </li>
        </ul>
      </div>
      {currentTab === 'invites' && canManageCategories && <InvitesIndex />}
      {currentTab === 'general' && <CommunitySettingForm />}
      {currentTab === 'gamification' && <CommunityGamification />}
      {currentTab === 'categories' && canManageInvites && <CategoriesIndex />}
    </div>
  )
}

export default CommunitySettingsIndex
