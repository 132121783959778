import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'

import '../CoursesApp.scss'

interface DetailModal {
  showModuleDetailModal: boolean
  setShowModuleDetailModal: any
  sectionModule: any
}

const ModuleDetailModal: React.FC<DetailModal> = ({
  showModuleDetailModal,
  setShowModuleDetailModal,
  sectionModule,
}) => {
  const [showTranscript, setShowTranscript] = useState(false)
  return (
    <div>
      <Modal
        contentClassName='module-modal'
        fullscreen
        show={showModuleDetailModal}
        onHide={() => setShowModuleDetailModal(false)}
      >
        <Modal.Header className='border-0'>
          <Modal.Title className='text-light'>{sectionModule?.title}</Modal.Title>
          <button
            type='button'
            className='text-light bg-transparent border-0 fw-bold fs-3'
            onClick={() => setShowModuleDetailModal(false)}
          >
            <i className='fa-solid fs-4 fa-x text-light'></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={sectionModule.videoUrl}
            className='rounded view-module-fullscreen'
            title='Module Video'
            style={{width: '100%', height: '75vh'}}
            allowFullScreen
          ></iframe>

          {/* Render Transcript */}
          <div className='w-100 mt-6'>
            {sectionModule.transcript.length > 0 && <h1 className='text-light'>Transcript: </h1>}
            {sectionModule.transcript.length > 400 ? (
              <div>
                {!showTranscript && (
                  <p
                    className='text-light w-80 fs-5'
                    dangerouslySetInnerHTML={{
                      __html: sectionModule.transcript.slice(0, 400) + '...',
                    }}
                  ></p>
                )}
                {showTranscript && (
                  <p
                    className='text-light w-80 fs-5'
                    dangerouslySetInnerHTML={{__html: sectionModule.transcript}}
                  ></p>
                )}
                <button
                  onClick={() => {
                    if (!showTranscript) {
                      setShowTranscript(true)
                    } else {
                      setShowTranscript(false)
                    }
                  }}
                  className='border-0 bg-light text-dark text-start'
                >
                  {showTranscript ? 'Compress' : 'Expand'}
                </button>
              </div>
            ) : (
              <p
                className='text-light w-80 fs-5'
                dangerouslySetInnerHTML={{__html: sectionModule.transcript}}
              ></p>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ModuleDetailModal
