import {Formik, Field, ErrorMessage} from 'formik'
import React from 'react'
import {Modal, Form, Button} from 'react-bootstrap'
import {useSearchParams} from 'react-router-dom'
import * as Yup from 'yup'

import {usePostResourcesFolder} from '../../../queries/resources'

interface FolderFormProps {
  showFolderModal: boolean
  handleClose: () => void
  updateFolder?: any
  selectedResource: any
}

const FolderForm: React.FC<FolderFormProps> = ({
  showFolderModal,
  handleClose,
  updateFolder,
  selectedResource,
}) => {
  const [searchParams] = useSearchParams()
  const folderId = searchParams.get('folderId')
  const validationSchema = Yup.object({
    name: Yup.string().required('Folder name is required'),
  })
  const resourceId = selectedResource?.uuid

  const {mutate: postFolder} = usePostResourcesFolder()
  return (
    <Modal show={showFolderModal} onHide={handleClose} centered backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{resourceId ? 'Edit Folder' : 'Add a Folder'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-5 py-3'>
        <Formik
          initialValues={{name: selectedResource?.name || ''}}
          validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            setSubmitting(true)
            try {
              if (!resourceId) {
                postFolder(folderId ? {...values, folderId} : values)
              } else {
                const folderData = {name: values.name}
                updateFolder({folderId: resourceId, folderData})
              }
              resetForm()
            } catch (error) {
              console.error('Error handling folder action:', error)
            } finally {
              setSubmitting(false)
              handleClose()
            }
          }}
        >
          {({isSubmitting, handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label htmlFor='name' className='fw-bold fs-6 required'>
                  Folder Name
                </label>
                <Field
                  type='text'
                  name='name'
                  className='form-control'
                  placeholder='Enter name of your folder'
                />
                <ErrorMessage name='name' component='div' className='text-danger' />
              </div>
              <div className='d-end mt-4'>
                <Button type='button' variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button type='submit' variant='primary' className='ml15' disabled={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default FolderForm
