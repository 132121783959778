const FetchingSpinner = ({text = 'Fetching...'}) => {
  return (
    <div className='mx-0 px-0 py-7 card h-100 w-100'>
      <div className='container d-flex align-items-center justify-content-between'>
        {text}
        <svg className='spinner' viewBox='25 25 50 50' width={'25px'} height={'25px'}>
          <circle className='spinner-inner' r='20' cy='50' cx='50'></circle>
        </svg>
      </div>
    </div>
  )
}

export default FetchingSpinner
