import {useState, KeyboardEvent, useEffect} from 'react'
import clsx from 'clsx'
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {Button, Tooltip} from 'react-bootstrap'

import {useLayout} from '../../core'
import {Topbar} from './Topbar'
import {useAuth} from '../../../modules/auth'
import HeaderMessage from './HeaderMessage'
import {isProfileIncomplete} from '../../../modules/common/utils'
import HeaderNotification from './HeaderNotification'
import {KTIcon} from '../../../../_metronic/helpers'

import './HeaderWrapper.css'

const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL

export function HeaderWrapper() {
  const {classes, attributes, config} = useLayout()
  const {aside} = config
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const q = searchParams.get('q') || ''
  const {currentUser, selectedCommunity} = useAuth()
  const [search, setSearch] = useState(q)
  const btnClass = 'btn btn-icon text btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
  const isProfileIncompleted = isProfileIncomplete(currentUser)

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // If Enter key is pressed, perform search
      onSearch()
    }
  }

  const onSearch = () => {
    search && navigate(`/search?q=${search}`)
  }

  useEffect(() => {
    setSearch(q)
  }, [q])

  return (
    <div
      id='kt_header'
      className={clsx(
        'header',
        classes.header.join(' '),
        'align-items-stretch',
        'header-wrapper',
        'bg-light'
      )}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div className='header-brand' style={{borderRight: '1px solid rgb(229 229 229)'}}>
        {/* begin::Logo */}
        <Link to='/'>
          <div className='d-flex align-items-center'>
            {/* <img
              className='w40 h40 rounded-circle object-cover object-top '
              alt=''
              src={`${
                selectedCommunity?.iconPic
                  ? `${s3BaseUrl}${selectedCommunity?.iconPic}`
                  : '/media/misc/menu-header-bg.jpg'
              }`}
            /> */}
            <img src='/media/svg/general/community.svg' alt='' className='community-icon' />
            <h5
              style={{fontSize: '22px'}}
              className='text-primary mx-3 fw-bold d-flex align-items-center community-name'
            >
              {selectedCommunity?.name}
            </h5>
          </div>
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <button className='rounded-circle border border-transparent d-flex align-items-center collapse-button'>
              <i className='fa-solid fa-chevron-left fs-9 text-primary me-n1 minimize-default'></i>
            </button>
            <i className='fa-solid fa-chevron-right fs-9 text-primary minimize-active collapse-icon'></i>
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTIcon iconName='abstract-14' className='fs-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      <div className='w-100' style={{padding: '16px 24px 0px 24px'}}>
        <div
          className={`lg-header d-md-flex w-100 align-items-center ${
            location.pathname.includes('pipeline') ? 'full-header' : ''
          }`}
        >
          <div className='w-100 main-header'>
            <div className='d-flex justify-content-center align-items-center rounded bg-white main-header__items'>
              <img
                src='/media/svg/general/search.svg'
                alt=''
                className={`ml20 ${isProfileIncompleted && 'incomplete-profile'}`}
                width={'24px'}
                height={'24px'}
              />
              <input
                type='text'
                className={`form-control text-fs-16 border-0 bg-transparent ${
                  isProfileIncompleted && 'incomplete-profile'
                }`}
                placeholder='Search'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={isProfileIncompleted}
              />
              <div className={clsx('d-flex align-items-center mx-6 gap-1')}>
                <div className={` ${isProfileIncompleted && 'incomplete-profile'}`}>
                  <HeaderMessage btnClass={btnClass} />
                </div>
                <div>
                  <HeaderNotification />
                </div>
                <Topbar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
