import React, {useState} from 'react'
import {Row, Col} from 'react-bootstrap'

import CoursesForm from './components/CoursesForm'
import CourseItem from './components/CourseItem'
import {useGetCourses, useUpdateCourseOrder} from '../../queries/courses'
import {useAuth} from '../../modules/auth'

function CoursesIndex() {
  const {selectedCommunity} = useAuth()
  const [showForm, setShowForm] = useState(false)
  const updateCourseMutation = useUpdateCourseOrder()
  const coursesResult = useGetCourses()

  const handleShowForm = () => {
    setShowForm(true)
  }

  const courses = coursesResult.data?.courses || []

  return (
    <div>
      {updateCourseMutation.isPending && (
        <div
          className='position-absolute w-100 h-100'
          style={{
            top: 0,
            left: 0,
            zIndex: 999,
            backgroundColor: 'rgba(233, 233, 233, 0.156)',
          }}
        />
      )}

      <div className='d-flex justify-content-between align-items-center mb-4'>
        <p className='text-fs-28 fw-bold'>Classroom</p>
        {selectedCommunity?.permissions.canCreateEditCourse && (
          <button
            className='btn btn-primary d-flex flex-row align-items-center'
            onClick={handleShowForm}
          >
            <i className='fa-solid fa-add add-pipeline-icon m-0'></i>
            <span>New Course</span>
          </button>
        )}
      </div>

      <Row style={{opacity: updateCourseMutation.isPending ? '0.6' : '1'}}>
        {courses?.map((course: any, index: number) => (
          <React.Fragment key={course.id}>
            <CourseItem
              course={course}
              totalCourses={courses.length}
              updateCourseOrder={updateCourseMutation.mutateAsync}
            />
          </React.Fragment>
        ))}
      </Row>
      <CoursesForm show={showForm} handleClose={() => setShowForm(false)} />
    </div>
  )
}

export default CoursesIndex
