import moment from 'moment'
import {Fragment} from 'react'
import Highlighter from 'react-highlight-words'
import {Link} from 'react-router-dom'
import Image from '../../../modules/common/Image'

function MemberCard({user, searchKeyword}: any) {
  return (
    <div
      key={user?.id}
      style={{width: '100%'}}
      className='connection-card rounded text-center border py-2 px-5 m-2'
    >
      <div className='d-flex'>
        <div className='py-3'>
          <Image src={`${user.absoluteProfilePath}`} />
        </div>
        <div className='text-start mx-5 py-3 w-100'>
          <Link to={`/view-profile/${user?.uuid}`} className='menu-link card-title fs-4 fw-bold'>
            <Highlighter
              highlightClassName='gov-highlight'
              searchWords={[searchKeyword]}
              autoEscape={true}
              textToHighlight={`${user.firstName} ${user.lastName}`}
            />
          </Link>
          <p className='mb-1'>
            <Highlighter
              highlightClassName='gov-highlight'
              searchWords={[searchKeyword]}
              autoEscape={true}
              textToHighlight={`${user.companyName || ''}`}
            />
          </p>
          <p className='text-black fw-bold'>
            <Highlighter
              highlightClassName='gov-highlight'
              searchWords={[searchKeyword]}
              autoEscape={true}
              textToHighlight={`${user.bio || ''}`}
            />
          </p>

          <div className='d-flex justify-content-between'>
            <div className='w-50'>
              <div className='d-flex align-items-center my-2 justify-content-start'>
                <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                  <i className='text-primary fa-solid fa-envelope'></i>
                </span>
                <p className='card-text mx-2 text-gray fw-bold'>
                  <Highlighter
                    highlightClassName='gov-highlight'
                    searchWords={[searchKeyword]}
                    autoEscape={true}
                    textToHighlight={`${user.email}`}
                  />
                </p>
              </div>
              <div className='d-flex align-items-center my-3 justify-content-start'>
                <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                  <i className='text-primary fa-solid fa-location-dot'></i>
                </span>
                <p className='card-text mx-2 text-gray fw-bold'>
                  <Highlighter
                    highlightClassName='gov-highlight'
                    searchWords={[searchKeyword]}
                    autoEscape={true}
                    textToHighlight={`${`${user.state || ''} ${user.city || ''} `}`}
                  />
                </p>
              </div>
            </div>

            <div className='w-50'>
              <div className='d-flex align-items-center my-2 justify-content-start'>
                <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                  <i className='text-primary fa-solid fa-calendar'></i>
                </span>
                <p className='card-text mx-2 text-gray fw-bold'>
                  <Highlighter
                    highlightClassName='gov-highlight'
                    searchWords={[searchKeyword]}
                    autoEscape={true}
                    textToHighlight={`Join ${moment.unix(user.createdAt).format('MMM DD, YYYY')}`}
                  />
                </p>
              </div>
              <div className='d-flex align-items-start my-3 justify-content-start'>
                <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                  <i className='text-primary fa-solid fa-link'></i>
                </span>
                <p className='card-text mx-2 text-gray fw-bold'>
                  {user?.userWebsites?.map((website: any, index: number) => (
                    <Fragment key={index}>
                      <a
                        href={website.websiteUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='fw-bold cursor-pointer fs-6 word-wrap'
                      >
                        <Highlighter
                          highlightClassName='gov-highlight'
                          searchWords={[searchKeyword]}
                          autoEscape={true}
                          textToHighlight={website.websiteUrl}
                        />
                      </a>
                      {index < user.userWebsites.length - 1 && <span> </span>}
                    </Fragment>
                  ))}
                </p>
              </div>
            </div>
          </div>
          {user?.userCertifications.length > 0 && (
            <div className='d-flex align-items-start my-3 justify-content-start'>
              <span className='bg-blue-100 mr-2 py-1 px-2 rounded-circle'>
                <i className='text-primary fa-solid fa-file'></i>
              </span>
              <p className='card-text mx-2 text-gray fw-bold'>
                <span className='fw-bold fs-6'>
                  <Highlighter
                    highlightClassName='gov-highlight'
                    searchWords={[searchKeyword]}
                    autoEscape={true}
                    textToHighlight={user?.userCertifications
                      ?.map((certification: any) => certification?.certificate?.name)
                      .join(', ')}
                  />
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MemberCard
