import {useState} from 'react'
import moment from 'moment'

import {useFetchEvents} from '../../queries/events'
import {useAuth} from '../auth'
import {getTimeUntilEvent} from './utils'
import EventDetailModal from '../../pages/events/components/EventDetailModal'

const RecentAnnouncement = () => {
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [showEventDetailModal, setShowEventDetailModal] = useState(false)
  const {getUserTimezone, selectedCommunity} = useAuth()
  const startDate = moment().format('YYYY-MM-DD')
  const endDate = moment().add(1, 'day').format('YYYY-MM-DD')
  const {data: upcomingEvent} = useFetchEvents(startDate, endDate, 1)

  const recentEvent = upcomingEvent?.events.find((event: any) => {
    const eventEndMoment = moment.utc(event.endDate).tz(getUserTimezone())
    return moment().isBefore(eventEndMoment)
  })

  const calculateEventTime = recentEvent
    ? getTimeUntilEvent({
        eventStartDate: recentEvent.startDate,
        timezone: getUserTimezone(),
      })
    : null

  return (
    <>
      {recentEvent && (
        <div className='d-flex justify-content-center align-items-center mt-4 mb-3'>
          <img src='/media/svg/general/calendar-icon.svg' alt='calendar-icon' />
          <h4 className='m-0 ms-3'>
            <span
              className='text-decoration-underline cursor-pointer'
              onClick={() => {
                setSelectedEvent(recentEvent)
                setShowEventDetailModal(true)
              }}
            >
              {recentEvent.title}
            </span>{' '}
            is happening in {calculateEventTime}
          </h4>
        </div>
      )}

      {showEventDetailModal && (
        <EventDetailModal
          showEventDetailModal={showEventDetailModal}
          handleEventDetailModalClose={() => {
            setShowEventDetailModal(false)
            setSelectedEvent(null)
          }}
          selectedEvent={selectedEvent}
          selectedCommunity={selectedCommunity}
          setShowEventDetailModal={setShowEventDetailModal}
          showActions={false}
        />
      )}
    </>
  )
}

export default RecentAnnouncement
