import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

import CustomDropdown from '../../../modules/common/CustomDropDown'
import ModuleNotes from '../../courses/components/notes/ModuleNotes'
import {statusOptions, statusClassMap} from '../pipelines.utils'
import GenericModal from '../../../modules/common/Modal'
import SharePipeline from './SharePipeline'
import {useDeletePipeline, useDuplicatePipeline} from '../../../queries/pipelines'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import PipeLineCardForm from './PipeLineCardForm'
import PipeLineForm from './PipeLineForm'
import PipeLineStageForm from './PipeLineStageForm'
import GenericOffcanvas from '../../../modules/common/Offcanvas'
import UserPipelines from './UserPipelines'
import PipelineHistory from './PipelineHistory'
import {useAuth} from '../../../modules/auth'

interface PipelinesHeaderProps {
  pipelineId: string
  status: string
  singlePipelineData: any
  pipelineStages: any
  pipelineNotificationsResult: any
  pipelines: any
  isPipelinesLoading: boolean
}

const PipelinesHeader: React.FC<PipelinesHeaderProps> = ({
  pipelineId,
  status,
  singlePipelineData,
  pipelineStages,
  pipelineNotificationsResult,
  pipelines,
  isPipelinesLoading,
}) => {
  const {currentUser} = useAuth()
  const [showNotesForm, setShowNotesForm] = useState(false)
  const [showPipeLineFormModal, setShowPipeLineFormModal] = useState(false)
  const [showAddStageModal, setShowAddStageModal] = useState(false)
  const [showContractModal, setShowContractModal] = useState(false)
  const [sharePipeline, setSharePipeline] = useState(false)
  const [showDuplicatePipelineModal, setShowDuplicatePipelineModal] = useState(false)
  const [showEditPipeLineModal, setShowEditPipeLineModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showUserPipeLineModal, setShowUserPipeLineModal] = useState(false)
  const [showHistoryModal, setShowHistorModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [editedPipelineName, setEditedPipelineName] = useState('')
  const navigate = useNavigate()

  const {mutateAsync: duplicatePipeline} = useDuplicatePipeline()
  const {mutateAsync: deletePipeline} = useDeletePipeline()

  const canManagePipeline = singlePipelineData?.pipeline?.createdById === currentUser?.id

  const handleDuplicatePipeline = async () => {
    try {
      const payload = {
        name: editedPipelineName
          ? editedPipelineName
          : `${singlePipelineData?.pipeline?.name} (Duplicate)`,
        wholePipeline: selectedOption === 'wholePipeline' ? true : false,
        pipelineWithStages: selectedOption === 'pipelineWithStages' ? true : false,
      }
      const duplicatedPipeline = await duplicatePipeline({pipelineId: pipelineId, data: payload})
      navigate(`?pipelineId=${duplicatedPipeline?.pipeline?.uuid}`)
      setShowDuplicatePipelineModal(false)
      setSelectedOption('')
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeletePipeLine = async () => {
    try {
      await deletePipeline(pipelineId)
      setShowDeleteModal(false)
      navigate('/pipeline')
    } catch (error) {
      console.error(error)
    }
  }

  // total contract values length of a pipeline
  const totalContractValue = pipelineStages.reduce((total: any, stage: any) => {
    const stageTotal = stage.pipelineStageCards.reduce((stageSum: any, card: any) => {
      return stageSum + (card.contractValue ?? 0)
    }, 0)
    return total + stageTotal
  }, 0)

  // total contracts of a pipeline stage
  const totalPipelineStageCards = pipelineStages.reduce((total: any, stage: any) => {
    return total + stage.pipelineStageCards.length
  }, 0)

  const dropdownOptions = [
    {
      label: (
        <>
          <i className='fa-solid fs-7 fa-pencil'></i>
          <span className='mx-2'>Edit</span>
        </>
      ),
      handler: () => setShowEditPipeLineModal(true),
    },
  ]

  if (canManagePipeline) {
    dropdownOptions.push(
      {
        label: (
          <>
            <i className='fa-solid fs-7 fa-copy'></i>
            <span className='mx-2'>Duplicate</span>
          </>
        ),
        handler: () => setShowDuplicatePipelineModal(true),
      },
      {
        label: (
          <>
            <i className='fa-solid fs-7 fa-share'></i>
            <span className='mx-2'>Share</span>
          </>
        ),
        handler: () => setSharePipeline(true),
      },
      {
        label: (
          <>
            <i className='fa-solid fs-7 text-danger fa-trash'></i>
            <span className='mx-2 text-danger'>Archive</span>
          </>
        ),
        handler: () => setShowDeleteModal(true),
      }
    )
  }
  return (
    <div className='mb-3'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <Button
            variant='primary'
            size='sm'
            className='d-flex flex-row align-items-center me-1 px-3'
            onClick={() => setShowPipeLineFormModal(true)}
          >
            <div
              className='d-flex align-items-center p-1 rounded-1 me-1'
              style={{backgroundColor: '#5b80af'}}
            >
              <i className='fa-solid fa-plus text-white'></i>
            </div>
            <span className='ms-1'>Pipeline</span>
          </Button>
          {pipelineId && (
            <>
              <Button
                variant='primary'
                size='sm'
                className='d-flex flex-row align-items-center me-1 px-3'
                onClick={() => setShowAddStageModal(true)}
              >
                <div
                  className='d-flex align-items-center p-1 rounded-1 me-1'
                  style={{backgroundColor: '#5b80af'}}
                >
                  <i className='fa-solid fa-plus text-white'></i>
                </div>
                <span className='ms-1'>Stage</span>
              </Button>
              <Button
                variant='primary'
                size='sm'
                className='d-flex flex-row align-items-center me-1 px-3'
                onClick={() => setShowContractModal(true)}
              >
                <div
                  className='d-flex align-items-center p-1 rounded-1 me-1'
                  style={{backgroundColor: '#5b80af'}}
                >
                  <i className='fa-solid fa-plus text-white'></i>
                </div>
                <span className='ms-1'>Contract</span>
              </Button>
              <Button
                variant='primary'
                size='sm'
                className='d-flex flex-row align-items-center me-1 px-3'
                onClick={() => setShowNotesForm(true)}
              >
                <div
                  className='d-flex align-items-center p-1 rounded-1 me-1'
                  style={{backgroundColor: '#5b80af'}}
                >
                  <img src='/media/svg/general/note.svg' alt='' width={15} />
                </div>
                <span className='ms-1'>Notes</span>
              </Button>
            </>
          )}
        </div>
        <div className='d-flex align-items-center'>
          {pipelineId && (
            <>
              <p className='text-success text-fs-13 fw-700 m-0 p-0'>
                <span className='me-1'>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(totalContractValue)}
                </span>
                -
                <span title='Total Contracts' className='ms-1'>
                  {totalPipelineStageCards} Contracts
                </span>
              </p>
              <button
                className='history-btn btn d-flex align-items-center px-3 ms-2 text-gray-900 rounded'
                onClick={() => setShowHistorModal(true)}
              >
                <i className='fa-solid fa-clock-rotate-left me-1'></i>
                History
              </button>
              <div className='pipeline-title d-flex align-items-center px-3 ms-2 btl-5 bbl-5'>
                <CustomDropdown
                  elementId={'singlePipeline'}
                  dropdownTitle={
                    <div className='d-flex align-items-center fa-solid text-primary fa-pen-to-square me-2'></div>
                  }
                  options={dropdownOptions}
                />
                <span className='text-fs-12 fw-bold text-gray-900'>
                  {singlePipelineData?.pipeline?.name}
                </span>
              </div>
              <div className='pipeline-actions d-flex align-items-center px-1 btr-5 bbr-5'>
                <img
                  src='/media/svg/general/chevron-group.svg'
                  onClick={() => setShowUserPipeLineModal(true)}
                  className='cursor-pointer'
                  alt='switch-icon'
                />
              </div>
            </>
          )}
        </div>
      </div>
      {pipelineId && (
        <div className='d-flex align-items-center my-5 overflow-x-scroll'>
          <div className='d-flex align-items-center bg-primary rounded p-1 me-2'>
            <img src='/media/svg/general/filter.svg' alt='filter-icon' />
          </div>
          {statusOptions?.map((option: any) => (
            <button
              key={option.value}
              className={`mx-2 btn px-2 py-1 text-fs-12 fw-bold status-btn`}
              style={{
                backgroundColor: status === option.value ? statusClassMap[status] : 'transparent',
                borderLeft: `4px solid ${statusClassMap[option.value]}`,
                color: option.value === status ? 'white' : 'black',
              }}
              onClick={() => {
                if (status === option.value) {
                  navigate(`?pipelineId=${pipelineId}`)
                } else {
                  navigate(`?pipelineId=${pipelineId}&status=${option.value}`)
                }
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}

      {showUserPipeLineModal && (
        <GenericOffcanvas
          showOffcanvas={showUserPipeLineModal}
          onHideOffcanvas={() => setShowUserPipeLineModal(false)}
          placement='end'
          children={
            <UserPipelines
              pipelineNotificationsResult={pipelineNotificationsResult}
              setShowUserPipeLineModal={setShowUserPipeLineModal}
              data={pipelines}
              isLoading={isPipelinesLoading}
            />
          }
        />
      )}
      {showContractModal && (
        <PipeLineCardForm
          pipelineId={String(pipelineId)}
          pipelineStages={pipelineStages}
          showCardModal={showContractModal}
          setShowCardModal={setShowContractModal}
        />
      )}
      {showAddStageModal && (
        <PipeLineStageForm
          pipelineId={pipelineId}
          showStageFormModal={showAddStageModal}
          setShowStageFormModal={setShowAddStageModal}
        />
      )}
      {(showEditPipeLineModal || showPipeLineFormModal) && (
        <GenericModal
          title={showPipeLineFormModal ? 'Create a Pipeline' : 'Edit Pipeline'}
          showModal={showEditPipeLineModal || showPipeLineFormModal}
          onHideModal={() => {
            if (showEditPipeLineModal) {
              setShowEditPipeLineModal(false)
            } else {
              setShowPipeLineFormModal(false)
            }
          }}
          hideFooter={true}
          modalProps={{
            size: 'md',
            centered: true,
          }}
        >
          <PipeLineForm
            setShowPipeLineModal={() => {
              if (showEditPipeLineModal) {
                setShowEditPipeLineModal(false)
              } else {
                setShowPipeLineFormModal(false)
              }
            }}
            pipelineValues={showEditPipeLineModal ? singlePipelineData?.pipeline : null}
          />
        </GenericModal>
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Archive PipeLine'
          subtitle='Are you sure you want to archive this PipeLine?'
          onConfirm={handleDeletePipeLine}
          disableBtns={false}
        />
      )}
      {showDuplicatePipelineModal && (
        <GenericModal
          title={'Confirm Action'}
          showModal={showDuplicatePipelineModal}
          onHideModal={() => setShowDuplicatePipelineModal(false)}
          modalProps={{
            size: 'md',
            centered: true,
          }}
          hideFooter
        >
          <div className='form-check mb-3'>
            <input
              className='form-check-input'
              type='radio'
              name={'wholePipeline'}
              value='wholePipeline'
              checked={selectedOption === 'wholePipeline'}
              onChange={() => setSelectedOption('wholePipeline')}
            />
            <label className='form-check-label text-black' htmlFor={'wholePipeline'}>
              Everything
            </label>
          </div>
          <div className='form-check mb-3'>
            <input
              className='form-check-input'
              type='radio'
              name={'pipelineWithStages'}
              value={'pipelineWithStages'}
              checked={selectedOption === 'pipelineWithStages'}
              onChange={() => setSelectedOption('pipelineWithStages')}
            />
            <label className='form-check-label text-black' htmlFor={'pipelineWithStages'}>
              Just Stages
            </label>
          </div>
          <div className='my-5'>
            <label className='form-check-label text-black fw-bold' htmlFor={'pipeline-input'}>
              Edit Pipeline Name
            </label>
            <input
              className='form-control'
              type='text'
              value={editedPipelineName ? editedPipelineName : singlePipelineData?.pipeline?.name}
              name={'pipeline-input'}
              onChange={(e) => setEditedPipelineName(e.target.value)}
            />
          </div>

          <div className='d-end'>
            <Button
              variant='secondary'
              className='mx-1'
              onClick={() => {
                setShowDuplicatePipelineModal(false)
                setSelectedOption('')
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              className='mx-1'
              onClick={handleDuplicatePipeline}
              disabled={selectedOption === ''}
            >
              Duplicate
            </Button>
          </div>
        </GenericModal>
      )}
      {sharePipeline && (
        <GenericModal
          title={`Share "${singlePipelineData?.pipeline?.name}"`}
          showModal={sharePipeline}
          onHideModal={() => setSharePipeline(false)}
          hideFooter={true}
          modalProps={{
            size: 'md',
            centered: true,
          }}
        >
          <SharePipeline
            pipeline={singlePipelineData?.pipeline}
            setSharePipeline={setSharePipeline}
          />
        </GenericModal>
      )}
      {showNotesForm && (
        <ModuleNotes
          sectionModule={singlePipelineData?.pipeline}
          moduleId={singlePipelineData?.pipeline?.id}
          showModuleNotes={showNotesForm}
          setShowModuleNotes={setShowNotesForm}
        />
      )}
      {showHistoryModal && (
        <GenericModal
          title={'Pipeline History'}
          showModal={showHistoryModal}
          onHideModal={() => setShowHistorModal(false)}
          modalProps={{
            size: 'lg',
            centered: true,
          }}
          hideFooter
        >
          <PipelineHistory
            pipelineId={pipelineId}
            pipelineName={singlePipelineData?.pipeline?.name}
            onHide={() => setShowHistorModal(false)}
          />
        </GenericModal>
      )}
    </div>
  )
}

export default PipelinesHeader
