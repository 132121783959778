import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

interface GetApiParams {
  page: number | string
  limit?: number
  moduleId?: string | number
  pipelineId?: string | number
  type?: string
}

export const postNotes = async (notesData: any) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/notes`, notesData)
  return response.data?.data || {}
}

export const getNotes = async (params: GetApiParams): Promise<any> => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/notes`, {
    params,
  })
  return response.data?.data || []
}

export const putNote = async (noteId: number, notesData: any) => {
  const response = await apiClient.put(`/c/${getSelectedCommunityId()}/notes/${noteId}`, notesData)
  return response.data?.data || {}
}

export const deleteNote = async (noteId: number) => {
  const response = await apiClient.delete(`/c/${getSelectedCommunityId()}/notes/${noteId}`)
  return response.data?.data || {}
}
