import {Card} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import moment from 'moment'

import PipeLineCardDetail from './PipeLineCardDetail'
import {useReadPipelineNotification} from '../../../queries/notifications'
import {useAuth} from '../../../modules/auth'
import {getContractTimeLeft} from '../../../modules/common/utils'

const PipeLineCard = ({
  card,
  pipelineId,
  stageId,
  pipelineNotificationsResult,
  pipelinesData,
  isLoading,
}: {
  card: any
  pipelineId: string
  stageId: string
  pipelineNotificationsResult: any
  pipelinesData: any
  isLoading: boolean
}) => {
  const [showPipeLineDetailModal, setShowPipeLineDetailModal] = useState(false)
  const readPipelineNotifications = useReadPipelineNotification()
  const {getUserTimezone} = useAuth()
  const timezone = getUserTimezone()

  const statusClassMap: any = {
    newContract: '#3D2C8D',
    reviewingContract: '#1916e5',
    writingProposal: '#46ab4a',
    noBid: '#000',
    submitted: '#1ddcf9',
    pendingAward: '#7c4dff',
    lost: '#d12121',
    won: '#58F41B',
  }

  const hasCardCommentNotification = pipelineNotificationsResult?.some(
    (notification: any) =>
      notification.meta?.type === 'PIPELINE_NEW_COMMENT' &&
      notification.meta?.pipelineId === pipelineId &&
      notification.meta?.pipelineCardId === card.uuid &&
      !notification.readStatus
  )

  const renderDueDate = () => {
    const dueDate = moment.utc(card.dueDate).tz(getUserTimezone())
    const now = moment.tz(getUserTimezone())
    const daysUntilDue = dueDate.diff(now.startOf('day'), 'days')
    const isWithin10Days = dueDate.isSameOrBefore(now.add(10, 'days'), 'day')

    if (isWithin10Days) {
      return daysUntilDue > 0
        ? `Due in ${daysUntilDue} days`
        : getContractTimeLeft(card.dueDate, timezone)
    }

    return `Due Date: ${dueDate.format('DD MMMM YYYY')}`
  }

  useEffect(() => {
    if (showPipeLineDetailModal) {
      setTimeout(() => {
        const unreadNotifications = pipelineNotificationsResult?.filter(
          (notification: any) =>
            notification.meta?.type === 'PIPELINE_NEW_COMMENT' &&
            notification.meta?.pipelineId === pipelineId &&
            notification.meta?.pipelineCardId === card.uuid &&
            !notification.readStatus
        )

        const notificationIds = unreadNotifications.map((notification: any) => notification.id)
        if (notificationIds.length) {
          readPipelineNotifications.mutate({notificationIds: notificationIds})
        }
      }, 2000)
    }
  }, [showPipeLineDetailModal])

  return (
    <div className='position-relative'>
      <Card className='stage-card cursor-pointer bg-white position-relative'>
        <Card.Body
          className='px-5 py-4'
          style={{gap: 0}}
          onClick={() => setShowPipeLineDetailModal(true)}
        >
          <Card.Title className='fs-4 fw-medium'>
            {card.name}
            {hasCardCommentNotification && <span className='notification-indicator top-1'></span>}
          </Card.Title>
          <div className='d-flex gap-3 align-items-baseline'>
            <div>
              <p
                title={
                  card.dueDate && moment(card.dueDate).endOf('day').isBefore(moment())
                    ? 'Overdue'
                    : 'Due date'
                }
                className={`mb-2 fw-medium fs-6 badge cursor-default ${
                  moment
                    .utc(card.dueDate)
                    .tz(getUserTimezone())
                    .isSameOrBefore(moment().add(10, 'days'), 'day')
                    ? 'text-bg-danger text-light'
                    : 'bg-green text-black'
                }`}
              >
                {renderDueDate()}
              </p>
            </div>
          </div>

          <Card.Text className='mb-1 text-fs-13'>
            Status: {card.status.charAt(0).toUpperCase() + card.status.slice(1)}
          </Card.Text>
        </Card.Body>

        {showPipeLineDetailModal && (
          <PipeLineCardDetail
            showPipeLineDetailModal={showPipeLineDetailModal}
            setShowPipeLineDetailModal={setShowPipeLineDetailModal}
            card={card}
            pipelineNotificationsResult={pipelineNotificationsResult}
            pipelineId={pipelineId}
            stageId={stageId}
            pipelinesData={pipelinesData}
            isLoading={isLoading}
          />
        )}
      </Card>

      <div
        className='stage-card-border'
        style={{backgroundColor: statusClassMap[card.status]}}
      ></div>
    </div>
  )
}

export default PipeLineCard
