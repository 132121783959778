import React from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Formik} from 'formik'
import * as Yup from 'yup'

import {useCreateCourseSection, useUpdateCourseSection} from '../../../queries/courses'

const validationSchema = Yup.object().shape({
  sectionName: Yup.string()
    .required('Name is required')
    .max(50, 'Name cannot exceed 50 characters'),
  isActive: Yup.boolean().optional(),
})

interface CourseSectionFormProps {
  showModal: boolean
  onHideModal: () => void
  course: any
  section?: {
    id: string
    sectionName: string
    status: string
  }
}

export const CourseSectionForm: React.FC<CourseSectionFormProps> = ({
  showModal,
  onHideModal,
  course,
  section = {
    id: '',
    sectionName: '',
    status: 'published',
  },
}) => {
  const {mutateAsync: createCourseSection} = useCreateCourseSection()
  const {mutateAsync: updateCourseSection} = useUpdateCourseSection()

  return (
    <Modal show={showModal} centered onHide={onHideModal} backdrop='static'>
      <Modal.Body>
        <Modal.Title className='fs-1 fw-bold mb-6'>
          {section.id ? 'Edit Section' : 'Add Section'}
        </Modal.Title>
        <Formik
          initialValues={{sectionName: section.sectionName, status: section.status}}
          validationSchema={validationSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            try {
              if (section.id) {
                await updateCourseSection({
                  courseId: course.uuid,
                  sectionId: section.id,
                  sectionData: values,
                })
              } else {
                await createCourseSection({
                  courseId: course.uuid,
                  sectionData: values,
                })
              }
              resetForm()
              onHideModal()
            } catch {
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({handleSubmit, isSubmitting, errors, touched, values, handleChange, setFieldValue}) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className='mb-3' controlId='setForm.ControlInput1'>
                <Form.Control
                  type='text'
                  placeholder='Section name'
                  autoFocus
                  name='sectionName'
                  value={values.sectionName}
                  onChange={handleChange}
                  isInvalid={touched.sectionName && !!errors.sectionName}
                />
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    {errors.sectionName && touched.sectionName && (
                      <div className='text-danger'>{errors.sectionName}</div>
                    )}
                  </div>
                  <div>
                    <p className='text-muted mt-3'>
                      {values.sectionName.length} / {50}
                    </p>
                  </div>
                </div>
              </Form.Group>
              <div className='d-flex justify-content-between align-items-center mt-9'>
                <div className={`form-check form-switch align-items-center`}>
                  <input
                    className='form-check-input cursor-pointer'
                    type='checkbox'
                    id='flexSwitchCheckDefault'
                    name='status'
                    checked={values.status === 'published'}
                    onChange={() => {
                      const newStatus = values.status === 'published' ? 'draft' : 'published'
                      setFieldValue('status', newStatus)
                    }}
                  />
                  <span className='fs-5 fw-bold'>
                    {values.status === 'published' ? 'Publish' : 'Draft'}
                  </span>
                </div>
                <div>
                  <Button
                    className='mx-3 border border-dark'
                    variant='transparent'
                    onClick={onHideModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={isSubmitting || Object.keys(errors).length !== 0}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
