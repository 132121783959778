import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import CustomDropdown from '../../../modules/common/CustomDropDown'
import PipeLineForm from './PipeLineForm'
import GenericModal from '../../../modules/common/Modal'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useDeletePipeline, useDuplicatePipeline} from '../../../queries/pipelines'
import SharePipeline from './SharePipeline'
import {useReadPipelineNotification} from '../../../queries/notifications'
import {Button} from 'react-bootstrap'

const PipelineItem = ({
  pipeline,
  isSharedPipeline,
  pipelineNotificationsResult,
  setShowUserPipeLineModal,
}: {
  pipeline: any
  isSharedPipeline?: boolean
  pipelineNotificationsResult: any
  setShowUserPipeLineModal: (show: boolean) => void
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pipelineId: any = searchParams.get('pipelineId')
  const {mutateAsync: deletePipeline} = useDeletePipeline()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPipeLineModal, setShowPipeLineModal] = useState(false)
  const [sharePipeline, setSharePipeline] = useState(false)
  const [showDuplicatePipelineModal, setShowDuplicatePipelineModal] = useState(false)
  const [editedPipelineName, setEditedPipelineName] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const readPipelineNotifications = useReadPipelineNotification()
  const {mutateAsync: duplicatePipeline} = useDuplicatePipeline()

  const hasSharedPipelineNotification = pipelineNotificationsResult?.some(
    (notification: any) =>
      notification.meta?.type === 'PIPELINE_SHARED' &&
      notification.meta?.pipelineId === pipeline.uuid &&
      !notification.readStatus
  )

  const hasCardCommentNotification = pipelineNotificationsResult?.some(
    (notification: any) =>
      notification.meta?.type === 'PIPELINE_NEW_COMMENT' &&
      notification.meta?.pipelineId === pipeline.uuid &&
      !notification.readStatus
  )

  const handleDeletePipeLine = async () => {
    try {
      await deletePipeline(pipeline.uuid)
      setShowDeleteModal(false)
      navigate('/pipeline')
      setShowUserPipeLineModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  const confirmDeletePipeLine = () => {
    setShowDeleteModal(true)
  }

  const handleDuplicatePipeline = async () => {
    try {
      const payload = {
        name: editedPipelineName ? editedPipelineName : `${pipeline?.name} (Duplicate)`,
        wholePipeline: selectedOption === 'wholePipeline' ? true : false,
        pipelineWithStages: selectedOption === 'pipelineWithStages' ? true : false,
      }
      const duplicatedPipeline = await duplicatePipeline({pipelineId: pipelineId, data: payload})
      navigate(`?pipelineId=${duplicatedPipeline?.pipeline?.uuid}`)
      setSharePipeline(false)
      setShowDuplicatePipelineModal(false)
      setSelectedOption('')
    } catch (error) {
      console.error(error)
    }
  }

  const dropdownOptions = [
    {
      label: (
        <>
          <i className='fa-solid fs-7 fa-pencil text-primary'></i>
          <span className='mx-2'>Edit</span>
        </>
      ),
      handler: () => setShowPipeLineModal(true),
    },
  ]

  if (!isSharedPipeline) {
    dropdownOptions.push(
      {
        label: (
          <>
            <i className='fa-solid fs-7 text-primary fa-copy'></i>
            <span className='mx-2'>Duplicate</span>
          </>
        ),
        handler: () => setShowDuplicatePipelineModal(true),
      },
      {
        label: (
          <>
            <i className='fa-solid fs-7 text-primary fa-share'></i>
            <span className='mx-2'>Share</span>
          </>
        ),
        handler: () => setSharePipeline(true),
      },
      {
        label: (
          <>
            <i className='fa-solid fs-7 text-danger fa-trash'></i>
            <span className='mx-2 text-danger'>Archive</span>
          </>
        ),
        handler: () => confirmDeletePipeLine(),
      }
    )
  }

  useEffect(() => {
    if (pipelineId === pipeline.uuid) {
      const filteredNotifications = pipelineNotificationsResult?.filter(
        (notification: any) =>
          notification.meta?.type === 'PIPELINE_SHARED' &&
          notification.meta?.pipelineId === pipeline.uuid &&
          !notification.readStatus
      )

      const notificationIds = filteredNotifications?.map((notification: any) => notification.id)
      if (notificationIds?.length) {
        readPipelineNotifications.mutate({notificationIds: notificationIds})
      }
    }
  }, [pipelineId])

  return (
    <>
      <div
        onClick={() => {
          navigate(`?pipelineId=${pipeline.uuid}`)
          setShowUserPipeLineModal(false)
        }}
        className={`rounded px-4 py-2 w-100 d-flex flex-row justify-content-between align-items-center position-relative ${
          pipeline.uuid === pipelineId ? 'bg-secondary' : ''
        }`}
      >
        <div>
          <div className='fs-5 fw-normal' title={pipeline.name}>
            {pipeline.name.length > 15 ? pipeline.name.slice(0, 15).concat('...') : pipeline.name}
            {(hasSharedPipelineNotification || hasCardCommentNotification) && (
              <span className={`notification-indicator`}></span>
            )}
          </div>
          {pipeline.user && (
            <p className='text-muted m-0 p-0'>Shared By {pipeline.user.fullName}</p>
          )}
        </div>
        <CustomDropdown elementId={`pipeline+${pipeline.id}`} options={dropdownOptions} />
      </div>
      {showPipeLineModal && (
        <GenericModal
          title={'Edit Pipeline'}
          showModal={showPipeLineModal}
          onHideModal={() => setShowPipeLineModal(false)}
          hideFooter={true}
          modalProps={{
            size: 'md',
            centered: true,
          }}
        >
          <PipeLineForm
            setShowPipeLineModal={() => setShowPipeLineModal(false)}
            pipelineValues={pipeline}
          />
        </GenericModal>
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Archive PipeLine'
          subtitle='Are you sure you want to archive this PipeLine?'
          onConfirm={handleDeletePipeLine}
          disableBtns={false}
        />
      )}
      {sharePipeline && (
        <GenericModal
          title={`Share "${pipeline.name}"`}
          showModal={sharePipeline}
          onHideModal={() => setSharePipeline(false)}
          hideFooter={true}
          modalProps={{
            size: 'md',
            centered: true,
          }}
        >
          <SharePipeline pipeline={pipeline} setSharePipeline={setSharePipeline} />
        </GenericModal>
      )}
      {showDuplicatePipelineModal && (
        <GenericModal
          title={'Confirm Action'}
          showModal={showDuplicatePipelineModal}
          onHideModal={() => setShowDuplicatePipelineModal(false)}
          modalProps={{
            size: 'md',
            centered: true,
          }}
          hideFooter
        >
          <div className='form-check mb-3'>
            <input
              className='form-check-input'
              type='radio'
              name={'wholePipeline'}
              value='wholePipeline'
              checked={selectedOption === 'wholePipeline'}
              onChange={() => setSelectedOption('wholePipeline')}
            />
            <label className='form-check-label text-black' htmlFor={'wholePipeline'}>
              Everything
            </label>
          </div>
          <div className='form-check mb-3'>
            <input
              className='form-check-input'
              type='radio'
              name={'pipelineWithStages'}
              value={'pipelineWithStages'}
              checked={selectedOption === 'pipelineWithStages'}
              onChange={() => setSelectedOption('pipelineWithStages')}
            />
            <label className='form-check-label text-black' htmlFor={'pipelineWithStages'}>
              Just Stages
            </label>
          </div>
          <div className='my-5'>
            <label className='form-check-label text-black fw-bold' htmlFor={'pipeline-input'}>
              Edit Pipeline Name
            </label>
            <input
              className='form-control'
              type='text'
              value={editedPipelineName ? editedPipelineName : pipeline?.name}
              name={'pipeline-input'}
              onChange={(e) => setEditedPipelineName(e.target.value)}
            />
          </div>

          <div className='d-end'>
            <Button
              variant='secondary'
              className='mx-1'
              onClick={() => {
                setShowDuplicatePipelineModal(false)
                setSelectedOption('')
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              className='mx-1'
              onClick={handleDuplicatePipeline}
              disabled={selectedOption === ''}
            >
              Duplicate
            </Button>
          </div>
        </GenericModal>
      )}
    </>
  )
}

export default PipelineItem
