export const statusOptions = [
  {label: 'New Contract', value: 'newContract'},
  {label: 'Reviewing Contract', value: 'reviewingContract'},
  {label: 'Writing Proposal', value: 'writingProposal'},
  {label: 'No Bid', value: 'noBid'},
  {label: 'Submitted', value: 'submitted'},
  {label: 'Pending Award', value: 'pendingAward'},
  {label: 'Lost', value: 'lost'},
  {label: 'Won', value: 'won'},
]

export const statusClassMap: any = {
  newContract: '#3D2C8D',
  reviewingContract: '#1916e5',
  writingProposal: '#46ab4a',
  noBid: '#000',
  submitted: '#1ddcf9',
  pendingAward: '#7c4dff',
  lost: '#d12121',
  won: '#58F41B',
}

export const fieldNameMap: any = {
  preBidDate: 'Pre-Bid Date',
  questionDeadline: 'Question Deadline',
  dueDate: 'Due Date',
  workScope: 'Work Scope',
  contractLink: 'Contract Link',
  contractValue: 'Contract Value',
  agency: 'Agency',
  contractLocation: 'Contract Location',
  status: 'Status',
  name: 'Name',
};
