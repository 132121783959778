import React, {useMemo, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import moment from 'moment'
import parse from 'html-react-parser'
import Select from 'react-select'

import Image from '../../../modules/common/Image'
import {
  useDeletePipelineStageCard,
  useUpdatePipelineStageCardStatus,
  useUpdateStageIdofCard,
} from '../../../queries/pipelines'
import PipeLineCardForm from './PipeLineCardForm'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useAuth} from '../../../modules/auth'
import Attachments from '../../feeds/components/FeedAttachments'
import PipelineCardCommentForm from './PipelineCardCommentForm'
import PipelineCardComments from './PipelineCardComments'
import GenericModal from '../../../modules/common/Modal'
import {getContractTimeLeft} from '../../../modules/common/utils'
import {statusOptions, statusClassMap} from '../pipelines.utils'

interface PipeLineCardDetailProps {
  showPipeLineDetailModal: boolean
  setShowPipeLineDetailModal: (show: boolean) => void
  card: any
  pipelineId: string
  stageId: string
  pipelineNotificationsResult?: any
  pipelinesData: any
  isLoading: boolean
}

interface SelectedPipelineStageObj {
  label: string
  newStageId: string
  newPipelineId: string
}

const PipeLineCardDetail: React.FC<PipeLineCardDetailProps> = ({
  showPipeLineDetailModal,
  setShowPipeLineDetailModal,
  card,
  pipelineId,
  stageId,
  pipelineNotificationsResult,
  pipelinesData,
  isLoading,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditCardModal, setShowEditCardModal] = useState(false)
  const [showDescription, setShowDescription] = useState(
    card?.description?.length > 500 ? false : true
  )
  const [showPipelinesModal, setShowPipelinesModal] = useState(false)
  const [selectedPipeline, setSelectedPipeline] = useState('')
  const [selectedStage, setSelectedStage] = useState<SelectedPipelineStageObj>({
    label: '',
    newStageId: '',
    newPipelineId: '',
  })

  const {mutateAsync: deleteCard} = useDeletePipelineStageCard()
  const {mutateAsync: updateIdofCard} = useUpdateStageIdofCard()
  const {mutateAsync: updateCardStatus, isPending} = useUpdatePipelineStageCardStatus()
  const {currentUser, getUserTimezone} = useAuth()
  const timezone = getUserTimezone()

  const handleDeletePipeLine = async () => {
    try {
      await deleteCard({pipelineId: pipelineId, stageId: stageId, cardId: card.uuid})
      setShowDeleteModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  const confirmDeletePipeLine = () => {
    setShowDeleteModal(true)
  }

  const pipelineOptions = useMemo(
    () =>
      pipelinesData?.pipelines
        ?.filter((pipeline: any) => pipeline.uuid !== pipelineId)
        .map((pipeline: any) => ({
          value: pipeline.id,
          label: pipeline.name,
        })) || [],
    [pipelineId, pipelinesData?.pipelines]
  )

  const stageOptions = useMemo(() => {
    const selectedPipelineData = pipelinesData?.pipelines.find(
      (pipeline: any) => pipeline.id === selectedPipeline
    )
    return (
      selectedPipelineData?.pipelineStages?.map((stage: any) => ({
        value: stage.id,
        label: stage.name,
      })) || []
    )
  }, [selectedPipeline, pipelinesData?.pipelines])

  const handleMoveCard = async () => {
    try {
      if (selectedStage.newStageId) {
        await updateIdofCard({
          pipelineId: pipelineId,
          stageId: stageId,
          cardId: card.uuid,
          data: selectedStage,
        })
        setShowPipeLineDetailModal(false)
        setSelectedStage({
          label: '',
          newStageId: '',
          newPipelineId: '',
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdateCardStatus = async (cardStatus: string) => {
    try {
      if (card.status !== cardStatus) {
        await updateCardStatus({
          pipelineId: pipelineId,
          stageId: stageId,
          cardId: card.uuid,
          status: {status: cardStatus},
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const renderDueDate = () => {
    const dueDate = moment.utc(card.dueDate).tz(getUserTimezone())
    const now = moment.tz(getUserTimezone())
    const daysUntilDue = dueDate.diff(now.startOf('day'), 'days')
    const isWithin10Days = dueDate.isSameOrBefore(now.add(10, 'days'), 'day')

    if (isWithin10Days) {
      return daysUntilDue > 0
        ? `Due in ${daysUntilDue} days`
        : getContractTimeLeft(card.dueDate, timezone)
    }

    return `Due Date: ${dueDate.format('DD MMMM YYYY')}`
  }

  return (
    <>
      <Modal
        show={showPipeLineDetailModal}
        onHide={() => setShowPipeLineDetailModal(false)}
        className={`${showEditCardModal && 'd-none'}`}
        size='lg'
        centered
      >
        <Modal.Header className='feed-detail-header'>
          <div className='d-flex justify-content-between align-items-start w-100 mx-2'>
            <div className='d-flex align-items-start'>
              <Image src={currentUser?.absoluteProfilePath || ''} />
              <div className='ms-2'>
                <Modal.Title>{currentUser?.fullName}</Modal.Title>
                <p className='text-gray'>Created {moment(card?.createdAt).fromNow()}</p>
              </div>
            </div>
            <div className='d-end align-items-center'>
              <div className='dropdown m-0 p-0'>
                <button
                  className='btn btn-transparent btn-sm'
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='fa-solid fs-2 text-white fa-ellipsis p-0 m-0'></i>
                </button>
                <ul className='dropdown-menu'>
                  <li
                    className='dropdown-item cursor-pointer'
                    onClick={() => setShowEditCardModal(true)}
                  >
                    <i className='fa-solid fs-7 fa-pencil text-primary'></i>
                    <span className='mx-2'>Edit</span>
                  </li>
                  <li
                    className='dropdown-item cursor-pointer'
                    onClick={() => setShowPipelinesModal(true)}
                  >
                    <span className='fa-solid fs-7 fa-arrow-right text-primary'></span>
                    <span className='mx-2'>Move to</span>
                  </li>
                  <li
                    className='dropdown-item cursor-pointer'
                    onClick={() => confirmDeletePipeLine()}
                  >
                    <i className='fa-solid fs-7 text-danger fa-trash'></i>
                    <span className='mx-2 text-danger'>Archive</span>
                  </li>
                </ul>
              </div>
              <div>
                <i
                  className='fa-solid fa-xmark fs-2 text-white cursor-pointer'
                  onClick={() => setShowPipeLineDetailModal(false)}
                ></i>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='modal-body-scroll'>
          <h2 className='fw-700'>{card.name}</h2>
          <div>
            <p
              className={`mb-2 fw-medium fs-6 badge cursor-default ${
                moment
                  .utc(card.dueDate)
                  .tz(getUserTimezone())
                  .isSameOrBefore(moment().add(10, 'days'), 'day')
                  ? 'text-bg-danger text-light'
                  : 'bg-green text-black'
              }`}
              title={
                card.dueDate && moment(card.dueDate).endOf('day').isBefore(moment())
                  ? 'Overdue'
                  : 'Due date'
              }
            >
              {renderDueDate()}
            </p>
          </div>

          <div className='d-flex align-items-baseline mt-1 h20 mb10'>
            {card?.contractValue && (
              <p title='Contract Value' className='me-2 text-fs-14 d-flex align-items-baseline'>
                <div className='d-flex align-items-center bg-primary rounded-circle me-2 dollar-badge'>
                  <span className='fa-solid fa-dollar text-white text-fs-10'></span>
                </div>
                $
                {new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(card.contractValue)}
              </p>
            )}
            <p className={`text-fs-14 ${card?.contractValue && 'ms-2'}`}>
              <i className='fa-solid fa-location-dot me-2 text-primary'></i>
              {card.contractLocation}
            </p>
          </div>

          <div className='row'>
            <div className='col-3'>
              {card?.agency && (
                <p className='mb10 me-2 text-fs-14'>
                  <p className='p-0 m-0 text-gray-600 text-fs-12 fw-medium'>Agency</p>
                  {card.agency.charAt(0).toUpperCase() + card.agency.slice(1)} Contract
                </p>
              )}
            </div>
          </div>

          <div className='row'>
            {card?.preBidDate && (
              <>
                <div className='col-3'>
                  <p className='mb10 text-fs-14'>
                    <p className='p-0 m-0 text-gray-600 text-fs-12 fw-medium'>Pre-Bid Meeting</p>
                    {moment
                      .utc(card.preBidDate)
                      .tz(getUserTimezone())
                      .format('YYYY-MM-DD | HH:mm:ss')}
                  </p>
                </div>
                <div className='col-4'></div>
              </>
            )}
            <div className='col-3'>
              {card?.questionDeadline && (
                <p className='mb10 me-2 text-fs-14'>
                  <p className='p-0 m-0 text-gray-600 text-fs-12 fw-medium'>Questions Deadline</p>
                  {moment
                    .utc(card.questionDeadline)
                    .tz(getUserTimezone())
                    .format('YYYY-MM-DD | HH:mm:ss')}
                </p>
              )}
            </div>
          </div>

          <p className='mb10 me-2 text-fs-14'>
            <p className='p-0 m-0 text-gray-600 text-fs-12 fw-medium'>Contract Link:</p>
            <a
              href={
                card.contractLink?.slice(0, 8).includes('https://') ||
                card.contractLink?.slice(0, 7).includes('http://')
                  ? card.contractLink
                  : `https://${card.contractLink}`
              }
              target='_blank'
              rel='noreferrer'
              className='text-info'
            >
              {card.contractLink}
            </a>
          </p>

          <p className='mb10 me-2 text-fs-14'>
            <p className='p-0 m-0 text-gray-600 text-fs-12 fw-medium'>Scope of Work:</p>
            {parse(card.workScope)}
          </p>

          <p className='mb10 me-2 text-fs-14'>
            <p className='p-0 m-0 text-gray-600 text-fs-12 fw-medium'>Status:</p>
            <div className='d-flex mt-2 mb-4 overflow-x-scroll'>
              {statusOptions?.map((option: any) => (
                <button
                  key={option.value}
                  disabled={isPending}
                  className='mx-2 btn px-2 py-1 text-fs-12 fw-bold status-btn'
                  style={{
                    backgroundColor:
                      card.status === option.value ? statusClassMap[card.status] : 'transparent',
                    borderLeft: `4px solid ${statusClassMap[option.value]}`,
                    color: option.value === card.status ? 'white' : 'black',
                  }}
                  onClick={() => handleUpdateCardStatus(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </p>

          {card?.description && (
            <>
              <p className='p-0 mb-0 mt-2 text-gray-600 text-fs-12 fw-medium'>Description:</p>
              {!showDescription && <p>{parse(`${card?.description?.slice(0, 500)}...`)}</p>}
              {showDescription && <p>{parse(card?.description)}</p>}
              {card?.description && card?.description?.length > 500 && (
                <span
                  onClick={() => setShowDescription(!showDescription)}
                  className='m-0 cursor-pointer fs-5 d-inline text-info'
                >
                  {showDescription ? 'Read Less' : 'Read More'}
                </span>
              )}
            </>
          )}

          {card.attachments?.length > 0 && (
            <div className='mt-5'>
              <h3>Attachments:</h3>
              <Attachments attachments={card.attachments} hideDeleteIcon={false} />
            </div>
          )}

          <div>
            <PipelineCardComments
              pipelineId={pipelineId}
              stageId={stageId}
              cardId={card.uuid}
              pipelineNotificationsResult={pipelineNotificationsResult}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='w-100'>
            <PipelineCardCommentForm pipelineId={pipelineId} stageId={stageId} cardId={card.uuid} />
          </div>
        </Modal.Footer>
      </Modal>

      {showEditCardModal && (
        <PipeLineCardForm
          pipelineId={pipelineId}
          stageId={stageId}
          setShowCardModal={setShowEditCardModal}
          showCardModal={showEditCardModal}
          stagesCardValues={card}
          setShowPipeLineDetailModal={setShowPipeLineDetailModal}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Archive Contract'
          subtitle='Are you sure you want to archive this Contract?'
          onConfirm={handleDeletePipeLine}
          disableBtns={false}
        />
      )}
      {showPipelinesModal && (
        <GenericModal
          title={'Move Contract'}
          showModal={showPipelinesModal}
          onHideModal={() => setShowPipelinesModal(false)}
          hideFooter
        >
          <label htmlFor='pipelineOptions' className='fw-bold'>
            Select Pipeline
          </label>
          <Select
            options={pipelineOptions}
            name='pipelineOptions'
            placeholder='Select a pipeline'
            isLoading={isLoading}
            onChange={(option: any) => setSelectedPipeline(option.value)}
            isClearable
          />
          <label htmlFor='stageOpions' className='fw-bold mt-3'>
            Select Stage
          </label>
          <Select
            options={stageOptions}
            placeholder='Select a stage'
            name='stageOptions'
            isLoading={isLoading}
            isDisabled={!selectedPipeline}
            onChange={(option: any) =>
              setSelectedStage({
                label: option.label,
                newStageId: option.value,
                newPipelineId: selectedPipeline,
              })
            }
          />

          <div className='d-end mt-4'>
            <Button
              variant='secondary'
              className='mx-1'
              onClick={() => setShowPipelinesModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              className='mx-1'
              disabled={!selectedStage.newStageId || !selectedPipeline}
              onClick={handleMoveCard}
            >
              Move to Pipeline
            </Button>
          </div>
        </GenericModal>
      )}
    </>
  )
}

export default PipeLineCardDetail
