import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {Badge} from 'react-bootstrap'

function FeedLikesCard({likes}: {likes: any[]}) {
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  return (
    <div className='p-0'>
      <div className='d-flex flex-column w-100 overflow-auto' style={{maxHeight: '474px'}}>
        {likes.map((like: any) => {
          const isCurrentUser = like.user.id === currentUser?.id
          const truncatedBio =
            like.user.bio.length > 190 ? `${like.user.bio.substring(0, 190)}...` : like.user.bio

          return (
            <div
              className='d-flex flex-row align-items-center justify-content-between'
              key={like.id}
            >
              <div className='mt-4 mb-2 d-flex flex-row align-items-center'>
                <img
                  src={like.user.absoluteProfilePath}
                  className='w40 h40 rounded-circle object-cover object-center'
                  alt=''
                />
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mx-3'>
                    <span className='fw-bold'>{like.user.fullName}</span>
                    {isCurrentUser && (
                      <Badge bg='light-primary' className='text-primary mx-2 fw-bolder'>
                        <p className='p-0 m-0'>You</p>
                      </Badge>
                    )}
                  </div>
                  <span
                    className='fs-8 mx-3 text-muted fw-bold'
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '300px',
                    }}
                  >
                    {truncatedBio}
                  </span>
                </div>
              </div>
              {!isCurrentUser && (
                <div>
                  <button
                    onClick={() => navigate(`/messages/${like.user.id}`)}
                    className='p-2 mt-2 rounded border-0 bg-primary-light text-primary fw-bold align-items-center'
                    style={{width: '95px'}}
                  >
                    <i className='fa-solid text-primary mx-2 fa-envelope'></i> Chat
                  </button>
                </div>
              )}
            </div>
          )
        })}
        {likes.length === 0 && <div>No Likes Yet!</div>}
      </div>
    </div>
  )
}

export default FeedLikesCard
