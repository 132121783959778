import {Link} from 'react-router-dom'
import {Card, ListGroup, Spinner} from 'react-bootstrap'

import Image from '../../../modules/common/Image'
import {useGetCommunitiesLeaderBoardType} from '../../../queries/communitySettings'

function LeaderBoardCard({type = 'thirty-day'}: {type?: string}) {
  const {data: leaderBoardCardResult, isFetching} = useGetCommunitiesLeaderBoardType(type)
  return (
    <Card style={{minWidth: '22rem', maxWidth: '420px'}} className='mt-9 rounded-2 border border-2'>
      <Card.Header className='bg-gradient-primary min-h40 py-3 rounded'>
        <h4 className='m-0 text-light'>LeaderBoard (30-day)</h4>
      </Card.Header>

      <ListGroup variant='flush' className='bg-transparent'>
        {!leaderBoardCardResult && isFetching && (
          <Spinner animation='border' className='m-3' variant='primary' />
        )}
        {leaderBoardCardResult?.length < 1 && (
          <ListGroup.Item className='fs-5 text-start text-gray-700'>No Record Found</ListGroup.Item>
        )}
        {leaderBoardCardResult?.slice(0, 5).map((result: any, index: number) => (
          <ListGroup.Item
            key={index}
            className='d-flex justify-content-between align-items-center mb-4 py-3 mx-4'
          >
            <div className='d-flex align-items-center'>
              <div className='leaderboard-badge rounded w30 h30 d-flex align-items-center justify-content-center fs-6 mr15 fw-bold'>
                {index + 1}
              </div>
              <Image
                src={result.user.absoluteProfilePath}
                className='rounded-circle ms-2 object-cover object-top w50 h50'
              />
              <div>
                <Link
                  to={`/view-profile/${result.user.uuid}`}
                  className='fw-bolder ms-2 text-black text-fs-12'
                >
                  {result.user.fullName}
                </Link>
                <p className='ms-2 text-fs-12 fw-bold text-gray-700 m-0'>
                  {result.user.companyName}
                </p>
                <p className='ms-2 text-fs-12 text-gray-700 m-0'>
                  {result.user.bio?.slice(0, 20).concat('....')}
                </p>
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Card.Footer className='text-center border-top border-2 py-3'>
        <Link
          to='/leaderboard'
          className='text-primary text-decoration-none fw-bold text-info fs-6'
        >
          See All LeaderBoards <span className='fa-solid fa-chevron-right'></span>
        </Link>
      </Card.Footer>
    </Card>
  )
}

export default LeaderBoardCard
