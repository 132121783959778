import {useEffect, useState} from 'react'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

import PipeLineStage from './PipeLineStage'
import {
  useGetPipelineById,
  useUpdatePipelineStageOrder,
  useUpdatePipelineStageCardOrder,
} from '../../../queries/pipelines'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import {useGetUserPipelineNotifications} from '../../../queries/notifications'
import PipelinesHeader from './PipelinesHeader'

function PipeLineStages({
  pipelines,
  isPipelinesLoading,
  status,
  pipelineId,
}: {
  pipelines: any
  isPipelinesLoading: boolean
  status: string
  pipelineId: string
}) {
  const [currentPipelineId, setCurrentPipelineId] = useState<any>(pipelineId)
  const [pipelineStages, setPipelineStages] = useState<any>([])

  const {data: singlePipelineData, isLoading} = useGetPipelineById(currentPipelineId)
  const pipelineStageOrderMutation = useUpdatePipelineStageOrder()
  const stageCardOrderMutation = useUpdatePipelineStageCardOrder()
  const {data: pipelineNotifications} = useGetUserPipelineNotifications()

  const pipelineNotificationsResult = pipelineNotifications?.notifications

  useEffect(() => {
    setCurrentPipelineId(pipelineId)
  }, [pipelineId])

  useEffect(() => {
    setPipelineStages(singlePipelineData?.pipeline?.pipelineStages || [])
  }, [singlePipelineData])

  const handleStageCardDragEnd = async (result: any) => {
    const {source, destination, draggableId} = result

    // Find the source and destination stages
    const sourceStageIndex = pipelineStages.findIndex(
      (stage: any) => stage.uuid === source.droppableId
    )
    const destinationStageIndex = pipelineStages.findIndex(
      (stage: any) => stage.uuid === destination.droppableId
    )

    // If source and destination are the same stage
    if (sourceStageIndex === destinationStageIndex) {
      const stageCards = Array.from(pipelineStages[sourceStageIndex].pipelineStageCards)

      // Remove card from the source index
      const [removedCard] = stageCards.splice(source.index, 1)

      // Add the card to the new position in the same stage
      stageCards.splice(destination.index, 0, removedCard)

      // Update the state with the new card order for this stage
      const updatedPipelineStages = [...pipelineStages]
      updatedPipelineStages[sourceStageIndex].pipelineStageCards = stageCards

      setPipelineStages(updatedPipelineStages)

      try {
        await stageCardOrderMutation.mutateAsync({
          pipelineId,
          stageId: source.droppableId,
          cardId: draggableId,
          order: destination.index + 1,
        })
      } catch {
        setPipelineStages(pipelineStages)
      }
    } else {
      // Cross-stage drag-and-drop handling
      const sourceStageCards = Array.from(pipelineStages[sourceStageIndex].pipelineStageCards)
      const destinationStageCards = Array.from(
        pipelineStages[destinationStageIndex].pipelineStageCards
      )

      // Remove card from the source stage
      const [removedCard] = sourceStageCards.splice(source.index, 1)

      // Add card to the destination stage
      destinationStageCards.splice(destination.index, 0, removedCard)

      // Update both source and destination stages' cards
      const updatedPipelineStages = [...pipelineStages]
      updatedPipelineStages[sourceStageIndex].pipelineStageCards = sourceStageCards
      updatedPipelineStages[destinationStageIndex].pipelineStageCards = destinationStageCards

      setPipelineStages(updatedPipelineStages)

      try {
        await stageCardOrderMutation.mutateAsync({
          pipelineId,
          stageId: source.droppableId, // Original stage
          cardId: draggableId,
          order: destination.index + 1,
          newStageId: destination.droppableId, // New stage if cross-stage drag
        })
      } catch {
        setPipelineStages(pipelineStages)
      }
    }
  }

  const handleStageDragEnd = async (result: any) => {
    const [oldStages, reorderedStages] = [Array.from(pipelineStages), Array.from(pipelineStages)]
    const [removed] = reorderedStages.splice(result.source.index, 1)
    reorderedStages.splice(result.destination.index, 0, removed)

    setPipelineStages(reorderedStages)
    try {
      await pipelineStageOrderMutation.mutateAsync({
        pipelineId,
        stageId: result.draggableId,
        order: result.destination.index + 1,
      })
    } catch {
      setPipelineStages(oldStages)
    }
  }

  const handleStageORCardDragEnd = async (result: any) => {
    const {source, destination} = result
    // If there's no destination, exit early
    if (!destination) {
    }

    // If the card is dropped in the same place, exit early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    if (result.type === 'card') {
      return handleStageCardDragEnd(result)
    }

    return handleStageDragEnd(result)
  }

  if (isLoading) {
    return <FetchingSpinner />
  }

  return (
    <div className='pipeline-view-container w-100'>
      <PipelinesHeader
        pipelineId={pipelineId}
        status={status}
        singlePipelineData={singlePipelineData}
        pipelineStages={pipelineStages}
        pipelineNotificationsResult={pipelineNotificationsResult}
        pipelines={pipelines}
        isPipelinesLoading={isPipelinesLoading}
      />
      <DragDropContext onDragEnd={handleStageORCardDragEnd}>
        <div className='position-relative h-100'>
          <Droppable droppableId='pipelineStages' direction='horizontal' type='stage'>
            {(provided) => (
              <div
                className='d-flex pipeline-view-content h-100 overflow-x-scroll'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {pipelineStages.map((stage: any, columnIndex: number) => {
                  // Calculate the total contract value for the current stage
                  const stageContractValue = stage.pipelineStageCards.reduce(
                    (sum: number, card: any) => sum + (card.contractValue ?? 0),
                    0
                  )
                  return (
                    <Draggable key={stage.uuid} draggableId={stage.uuid} index={columnIndex}>
                      {(provided, snapshot) => (
                        <div
                          className={`pipeline-column ${
                            snapshot.isDragging ? 'min-w200' : 'w-100'
                          }`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <PipeLineStage
                            stage={stage}
                            pipelineId={pipelineId}
                            stageContractValueLength={stageContractValue}
                            pipelineNotificationsResult={pipelineNotificationsResult}
                            pipelinesData={pipelines}
                            isLoading={isPipelinesLoading}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                {!pipelineId && (
                  <div className='d-flex w-100 mt-3'>
                    <h2 className='fw-bold text-center flex-1'>
                      Create a Pipeline to Get Started!
                    </h2>
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  )
}

export default PipeLineStages
