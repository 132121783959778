import Image from '../../../modules/common/Image'

const RecentFeedComments = ({totalCommentsCount = 0, comments}: any) => {
  const limitedComments = comments.slice(0, 3)

  return (
    <div className='d-flex py-1 rounded justify-content-start align-items-center mx-3'>
      <div className='mx-2'>
        <img src='/media/svg/general/message.svg' alt='thumb-up-icon' />
        <span className='text-fs-14 mx-3 fw-medium cursor-default text-gray-800'>
          {totalCommentsCount}
        </span>
      </div>
      {limitedComments.length > 0 && (
        <div className={`d-flex ${limitedComments.length >= 3 && 'px-4'}`}>
          {limitedComments.map((commentUser: any, index: number) => (
            <div key={index} style={{marginLeft: limitedComments.length >= 3 ? '-15px' : '0px'}}>
              <Image
                src={`${commentUser?.user?.absoluteProfilePath}`}
                className='mx-1 w30 h30 rounded-circle object-cover'
              />
            </div>
          ))}
          {limitedComments.length >= 3 && comments.length !== 3 && (
            <div
              style={{marginLeft: '-15px'}}
              className='rounded-circle h30 w30 d-flex justify-content-center align-items-center img-feed text-light'
            >
              +{totalCommentsCount - 3}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default RecentFeedComments
