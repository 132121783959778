import React, {useEffect, useRef, useState} from 'react'
import EmojiPicker from 'emoji-picker-react'

import InsertLinkModal from '../../../modules/common/InsertLinkModal'

interface MessagesInputProps {
  isPending: boolean
  onSendMessage: (messageData: {message: string}) => void
  messageContainer: any
}

const MessagesInput: React.FC<MessagesInputProps> = ({
  onSendMessage,
  isPending,
  messageContainer,
}) => {
  const [newMessage, setNewMessage] = useState<string>('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showLinkModal, setShowLinkModal] = useState(false)
  const messageInputRef = useRef<any>()
  const emojiPickerRef = useRef<HTMLDivElement | null>(null)

  const handleLinkClose = () => setShowLinkModal(false)

  const sendMessage = async () => {
    onSendMessage({message: newMessage})
    setNewMessage('')
    messageInputRef.current.focus()
    messageContainer.current.scrollTop = messageContainer.current.scrollHeight
  }

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState)
  }

  const onEmojiClick = (emojiObject: any) => {
    setNewMessage((prevInput) => prevInput + emojiObject.emoji)
  }

  const handleLinkInsert = (link: string) => {
    setNewMessage((prevMessage: any) => prevMessage + ' ' + link)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
        setShowEmojiPicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className='px-5'>
      <div
        style={{
          border: '1px solid #E3E3E3',
          backgroundColor: '#F9F9F9',
          bottom: '20px',
          width: '95%',
        }}
        className='d-flex msg-input justify-content-between mt-3 align-items-center bg-white position-absolute rounded'
      >
        <input
          type='text'
          className='px-3 w-100 border-0 outline-none'
          placeholder='Enter Message here'
          aria-describedby='button-addon2'
          autoFocus={true}
          ref={messageInputRef}
          value={newMessage}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              sendMessage()
            }
          }}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <img
          src='/media/svg/general/emoji.svg'
          className='mx-4 cursor-pointer'
          alt=''
          onClick={toggleEmojiPicker}
        />
        {showEmojiPicker && (
          <div
            style={{position: 'absolute', bottom: '60px', zIndex: '1', right: '20px'}}
            ref={emojiPickerRef}
          >
            <EmojiPicker skinTonesDisabled={true} onEmojiClick={onEmojiClick} />
          </div>
        )}
        <img
          src='/media/svg/general/link.svg'
          className='mx-1 cursor-pointer'
          alt=''
          onClick={() => setShowLinkModal(true)}
        />

        {showLinkModal && (
          <InsertLinkModal
            handleClose={handleLinkClose}
            showModal={showLinkModal}
            onLinkInsert={handleLinkInsert}
          />
        )}
        <button
          className='btn btn-sm btn-primary mx-5 my-3'
          type='button'
          id='button-addon2'
          onClick={() => sendMessage()}
          disabled={newMessage.trim() ? false : true || isPending}
        >
          <i className='bi bi-send p-0 m-0 fs-5'></i>
        </button>
      </div>
    </div>
  )
}

export default MessagesInput
