import {Button, Form, Modal} from 'react-bootstrap'
import {Formik, Field, Form as FormikForm, ErrorMessage, FieldArray} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'

import {useCreatePipeline, useUpdatePipeline} from '../../../queries/pipelines'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Pipeline Name is required'),
  template: Yup.string().optional(),
  stages: Yup.array().when('template', (template: any, schema) => {
    return template === ''
      ? schema.of(Yup.string().required('At least one stage is required'))
      : schema
  }),
})

function PipeLineForm({
  setShowPipeLineModal,
  pipelineValues,
}: {
  setShowPipeLineModal: () => void
  pipelineValues?: any
}) {
  const initialValues = {
    name: '',
    template: 'default',
    stages: [''],
  }

  const navigate = useNavigate()

  const {mutateAsync: createPipeline, isPending} = useCreatePipeline()
  const {mutateAsync: updatePipeline, isPending: isUpdating} = useUpdatePipeline()

  return (
    <div>
      <Formik
        initialValues={
          !pipelineValues
            ? initialValues
            : {
                name: pipelineValues.name,
              }
        }
        validationSchema={
          !pipelineValues
            ? validationSchema
            : Yup.object().shape({
                name: Yup.string().required('Pipeline Name is required'),
              })
        }
        onSubmit={async (values: any, {resetForm}) => {
          try {
            if (!pipelineValues) {
              // Remove `stages` if it is empty or if the template is `default`
              const payload = {
                ...values,
                stages:
                  values.template === 'default' ||
                  values.stages.every((stage: string) => !stage.trim())
                    ? undefined
                    : values.stages.filter((stage: string) => stage.trim()),
              }

              if (!payload.stages) {
                delete payload.stages // Ensure `stages` key is removed completely
              }

              const response = await createPipeline(payload)
              navigate(`?pipelineId=${response?.uuid}`)
            } else {
              await updatePipeline({pipelineId: pipelineValues.uuid, data: values})
            }
            setShowPipeLineModal()
            resetForm()
          } catch (error) {
            console.error(error)
          }
        }}
      >
        {({values, setFieldValue}: any) => (
          <FormikForm>
            <div>
              <h6 className='required'>Pipeline Name</h6>
              <Form.Group controlId='validationFormik101' className='mt-3'>
                <Field type='text' name='name' placeholder='Name....' className='form-control' />
                <ErrorMessage name='name' component='div' className='text-danger' />
              </Form.Group>
            </div>
            {!pipelineValues && (
              <>
                <div className='my-3'>
                  <h6>Select Template</h6>
                  <div className='d-flex align-items-start'>
                    <div className='me-2 position-relative'>
                      <img
                        className='cursor-pointer default-img object-fit-contain object-center rounded'
                        style={{
                          border:
                            values.template === 'default'
                              ? '1px solid #15498B'
                              : '1px solid lightgray',
                        }}
                        onClick={() => setFieldValue('template', 'default')}
                        src='/media/avatars/pipeline-default-template.png'
                        alt=''
                      />
                      <p className='fw-bold mb-0 mt-1 ms-2'>Default Template</p>
                    </div>
                    <div className='ms-2 position-relative'>
                      <div
                        className='blank-template cursor-pointer'
                        onClick={() => setFieldValue('template', '')}
                        style={{
                          border:
                            values.template === '' ? '1px solid #15498B' : '1px solid lightgray',
                        }}
                      >
                        <i className='fa-solid fa-plus'></i>
                      </div>
                      <p className='fw-bold mb-0 mt-1 ms-2'>Blank Template</p>
                    </div>
                  </div>
                </div>
                {values.template === '' && (
                  <div className='my-3'>
                    <h5 className='required'>Add Stages</h5>
                    <FieldArray name='stages'>
                      {({push, remove}) => (
                        <>
                          {values.stages.map((_: any, index: number) => (
                            <div key={index} className='mb-3'>
                              <Form.Group className='position-relative w-100'>
                                <Field
                                  type='text'
                                  name={`stages[${index}]`}
                                  placeholder='Stage Name....'
                                  className='form-control'
                                />
                                <ErrorMessage
                                  name={`stages[${index}]`}
                                  component='div'
                                  className='text-danger'
                                />
                                <div className='d-flex align-items-start justify-content-end mt-2'>
                                  {index === values.stages.length - 1 && (
                                    <Button
                                      onClick={() => push('')}
                                      style={{padding: '10px 14px'}}
                                      size='sm'
                                      className='btn btn-primary mt-3 mx-1'
                                    >
                                      <i className='fa-solid text-fs-12 p-0 fa-plus'></i>
                                    </Button>
                                  )}
                                  {values.stages.length > 1 && (
                                    <Button
                                      onClick={() => remove(index)}
                                      style={{padding: '10px 14px'}}
                                      size='sm'
                                      className='btn btn-danger mt-3 mx-1'
                                    >
                                      <i className='fa-solid text-fs-12 p-0 fa-minus'></i>
                                    </Button>
                                  )}
                                </div>
                              </Form.Group>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>
                )}
              </>
            )}
            <Modal.Footer className='px-0 py-4'>
              <Button
                variant='secondary'
                onClick={setShowPipeLineModal}
                disabled={isPending || isUpdating}
              >
                Close
              </Button>
              <Button variant='primary' type='submit' disabled={isPending || isUpdating}>
                Save
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </div>
  )
}

export default PipeLineForm
