import {useAuth} from '../../modules/auth'
import ResourcesHeader from './components/ResourcesHeader'
import ResourcesList from './components/ResourcesList'

import './Resources.scss'

const ResourcesIndex = () => {
  const {selectedCommunity} = useAuth()

  const canManageResources = selectedCommunity?.permissions.canManageResources || false

  return (
    <div className='d-flex flex-column gap-5'>
      <ResourcesHeader canManageResources={canManageResources} />
      <ResourcesList canManageResources={canManageResources} />
    </div>
  )
}

export default ResourcesIndex
