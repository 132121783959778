import React, {useState} from 'react'
import moment from 'moment'
import {Button} from 'react-bootstrap'

import {useDeletePipelineStageCardComment} from '../../../queries/pipelines'
import Image from '../../../modules/common/Image'
import Attachments from '../../feeds/components/FeedAttachments'
import {useAuth} from '../../../modules/auth'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import PipelineCardCommentForm from './PipelineCardCommentForm'
import RecentCardCommentLikes from './RecentCardCommentLikes'

interface PipelineCardCommentsRowProps {
  pipelineId: string
  stageId: string
  cardId: string
  comment: any
  isReply?: boolean
  pipelineNotificationsResult: any
  toggleReplyField: (value: string) => void
}

const PipelineCardCommentsRow: React.FC<PipelineCardCommentsRowProps> = ({
  pipelineId,
  stageId,
  cardId,
  comment,
  isReply = false,
  pipelineNotificationsResult,
  toggleReplyField,
}) => {
  const [editingIndex, setEditingIndex] = useState<any>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [commentToDelete, setCommentToDelete] = useState<any>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const {currentUser} = useAuth()

  const hasCommentNotification = pipelineNotificationsResult?.some(
    (notification: any) =>
      notification.meta?.type === 'PIPELINE_NEW_COMMENT' &&
      notification.meta?.pipelineId === pipelineId &&
      notification.meta?.pipelineCardId === cardId &&
      notification.meta?.commentId === comment.id &&
      !notification.readStatus
  )

  const deleteCommentMutation = useDeletePipelineStageCardComment()

  const editComment = (index: any) => {
    setEditingIndex(index)
  }

  const deleteComment = async () => {
    if (commentToDelete) {
      try {
        await deleteCommentMutation.mutateAsync({
          pipelineId: pipelineId,
          stageId: stageId,
          cardId: cardId,
          commentId: commentToDelete.id,
        })
        setCommentToDelete(null)
        setShowConfirmationModal(false)
      } catch {}
    }
  }

  const formatComment = (comment: string) => {
    const comments = comment?.split(' ')
    return comments.map((comment, index) => {
      if (comment.startsWith('http://') || comment.startsWith('https://')) {
        return (
          <a
            className='comments-link'
            key={index}
            href={comment}
            target='_blank'
            rel='noopener noreferrer'
          >
            {index === 0 ? comment : ` ${comment}`}
          </a>
        )
      } else {
        return <span key={index}>{index === 0 ? comment : ` ${comment}`}</span>
      }
    })
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const renderCommentContent = () => {
    if (comment.comment?.length > 200) {
      if (isExpanded) {
        return (
          <>
            <p className='m-0 p-0'>{formatComment(comment.comment)}</p>
            <button onClick={toggleExpand} className='btn text-primary p-0'>
              Read Less
            </button>
          </>
        )
      } else {
        return (
          <>
            <p className='m-0 p-0'>{formatComment(comment.comment.slice(0, 200).concat('...'))}</p>
            <button onClick={toggleExpand} className='btn text-primary p-0'>
              Read More
            </button>
          </>
        )
      }
    } else {
      return formatComment(comment.comment)
    }
  }

  return (
    <div
      className='comment'
      style={{
        marginLeft: isReply ? '60px' : '0px',
      }}
      key={comment.id}
    >
      <div className='d-flex my-3 align-items-start position-relative'>
        <Image
          src={`${comment.user?.absoluteProfilePath}`}
          className='feed-profile-pic rounded-circle object-cover'
        />
        <div
          style={{border: '1px solid #E3E3E3'}}
          className='mx-3 py-3 overflow-x-scroll w-100 bg-transparent rounded'
        >
          <p className='my-0 mx-3 text-fs-16 text-black fw-bold'>
            {comment.user && `${comment.user.firstName} ${comment.user.lastName}`} |
            <span style={{marginLeft: '5px'}}>
              {comment && comment.createdAt && moment(comment.createdAt).fromNow()}
            </span>
          </p>
          <div className='w-100'>
            {editingIndex === comment.id ? (
              <div className='d-flex ml10'>
                <PipelineCardCommentForm
                  pipelineId={pipelineId}
                  stageId={stageId}
                  cardId={cardId}
                  setEditingIndex={setEditingIndex}
                  editedComment={comment}
                />
              </div>
            ) : (
              <>
                <div className='my-1 mx-3 text-black text-fs-14 word-wrap-break-word'>
                  {renderCommentContent()}{' '}
                  {hasCommentNotification && (
                    <span className='notification-indicator ' style={{right: '7px'}}></span>
                  )}
                </div>
                <div className='d-flex align-items-center'>
                  <Attachments attachments={comment.attachments} hideDeleteIcon={false} />
                </div>
              </>
            )}
          </div>
        </div>
        {currentUser?.id === comment.user.id && !editingIndex && (
          <div className='dropdown position-absolute top-0 end-0 mt-2 me-2'>
            <button
              className='btn btn-transparent text-gray'
              type='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <i className='fa-solid fs-2 feed-menu-icon fa-ellipsis'></i>
            </button>
            <ul className='dropdown-menu'>
              <li
                className='dropdown-item text-primary fw-normal cursor-pointer'
                onClick={() => editComment(comment.id)}
              >
                <i className='fa fa-pencil text-primary me-2'></i> Edit
              </li>
              <li
                onClick={() => {
                  setShowConfirmationModal(true)
                  setCommentToDelete(comment)
                }}
                className='dropdown-item text-primary fw-normal cursor-pointer'
              >
                <i className='fa fa-trash text-primary me-2'></i> Delete
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className='d-flex flex-row align-items-center' style={{marginLeft: '66px'}}>
        <div>
          <RecentCardCommentLikes
            pipelineId={pipelineId}
            stageId={stageId}
            cardId={cardId}
            likes={comment?.pipelineStageCardCommentLikes}
            currentUserId={currentUser?.id}
            commentId={comment.id}
          />
        </div>
        <div>
          {!isReply && (
            <Button
              variant='outline-secondary'
              size='sm'
              className='text-black text-fs-12 ms-1'
              onClick={() => toggleReplyField(comment.id)}
            >
              Reply ({comment.replies.length})
            </Button>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          showModal={showConfirmationModal}
          onHideModal={() => {
            setShowConfirmationModal(false)
            setCommentToDelete(null)
          }}
          disableBtns={deleteCommentMutation?.isPending}
          onConfirm={deleteComment}
          onCancel={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  )
}

export default PipelineCardCommentsRow
