import {Button} from 'react-bootstrap'
import moment from 'moment-timezone'

import {useGetPipelineHistory} from '../../../queries/pipelines'
import {useAuth} from '../../../modules/auth'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import {fieldNameMap} from '../pipelines.utils'

const PipelineHistory = ({
  pipelineId,
  pipelineName,
  onHide,
}: {
  pipelineId: string
  pipelineName: string
  onHide: () => void
}) => {
  const {getUserTimezone} = useAuth()

  const {data: pipelineHistory, isFetching} = useGetPipelineHistory(pipelineId)

  return (
    <div className='mb-3'>
      <div>
        <h5 className='fw-bold'>Pipeline Name</h5>
        <div className='border rounded p-3 mb-3'>{pipelineName}</div>
      </div>
      <div>
        <h5 className='fw-bold'>History</h5>
        {isFetching && <FetchingSpinner />}
        {!isFetching &&
          pipelineHistory?.map((history: any) => (
            <div key={history.id} className='border rounded p-3 mb-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='fw-bolder text-fs-14 mb-1'>{history.heading}</p>
                <span className='text-muted mt-2'>
                  {moment
                    .utc(history.updatedAt)
                    .tz(getUserTimezone())
                    .format('DD/MM/YYYY | hh:mm:ss')}
                </span>
              </div>
              {history.action === 'deleted' && history.currentValues?.name && (
                <p>
                  <strong>Name: </strong>
                  <span>{history.currentValues?.name}</span>
                </p>
              )}
              {history.recipient && history.entityType === 'pipelines_shares' && (
                <p>
                  <strong>Recipient: </strong>
                  <span>{history.recipient.fullName}</span>
                </p>
              )}
              {history.changes.map((change: any, index: number) => (
                <>
                  {(change.previous || change.new) && (
                    <p key={index}>
                      <strong>{fieldNameMap[change.fieldName] || change.fieldName}:</strong>
                      <span className='text-danger text-decoration-line-through'>
                        {' '}
                        {change.previous}
                      </span>{' '}
                      <span>{change.new}</span>
                    </p>
                  )}
                </>
              ))}
              <p className='d-end fw-bold fst-italic m-0'>{history.updatedBy}</p>
            </div>
          ))}
      </div>

      <div className='d-end'>
        <Button variant='secondary' className='mx-1' onClick={onHide}>
          Close
        </Button>
      </div>
    </div>
  )
}

export default PipelineHistory
