import {useAuth} from '../../../modules/auth'

const WelcomeScreen = ({
  onSelectDefaultMessage,
}: {
  onSelectDefaultMessage: (message: string) => void
}) => {
  const {currentUser} = useAuth()

  return (
    <div className='d-flex justify-content-center flex-column align-items-center w-100 h-100 gap-9'>
      <div className='text-center'>
        <h1 style={{fontSize: '38px', fontWeight: 700}} className='container-heading text-primary'>
          Hello,&nbsp;
          <span className='chat-heading'>
            {currentUser?.firstName} {currentUser?.lastName}
          </span>
        </h1>
        <h4 style={{fontSize: '38px', fontWeight: 700}} className='container-heading text-primary'>
          Unlock Your Success with GovChat
        </h4>
        <h4 style={{color: '#9B9C9E'}} className='container-subheading text-fs-16 sw-bold'>
          Your AI-Powered Assistant for Government Contracts
        </h4>
      </div>

      <div className='d-flex gap-3'>
        <div
          className='default-msg-card default-lg-card cursor-pointer text-fs-14 fw-bold align-items-baseline'
          onClick={() => onSelectDefaultMessage('How can I win a contract?')}
        >
          How can I win a contract?
          <i className='fa-solid fa-arrow-right text-white'></i>
        </div>
        <div
          className='default-msg-card default-lg-card cursor-pointer text-fs-14 fw-bold align-items-baseline'
          onClick={() => onSelectDefaultMessage('How can I network with other contractors?')}
        >
          How can I network with other contractors?
          <i className='fa-solid fa-arrow-right text-white'></i>
        </div>
        <div
          className='default-msg-card default-lg-card cursor-pointer text-fs-14 fw-bold align-items-baseline'
          onClick={() => onSelectDefaultMessage('How do I highlight my successful bids?')}
        >
          How do I highlight my successful bids?
          <i className='fa-solid fa-arrow-right text-white'></i>
        </div>
        <div
          className='default-msg-card default-lg-card cursor-pointer text-fs-14 fw-bold align-items-baseline'
          onClick={() => onSelectDefaultMessage('How can I find specific government contracts?')}
        >
          How can I find specific government contracts?
          <i className='fa-solid fa-arrow-right text-white'></i>
        </div>
      </div>
    </div>
  )
}

export default WelcomeScreen
