import {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import NoteForm from './NoteForm'
import NoteCard from './NoteCard'
import {useGetNotes} from '../../queries/notes'
import {Pagination} from '../../hooks/Pagination'
import EmptyMessage from '../../modules/common/EmptyMessage'
import NotesFilter from './NoteFilter'
import UpcomingEvents from '../../layout/components/UpComingEvents'

function NotesIndex() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page') || 1
  const type = searchParams.get('type') || ''

  const [showNoteFormModal, setShowNoteFormModal] = useState(false)
  const notesResult = useGetNotes({type})

  const handleModalShow = () => {
    setShowNoteFormModal(true)
  }

  const handlePage = (page: number) => {
    navigate(`?page=${page}`)
  }

  useEffect(() => {
    notesResult.refetch()
  }, [page])

  let notes = notesResult.data?.notes || []

  return (
    <div className='kt-content-main' style={{minWidth: '700px'}}>
      <div className='kt-left-section notes-container'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div>
            <h1 className='m-0'>Notes</h1>
          </div>
          <div>
            <button
              className='btn btn-primary d-flex flex-row align-items-center'
              onClick={handleModalShow}
            >
              <i className='fa-solid fa-add add-pipeline-icon m-0'></i>
              <span>New Note</span>
            </button>
          </div>
        </div>
        <div>
          <NotesFilter />
        </div>
        {!notesResult.isLoading && notes.length === 0 ? (
          <div className='mt-5'>
            <EmptyMessage message={'You have no notes'} />
          </div>
        ) : (
          notes.map((note: any, index: number) => (
            <div key={index}>
              <NoteCard note={note} index={index} />
            </div>
          ))
        )}

        <div className='d-flex mt-3 justify-content-center align-items-center'>
          {notesResult.data?.totalPages > 1 && (
            <Pagination
              page={notesResult.data.page}
              totalPages={notesResult.data.totalPages}
              handlePagination={handlePage}
            />
          )}
        </div>
      </div>
      {showNoteFormModal && (
        <NoteForm show={showNoteFormModal} handleClose={() => setShowNoteFormModal(false)} />
      )}

      <div className='kt-content-right'>
        <div className='kt-content-right_fixed'>
          <UpcomingEvents />
        </div>
      </div>
    </div>
  )
}

export default NotesIndex
