import {useNavigate} from 'react-router-dom'

import ProfilePreview from '../../../modules/common/ProfilePreview'
import Image from '../../../modules/common/Image'
import CustomDropdown from '../../../modules/common/CustomDropDown'

const MessagesHeader = ({recipientUser}: {recipientUser: any}) => {
  const navigate = useNavigate()
  return (
    <div className='d-flex justify-content-between align-items-center border-bottom py-3'>
      <div className='d-flex align-items-center'>
        <Image src={`${recipientUser?.absoluteProfilePath}`} />
        <ProfilePreview user={recipientUser} userNameClass='text-dark' />
      </div>
      <div>
        <CustomDropdown
          elementId={'user-messages'}
          options={[
            {
              label: 'View Profile',
              handler: () => navigate(`/view-profile/${recipientUser.uuid}`),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default MessagesHeader
