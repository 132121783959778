import {Badge} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

const CategoriesFilterCard = ({
  categories,
  selectedCategoryId,
  setSelectedCategoryId,
}: {
  categories: any
  selectedCategoryId: string
  setSelectedCategoryId: (value: string) => void
}) => {
  const navigate = useNavigate()

  const handleCategoryChange = (categoryId: string) => {
    setSelectedCategoryId(categoryId)
    navigate(categoryId ? `?categoryId=${categoryId}` : '/feeds/public')
  }

  return (
    <div className='w-100 rounded pb-2 my-3 overflow-y-scroll d-flex flex-row gap-2'>
      <Badge
        onClick={() => handleCategoryChange('')}
        className={`rounded-pill px-5 py-3 fs-6 text-white cursor-pointer ${
          selectedCategoryId === '' ? 'bg-primary' : 'bg-categories-filter'
        }`}
      >
        <span
          className={`${selectedCategoryId === '' ? '' : 'bg-opacity-50'}`}
          style={{
            background:
              selectedCategoryId === '' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
            zIndex: -1,
          }}
        ></span>
        All
      </Badge>
      {categories?.map((category: any) => (
        <Badge
          key={category.id}
          onClick={() => handleCategoryChange(category.id)}
          className={`rounded-pill px-5 py-3 fs-6 text-white cursor-pointer ${
            selectedCategoryId === category.id ? 'bg-primary' : 'bg-categories-filter'
          }`}
        >
          <span
            className={`${selectedCategoryId === category.id ? '' : 'bg-opacity-50'}`}
            style={{
              background:
                selectedCategoryId === category.id
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.05)',
              zIndex: -1,
            }}
          ></span>
          {category.name}
        </Badge>
      ))}
    </div>
  )
}

export default CategoriesFilterCard
