import {useEffect, useMemo, useState} from 'react'
import {Draggable, Droppable} from 'react-beautiful-dnd'
import Select from 'react-select'
import {Button} from 'react-bootstrap'
import {useSearchParams} from 'react-router-dom'

import CustomDropdown from '../../../modules/common/CustomDropDown'
import PipeLineCard from './PipeLineCard'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useDeletePipelineStage, useUpdatePipelineIdofStage} from '../../../queries/pipelines'
import PipeLineStageForm from './PipeLineStageForm'
import GenericModal from '../../../modules/common/Modal'

interface PipelineStageProps {
  stage: any
  pipelineId: string
  pipelineNotificationsResult: any
  stageContractValueLength: number
  pipelinesData: any
  isLoading: boolean
}

const statusMap: any = {
  submitted: 'submitted',
  won: 'won',
  lost: 'lost',
  inProgress: 'inProgress',
  noBid: 'noBid',
  reviewingContract: 'reviewingContract',
  writingProposal: 'writingProposal',
  newContract: 'newContract',
  pendingAward: 'pendingAward',
}

interface SelectedPipelineObj {
  label: string
  newPipelineId: string
}

const PipeLineStage: React.FC<PipelineStageProps> = ({
  stage,
  pipelineId,
  pipelineNotificationsResult,
  stageContractValueLength,
  pipelinesData,
  isLoading,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditStageModal, setShowEditStageModal] = useState(false)
  const [showPipelinesModal, setShowPipelinesModal] = useState(false)
  const [stageCards, setStageCards] = useState([])
  const [selectedPipeline, setSelectedPipeline] = useState<SelectedPipelineObj>({
    label: '',
    newPipelineId: '',
  })
  const [searchParams] = useSearchParams()
  const filterStatus = searchParams.get('status') || ''

  const {mutateAsync: deletePipelineStage} = useDeletePipelineStage()
  const {mutateAsync: updatePipelineIdofStage} = useUpdatePipelineIdofStage()

  const editStageOptions = useMemo(
    () => [
      {
        label: (
          <>
            <span className='fa-solid fs-7 fa-pencil text-primary'></span>
            <span className='mx-2'>Edit</span>
          </>
        ),
        handler: () => setShowEditStageModal(true),
      },
      {
        label: (
          <>
            <span className='fa-solid fs-7 fa-arrow-right text-primary'></span>
            <span className='mx-2'>Move to</span>
          </>
        ),
        handler: () => setShowPipelinesModal(true),
      },
      {
        label: (
          <>
            <span className='fa-solid fs-7 text-danger fa-trash'></span>
            <span className='mx-2 text-danger'>Archive</span>
          </>
        ),
        handler: () => setShowDeleteModal(true),
      },
    ],
    [stage]
  )

  const handleDeletePipeLine = async () => {
    await deletePipelineStage({pipelineId: pipelineId, stageId: stage.uuid})
    setShowDeleteModal(false)
  }

  const handleMovePipelineStage = async () => {
    try {
      if (selectedPipeline.newPipelineId) {
        await updatePipelineIdofStage({
          pipelineId: pipelineId,
          stageId: stage.uuid,
          data: selectedPipeline,
        })
        setSelectedPipeline({
          label: '',
          newPipelineId: '',
        })
        setShowPipelinesModal(false)
      }
    } catch {}
  }

  const pipelineOptions = useMemo(
    () =>
      pipelinesData?.pipelines
        ?.filter((pipeline: any) => pipeline.uuid !== pipelineId)
        .map((pipeline: any) => ({
          value: pipeline.id,
          label: pipeline.name,
        })) || [],
    [pipelineId, pipelinesData?.pipelines]
  )

  useEffect(() => {
    if (filterStatus && statusMap[filterStatus]) {
      const filterCards = stage.pipelineStageCards.filter(
        (card: any) => card.status === statusMap[filterStatus]
      )
      setStageCards(filterCards)
    } else {
      setStageCards(stage.pipelineStageCards || [])
    }
  }, [stage.pipelineStageCards, filterStatus])

  return (
    <div className='card-container me-2 h-100 overflow-hidden bg-white rounded cursor-default'>
      <div className='px-7 py-5 min-w200 h90 max-h180 bg-primary btl-10 btr-10 position-sticky top-0 z-20'>
        <div className='d-flex flex-row justify-content-between bg-primary'>
          <h4 className='text-white m-0' title={stage.name}>
            {stage.name?.length > 25 ? stage.name?.slice(0, 25).concat('...') : stage.name}
          </h4>
          <div>
            <CustomDropdown
              elementId={'pipelineStage'}
              options={editStageOptions}
              className='stage-dropdown'
            />
          </div>
        </div>
        <div className='d-flex text-light'>
          <span title='Total Contracts Value' className='me-1'>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(stageContractValueLength)}
          </span>
          -{' '}
          <span title='Total Contracts' className='ms-1'>
            {stageCards?.length} Contracts
          </span>
        </div>
      </div>
      <Droppable key={stage.uuid} droppableId={stage.uuid} type='card'>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className='card-list px-3 overflow-y-scroll min-w200'
          >
            {stageCards.map((card: any, cardIndex: number) => (
              <Draggable key={card.uuid} draggableId={card.uuid} index={cardIndex}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='mt-4'
                  >
                    <PipeLineCard
                      card={card}
                      pipelineId={pipelineId}
                      stageId={stage.uuid}
                      pipelineNotificationsResult={pipelineNotificationsResult}
                      pipelinesData={pipelinesData}
                      isLoading={isLoading}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {/* To allow dragging at the end */}
            {<div className='min-h50'></div>}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {showEditStageModal && (
        <PipeLineStageForm
          stageValues={stage}
          pipelineId={pipelineId}
          showStageFormModal={showEditStageModal}
          setShowStageFormModal={setShowEditStageModal}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Archive Stage'
          subtitle='Are you sure you want to archive this Stage?'
          onConfirm={handleDeletePipeLine}
          disableBtns={false}
        />
      )}
      {showPipelinesModal && (
        <GenericModal
          title={'Select a Pipeline'}
          showModal={showPipelinesModal}
          onHideModal={() => setShowPipelinesModal(false)}
          modalProps={{
            centered: true,
          }}
          hideFooter
        >
          <Select
            options={pipelineOptions}
            placeholder='Select a pipeline'
            isLoading={isLoading}
            onChange={(option: any) =>
              setSelectedPipeline({
                label: option.label,
                newPipelineId: option.value,
              })
            }
            className='my-3'
          />
          <div className='d-end'>
            <Button
              variant='secondary'
              className='mx-1'
              onClick={() => {
                setShowPipelinesModal(false)
                setSelectedPipeline({
                  label: '',
                  newPipelineId: '',
                })
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              className='mx-1'
              disabled={!selectedPipeline.newPipelineId}
              onClick={handleMovePipelineStage}
            >
              Move to Pipeline
            </Button>
          </div>
        </GenericModal>
      )}
    </div>
  )
}

export default PipeLineStage
