import React, {useState, useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import UsersList from './components/UsersList'
import UserThreads from './components/UserThreads'
import UserDetail from './components/UserDetail'
import ThreadMessages from './components/ThreadMessages'
import {useAuth} from '../../modules/auth'
import {useFetchUsers} from '../../queries/threads'
import NewThreadModal from './components/NewThreadModal'

import './components/Users.css'

const ThreadsIndex = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {currentUser, selectedCommunity} = useAuth()
  const userId = searchParams.get('userId')
  const threadId = searchParams.get('threadId')
  const [selectedUser, setSelectedUser] = useState<{id?: number}>({})
  const [showChatModal, setShowChatModal] = useState(false)

  const usersResult = useFetchUsers()

  const onClickUser = (user: any) => {
    setSelectedUser(user)
    navigate(`?userId=${user.id}`)
  }

  const onClickThread = (user: any, thread: any) => {
    setSelectedUser(user)
    navigate(`?userId=${userId}&threadId=${thread.id}`)
  }

  const canManageThreads = selectedCommunity?.permissions.canManageThreads

  const handleUserEffect = () => {
    if (userId && usersResult.data?.users) {
      const user = usersResult.data.users.find((user: any) => user.id === parseInt(userId))
      if (user) {
        setSelectedUser(user)
      }
    }

    if (!userId && usersResult.data?.users) {
      navigate(`?userId=${usersResult.data?.users[0]?.id}`)
    }
  }

  useEffect(() => {
    handleUserEffect()
  }, [userId, usersResult])

  useEffect(() => {
    if (!userId && selectedCommunity?.isMember) {
      navigate(`?userId=${currentUser?.id}`)
    }
  }, [])

  return (
    <div className='email-container flex-nowrap d-flex w-100'>
      {canManageThreads && (
        <div className='left-card'>
          <UsersList
            users={usersResult.data?.users}
            isFetching={usersResult.isFetching}
            selectedUser={selectedUser}
            onClickUser={onClickUser}
          />
        </div>
      )}

      {userId && (
        <>
          <div className='mx-4 w-100 middle-card'>
            {threadId && <ThreadMessages threadId={threadId} />}
            {!threadId && <UserThreads selectedUser={selectedUser} onClickThread={onClickThread} />}
          </div>
          {canManageThreads && usersResult.data?.users?.length && (
            <div className='right-Card'>
              <UserDetail userDetail={selectedUser} setShowChatModal={setShowChatModal} />
            </div>
          )}
        </>
      )}

      {showChatModal && (
        <NewThreadModal
          showChatModal={showChatModal}
          setShowChatModal={setShowChatModal}
          selectedUser={selectedUser}
        />
      )}
    </div>
  )
}

export default ThreadsIndex
