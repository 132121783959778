import {useState} from 'react'
import parse from 'html-react-parser'

import {FormatedDateDifference} from '../../../modules/common/utils'
import FeedDetailModal from './FeedDetailModal'
import ProfilePreview from '../../../modules/common/ProfilePreview'
import RecentFeedsComments from './RecentFeedsComments'
import RecentFeedLikes from './RecentFeedLikes'
import Image from '../../../modules/common/Image'

interface FeedIndexCardProps {
  feed: any
  currentUserId: any
}

const FeedIndexCard: React.FC<FeedIndexCardProps> = ({feed, currentUserId}) => {
  const [showFeedDetailModal, setShowFeedDetailModal] = useState(false)

  const renderFeedAttachment = () => {
    const imageAttachment = feed._attachments?.find((attachment: any) =>
      attachment.fileType.includes('image')
    )
    if (imageAttachment) {
      return (
        <div className='w-15 feed-attachments'>
          <img
            className='h100 w100 rounded mb-2 object-cover object-top'
            src={`${process.env.REACT_APP_S3_BASE_URL}${imageAttachment.signedFileName}`}
            alt='img'
          />
        </div>
      )
    }
  }

  const MAX_LENGTH = 160
  return (
    <div className='p-0 mb-8 rounded feeds-card'>
      {feed.isPinned && (
        <div className='bg-primary rounded pin-card d-flex justify-content-between align-items-center'>
          <div>
            <i className='thumbtach-icon fa-solid fa-thumbtack text-light fs-4 p-4 cursor-default'></i>
            <span className=' fw-bold text-light'>Pinned</span>
          </div>
        </div>
      )}
      <div className='container-fluid bg-white shadow-sm py-5 feeds-inner-card border border-light-gray rounded'>
        <div>
          <div className='d-flex align-items-start'>
            <Image
              src={`${feed.user?.absoluteProfilePath}`}
              className='w40 h40 rounded-circle object-cover'
            />
            <div className='feeds-card-header'>
              <ProfilePreview user={feed.user} />
              <p className='text-fs-12 mx-2 my-0 p-0'>
                {' '}
                Posted {FormatedDateDifference(feed.createdAt)}
              </p>
            </div>
          </div>
          <div
            className={`cursor-pointer pt-5 ${
              feed._attachments?.length > 0 &&
              'd-flex justify-content-between align-items-start feed-detail-card'
            }`}
            onClick={() => setShowFeedDetailModal(true)}
          >
            <div className={`${feed._attachments?.length > 0 ? 'w-80' : 'w-100'}`}>
              <h2>{feed.title}</h2>
              <div>
                {feed.description?.length > MAX_LENGTH && (
                  <p className='fs-4'>{parse(feed.description.slice(0, MAX_LENGTH) + '...')}</p>
                )}
                {feed.description?.length <= MAX_LENGTH && (
                  <p style={{wordBreak: 'break-word'}} className='fs-4'>
                    {parse(feed.description)}
                  </p>
                )}
              </div>
            </div>
            {renderFeedAttachment()}
          </div>
          <div className='d-flex py-1 rounded justify-content-start align-items-baseline'>
            <RecentFeedLikes
              likes={feed.feedLikes}
              feedId={feed.id}
              currentUserId={currentUserId}
            />
            <RecentFeedsComments
              comments={feed.comments}
              totalCommentsCount={feed.totalCommentsCount}
            />
          </div>
        </div>
      </div>
      <FeedDetailModal
        showFeedDetailModal={showFeedDetailModal}
        feed={feed}
        handleModalClose={() => setShowFeedDetailModal(false)}
      />
    </div>
  )
}

export default FeedIndexCard
