import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

export const getTeamMembers = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/team`)
  return response.data?.data || []
}

export const inviteTeamMember = async (inviteData: any) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/team/invite`, inviteData)
  return response.data?.data || {}
}

export const resendInvite = async (inviteId: number) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/team/invite/resend`, {
    inviteId,
  })
  return response.data?.data || {}
}

export const deleteTeamMember = async (inviteId: number) => {
  const response = await apiClient.delete(`/c/${getSelectedCommunityId()}/team/${inviteId}`)
  return response.data?.data || {}
}
