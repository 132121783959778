import {useState} from 'react'
import moment from 'moment'
import {Card, ListGroup, Button, Spinner} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'

import {useFetchEvents} from '../../queries/events'
import {useAuth} from '../../modules/auth'
import EventDetailModal from '../../pages/events/components/EventDetailModal'

function UpcomingEvents() {
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [showEventDetailModal, setShowEventDetailModal] = useState(false)
  const navigate = useNavigate()
  const {getUserTimezone, selectedCommunity} = useAuth()
  const startDate = moment().format('YYYY-MM-DD')
  const endDate = moment().add(7, 'days').format('YYYY-MM-DD')

  const {data: upcomingEventsResult, isFetching} = useFetchEvents(startDate, endDate)

  const bgColors = ['#FF5733', '#50CD89', '#3357FF', '#FF33A1', '#A133FF']

  // Function to get a random color based on the index
  const getRandomBgColor = (index: number) => bgColors[index % bgColors.length]
  return (
    <div>
      <Card
        style={{minWidth: '22rem', maxWidth: '420px'}}
        className='shadow-sm mb-12 border border-2'
      >
        <Card.Header className='bg-gradient-primary min-h40 py-3 rounded'>
          <h4 className='m-0 text-light'>Upcoming Events & Due Dates</h4>
        </Card.Header>

        <ListGroup variant='flush' className='bg-transparent sidebar-widget-content'>
          {upcomingEventsResult?.events?.length < 1 && (
            <ListGroup.Item className='fs-5 text-start text-gray-700'>
              No Events Available
            </ListGroup.Item>
          )}

          {!upcomingEventsResult?.events && isFetching && (
            <Spinner animation='border' className='m-3' variant='primary' />
          )}

          {upcomingEventsResult?.events?.slice(0, 10).map((event: any, index: number) => {
            const eventStartDate = moment.utc(event.startDate).tz(getUserTimezone())
            const eventEndDate = moment.utc(event.endDate).tz(getUserTimezone())
            const bgColor = getRandomBgColor(index)
            return (
              <ListGroup.Item
                key={index}
                className='d-flex justify-content-between align-items-center py-3 mx-4'
              >
                <div className='text-center' style={{width: '50px'}}>
                  <h5 className='mb-0'>{eventStartDate.format('DD')}</h5>
                  <div
                    style={{
                      backgroundColor: bgColor,
                    }}
                    className='py-1 bbr-5 bbl-5'
                  >
                    <small className='text-uppercase text-light'>
                      {eventStartDate.format('MMM')}
                    </small>
                  </div>
                </div>
                <div className='ms-3 flex-grow-1'>
                  <p className='mb-1 text-dark text-fs-12 fw-bolder'>{event.title}</p>

                  <p className='mb-0 text-muted text-fs-12'>
                    @ {eventStartDate.format('hh:mm A')} - {eventEndDate.format('hh:mm A')}{' '}
                    {moment.tz(getUserTimezone()).format('z')}
                  </p>

                  <Button
                    variant='transparent'
                    className='text-info fw-normal text-fs-12 text-decoration-none p-0 m-0'
                    onClick={() => {
                      setSelectedEvent(event)
                      if (event?.stageId) {
                        navigate(`/pipeline?pipelineId=${event?.pipelineStage?.pipeline?.uuid}`)
                      } else {
                        setShowEventDetailModal(true)
                      }
                    }}
                  >
                    Details
                  </Button>
                </div>
              </ListGroup.Item>
            )
          })}
        </ListGroup>

        <Card.Footer className='text-center border-top border-2 py-3'>
          <Link to='/calendar' className='text-primary text-decoration-none fw-bold text-info fs-6'>
            View More <span className='fa-solid fa-chevron-right'></span>
          </Link>
        </Card.Footer>
      </Card>

      {showEventDetailModal && (
        <EventDetailModal
          showEventDetailModal={showEventDetailModal}
          handleEventDetailModalClose={() => {
            setSelectedEvent(null)
            setShowEventDetailModal(false)
          }}
          selectedEvent={selectedEvent}
          selectedCommunity={selectedCommunity}
          setShowEventDetailModal={setShowEventDetailModal}
          showActions={false}
        />
      )}
    </div>
  )
}

export default UpcomingEvents
