import {useState, useRef, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

import {
  useDeleteUserSearch,
  useGetAllUserSearches,
  useUpdateUserSearch,
  useUpdateUserSearchOrder,
} from '../../../../queries/users'
import {ConfirmationModal} from '../../../../modules/common/ConfirmationModal'
import CustomDropdown from '../../../../modules/common/CustomDropDown'

const SaveSearchIndex = () => {
  const [editingSearchId, setEditingSearchId] = useState<number | null>(null)
  const [searchInputValues, setSearchInputValues] = useState<Record<number, string>>({})
  const [showDeleteSaveSearch, setShowDeleteSaveSearch] = useState(false)
  const [searchToDelete, setSearchToDelete] = useState<number | null>(null)
  const [searches, setSearches] = useState<any[]>([])
  const inputRef = useRef<HTMLInputElement | null>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const {data: userSearchesResult} = useGetAllUserSearches()
  const {mutateAsync: deleteUserSearch} = useDeleteUserSearch()
  const {mutateAsync: updateUserSearch} = useUpdateUserSearch()
  const {mutateAsync: updateUserSearchOrder} = useUpdateUserSearchOrder()

  const confirmDelete = (searchId: number) => {
    setShowDeleteSaveSearch(true)
    setSearchToDelete(searchId)
  }

  const handleDeleteUserSearch = async () => {
    try {
      if (searchToDelete) {
        await deleteUserSearch(searchToDelete)
        setShowDeleteSaveSearch(false)
        setSearchToDelete(null)
      }
    } catch {}
  }

  const handleUpdateUserSearch = async (searchId: number) => {
    const keyword = searchInputValues[searchId]
    try {
      if (keyword) {
        await updateUserSearch({searchId, keyword})
        if (location.pathname === '/search') {
          navigate(`?q=${keyword}`)
        } else {
          navigate(`/search?q=${keyword}`)
        }
        setSearchInputValues((prev) => {
          const updated = {...prev}
          delete updated[searchId]
          return updated
        })
      }
      setEditingSearchId(null)
    } catch {}
  }

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return

    const reorderedSearches = Array.from(searches)
    const [movedItem] = reorderedSearches.splice(result.source.index, 1)
    reorderedSearches.splice(result.destination.index, 0, movedItem)

    setSearches(reorderedSearches)

    // Update order in the backend
    try {
      await updateUserSearchOrder({
        searchId: movedItem.id,
        order: result.destination.index,
      })
    } catch {}
  }

  useEffect(() => {
    if (userSearchesResult?.searches) {
      setSearches(userSearchesResult.searches)
    }
  }, [userSearchesResult])

  useEffect(() => {
    if (editingSearchId !== null && inputRef.current) {
      inputRef.current.focus()
    }
  }, [editingSearchId])

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='save-searches'>
          {(provided) => (
            <div
              className='search-items overflow-y-scroll'
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {searches.map((search, index) => (
                <Draggable key={search.id} draggableId={search.id.toString()} index={index}>
                  {(provided) => (
                    <div
                      className='d-flex align-items-center justify-content-between p-3 cursor-pointer'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {editingSearchId !== search.id && (
                        <h6
                          className='save-search text-dark fw-normal'
                          onClick={() => navigate(`/search?q=${search.keyword}`)}
                        >
                          {search.keyword}
                        </h6>
                      )}
                      {editingSearchId === search.id && (
                        <input
                          ref={inputRef}
                          type='text'
                          className='border-0 bg-transparent outline-none m-0 p-0'
                          value={
                            searchInputValues[search.id] !== undefined
                              ? searchInputValues[search.id]
                              : search.keyword
                          }
                          onChange={(e) =>
                            setSearchInputValues({
                              ...searchInputValues,
                              [search.id]: e.target.value,
                            })
                          }
                        />
                      )}
                      <div className='d-flex align-items-center'>
                        {editingSearchId === search.id && (
                          <i
                            className='fa-solid fa-check fs-3 text-dark me-2'
                            onClick={() => handleUpdateUserSearch(search.id)}
                          ></i>
                        )}
                        <CustomDropdown
                          elementId={`save-search-${index}`}
                          options={[
                            {
                              label: 'Delete Search',
                              handler: () => confirmDelete(search.id),
                            },
                            {
                              label: 'Edit Search',
                              handler: () =>
                                editingSearchId === search.id
                                  ? setEditingSearchId(null)
                                  : setEditingSearchId(search.id),
                            },
                          ]}
                          iconColor='black'
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {showDeleteSaveSearch && (
        <ConfirmationModal
          showModal={showDeleteSaveSearch}
          onHideModal={() => {
            setShowDeleteSaveSearch(false)
            setSearchToDelete(null)
          }}
          onCancel={() => {
            setShowDeleteSaveSearch(false)
            setSearchToDelete(null)
          }}
          title='Delete Search'
          subtitle='Are you sure you want to delete this Search?'
          onConfirm={handleDeleteUserSearch}
          disableBtns={false}
        />
      )}
    </>
  )
}

export default SaveSearchIndex
