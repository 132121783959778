import {Button} from 'react-bootstrap'
import {useNavigate} from 'react-router'
import Image from '../../modules/common/Image'

const ProfilePreview = ({user, showBadge = true, userNameClass}: any) => {
  const navigate = useNavigate()
  return (
    <div className={`dropdown profile-preview-popover w-fit rounded-2`}>
      <p
        id={`dropdownMenuButton${user.id}`}
        data-mdb-toggle='dropdown'
        aria-expanded='false'
        className={`mx-2 text-primary fw-bold my-0 d-flex align-items-center ${userNameClass}`}
      >
        <h4
          style={{lineHeight: '26px'}}
          className='mb-0'
        >{`${user.firstName} ${user.lastName}`}</h4>
        {showBadge && user.userCommunities?.[0]?.isModerator === true && (
          <span className='badge mx-1 bg-secondary'>Moderator</span>
        )}
        {showBadge && user.userCommunities?.[0]?.isCommunityAdmin === true && (
          <span className='badge mx-1 bg-secondary'>Community Admin</span>
        )}
      </p>
      <div
        className='dropdown-menu my-menu w380 profile-preview-card'
        aria-labelledby={`dropdownMenuButton${user.id}`}
      >
        <div className='m-4'>
          <div className='d-flex align-items-center mb-3'>
            <Image
              src={`${user?.absoluteProfilePath}`}
              className='rounded-circle img-fluid h120 w120 object-cover object-top'
            />
            <div className='ml10'>
              <div className='d-flex align-items-start'>
                <h3 className='user-fullname'>
                  <span className='bi bi-person-fill mx-1'></span>
                  {user.firstName} {user.lastName}
                </h3>
              </div>
              {user.state ? (
                <p className='text-gray fw-medium'>
                  <span className='bi bi-geo-alt-fill mx-1'></span>
                  {user.state}
                </p>
              ) : (
                'N/A'
              )}
              {user.bio?.length > 100 && <p title={user.bio}>{user.bio?.slice(0, 100) + '...'}</p>}
              {user.bio?.length < 100 && <p>{user.bio}</p>}
            </div>
          </div>
          <div>
            <Button
              onClick={() => navigate(`/view-profile/${user.uuid}`)}
              variant='primary'
              className='w-100'
            >
              View Profile
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePreview
