import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import moment from 'moment'

import {useGetResources, useMoveResource} from '../../../queries/resources'
import RenderFileIcon from '../../../modules/common/RenderFileIcon'
import EmptyMessage from '../../../modules/common/EmptyMessage'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'

interface MoveResourceModalProps {
  showResourcesMoveModal: any
  setShowResourcesMoveModal: any
  resource: any
}

const MoveResourceModal: React.FC<MoveResourceModalProps> = ({
  showResourcesMoveModal,
  setShowResourcesMoveModal,
  resource,
}) => {
  const [selectedFolderId, setSelectedFolderId] = useState<undefined | string>(undefined)
  const foldersResult = useGetResources({
    page: 1,
    limit: 100,
    folderId: selectedFolderId,
    type: 'folder',
  })

  const foldersData = foldersResult.data
  useEffect(() => {
    foldersResult.refetch()
  }, [selectedFolderId])

  const handleFolderChange = (uuid: string) => {
    setSelectedFolderId(uuid)
  }

  const {mutate: updatePath} = useMoveResource()

  const handleMoveResource = () => {
    if (selectedFolderId) {
      updatePath({
        folderId: resource?.uuid,
        folderData: {newFolderId: selectedFolderId},
      })
    } else {
      updatePath({
        folderId: resource?.uuid,
        folderData: {newFolderId: null},
      })
    }
    setShowResourcesMoveModal(false)
  }
  return (
    <Modal
      show={showResourcesMoveModal}
      onHide={() => setShowResourcesMoveModal(false)}
      size='lg'
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>You're moving {resource.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {foldersData?.resources?.length === 0 && (
            <div className='w-75'>
              <EmptyMessage message='This folder is empty' />
            </div>
          )}
          {foldersResult.isFetching && <FetchingSpinner />}
          {foldersData?.resources?.map((resources: any) => (
            <div key={resources.id} className='d-flex align-items-center justify'>
              {resource.id !== resources.id && (
                <div
                  className='d-flex justify-content-between w-100 h100 border rounded px-3 m-2 py-3 cursor-pointer'
                  onClick={() => handleFolderChange(resources?.uuid)}
                >
                  <div className='d-flex align-items-center'>
                    <div className='text-center'>
                      {resources.resourceType === 'folder' && (
                        <img style={{width: '60px'}} src='/media/books/file.png' alt='img' />
                      )}
                      {resources.resourceType !== 'folder' && (
                        <div>
                          <RenderFileIcon
                            fileType={resources?.attachment?.fileType}
                            className='mb-3 w70'
                          />
                        </div>
                      )}
                    </div>
                    <div className='ml10 mt-5'>
                      <h6>
                        {resources.name.length > 35
                          ? resources.name.slice(0, 35) + '...'
                          : resources.name}
                      </h6>
                      <p className='text-gray'>{moment(resources.updatedAt).format('D/M/YYYY')}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className='d-end'>
          <Button
            variant='secondary'
            className='mr10'
            onClick={() => setShowResourcesMoveModal(false)}
          >
            Close
          </Button>
          <Button variant='primary' onClick={handleMoveResource}>
            Move here
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MoveResourceModal
