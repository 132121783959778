import React from 'react'
import {Button, Modal} from 'react-bootstrap'

interface ConfirmationModalProps {
  title?: string
  subtitle?: string
  showModal: boolean
  onHideModal: () => void
  disableBtns: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  showModal,
  onHideModal,
  disableBtns,
  onConfirm,
  onCancel,
  title = 'Confirm Delete?',
  subtitle = 'Are you sure you want to delete this item?',
}) => {
  return (
    <Modal show={showModal} onHide={onHideModal} backdrop='static'>
      <Modal.Header closeButton>
        <div>
          <h3 className='text-white tet-fs-16'>{title}</h3>
          <p className='text-white text-fs-12'>{subtitle}</p>
        </div>
      </Modal.Header>
      <Modal.Footer>
        <Button variant='secondary' disabled={disableBtns} onClick={onCancel}>
          Cancel
        </Button>
        <Button variant='danger' onClick={onConfirm} disabled={disableBtns}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
