import {useState} from 'react'
import {Badge, Button} from 'react-bootstrap'

import UserChats from './UserChats'
import UserChatMessages from './UserChatMessages'
import {KTIcon} from '../../../_metronic/helpers'
import GenericOffcanvas from '../../modules/common/Offcanvas'

import './GovChat.scss'

function GovChatIndex() {
  const [show, setShow] = useState(false)

  return (
    <div className='d-flex govchat-container w-100 h-100'>
      <div className='user-chat-left-section'>
        <UserChats setShow={setShow} />
      </div>

      <div className='sm-sidebar d-none'>
        <Button variant='transparent' size='sm' onClick={() => setShow(true)}>
          <KTIcon iconName='entrance-left' className='fs-1' />
        </Button>

        <GenericOffcanvas
          showOffcanvas={show}
          onHideOffcanvas={() => setShow(false)}
          title={
            <div className='d-flex align-items-center mb-5'>
              <span className='fs-1 fw-bold mr10'>GOVCHAT</span>
              <Badge bg='success'>
                <span className='text-white'>BETA</span>
              </Badge>
            </div>
          }
        >
          <UserChats setShow={setShow} />
        </GenericOffcanvas>
      </div>

      <div className='user-chat-main-section d-flex justify-content-center w-100'>
        <UserChatMessages />
      </div>
    </div>
  )
}

export default GovChatIndex
