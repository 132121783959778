import {useEffect, useState, useRef} from 'react'
import {Pagination} from '../../hooks/Pagination'
import CommunityCard from './CommunityCard'
import FetchingSpinner from '../../modules/common/FetchingSpinner'
import apiClient from '../../apis/apiClient'
import {useAuth} from '../../modules/auth/core/Auth'
import './CommunityIndex.css'

const CommunityIndex = () => {
  const {selectedCommunity}: any = useAuth()
  const [users, setUsers] = useState([])
  const searchInputRef = useRef<any>()
  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(1)
  const [page, setPage] = useState(1)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    loadConnections(page)
  }, [])

  const loadConnections = (page: number) => {
    setIsFetching(true)
    apiClient
      .get(
        `/users?page=${page}&limit=32&search=${searchInputRef.current.value}&communityId=${selectedCommunity?.uuid}`
      )
      .then((res) => {
        setIsFetching(false)
        const {users} = res.data.data
        setUsers(users.users)
        setTotalPages(users.totalPages)
        setTotalItems(users.totalItems)
      })
      .catch(() => {
        setIsFetching(false)
      })
  }

  const onSearchClick = () => {
    setPage(1)
    loadConnections(1)
  }

  const handlePages = (updatePage: number) => {
    setPage(updatePage)
    loadConnections(updatePage)
  }

  if (isFetching && users.length === 0) {
    return <FetchingSpinner />
  }

  return (
    <>
      <div className='kt-content-main w-100'>
        <div className='community-index-container w-100'>
          <div className='d-flex community-container justify-content-between align-items-center'>
            <div>
              <h1 className='card-title'>{totalItems} user(s) Found</h1>
            </div>
            <div className='search-user'>
              <img src='/media/svg/general/search.svg' alt='' />
              <input
                className='border-0 input-field bg-transparent outline-none btr-10 bbr-10'
                type='text'
                name='membersSearch'
                placeholder='Search User'
                ref={searchInputRef}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    onSearchClick()
                  }
                }}
              />
            </div>
          </div>

          <div className='d-flex flex-wrap'>
            <CommunityCard users={users} />
          </div>
          {totalPages > 1 && (
            <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />
          )}
        </div>
      </div>
    </>
  )
}

export {CommunityIndex}
