import React, {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useCreatePipelineStageCardCommentLike} from '../../../queries/pipelines'

interface RecentCardCommentLikesProps {
  pipelineId: string
  stageId: string
  cardId: string
  likes: any
  currentUserId: number | undefined
  commentId: number
}

const RecentCardCommentLikes: React.FC<RecentCardCommentLikesProps> = ({
  likes,
  cardId,
  currentUserId,
  commentId,
  pipelineId,
  stageId,
}) => {
  const hasCurrentUserLiked = likes?.find((like: any) => like.userId === currentUserId)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [liked, setLiked] = useState(false)
  const {mutateAsync: addCardLike} = useCreatePipelineStageCardCommentLike()

  const handleLikeClick = async () => {
    if (isSubmitting) return // Prevent multiple clicks while submitting

    try {
      setIsSubmitting(true)
      setLiked(true)
      await addCardLike({pipelineId, stageId, cardId, commentId})
      setTimeout(() => {
        setIsSubmitting(false)
        setLiked(false)
      }, 500)
    } catch (err) {
      console.error(err, 'An error occurred')
      setIsSubmitting(false)
      setLiked(false)
    }
  }

  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      {likes?.map((like: any) => (
        <div key={like.id} className='text-decoration-underline'>
          {like.user.fullName}
        </div>
      ))}
      {likes.length === 0 && <div>0 likes</div>}
    </Tooltip>
  )

  return (
    <div className='mx-1 cursor-pointer'>
      <button
        disabled={isSubmitting}
        onClick={handleLikeClick}
        className={`border-0 bg-transparent m-0 p-0 ${liked ? 'feed-liked' : ''} ${
          isSubmitting ? 'cursor-not-allow' : 'cursor-pointer'
        }`}
        style={{color: hasCurrentUserLiked ? '#009ef7' : '#a1a5b7'}}
      >
        {!hasCurrentUserLiked && <img src='/media/svg/general/thumb-up.svg' alt='thumb-up-icon' />}
        {hasCurrentUserLiked && (
          <img src='/media/svg/general/highlighted-thumb.svg' alt='thumb-up-icon' />
        )}
      </button>
      <OverlayTrigger placement='top' delay={{show: 250, hide: 150}} overlay={renderTooltip}>
        <span onClick={handleLikeClick} className='text-fs-12 ms-1 fw-medium text-gray'>
          {likes?.length}
        </span>
      </OverlayTrigger>
    </div>
  )
}

export default RecentCardCommentLikes
