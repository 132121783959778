import apiClient from './apiClient'
import {getSelectedCommunityId} from '../modules/common/utils'

export const updateUserPassword = async (data: {oldPassword: string; newPassword: string}) => {
  const response = await apiClient.post(`users/change-password`, data)
  return response.data?.data || {}
}

export const getStripePortal = async () => {
  const response = await apiClient.get(`/users/stripe/portal`)
  return response.data?.data || []
}

export const getAllUserSearches = async (page?: number, limit?: number) => {
  const response = await apiClient.get(`users/c/${getSelectedCommunityId()}/search`, {
    params: {page, limit},
  })
  return response.data?.data || []
}

export const getUserSearchByKeyword = async (keyword: string) => {
  const response = await apiClient.get(`users/c/${getSelectedCommunityId()}/search/${keyword}`)
  return response.data?.data || []
}

export const createUserSearch = async (keyword: string) => {
  const response = await apiClient.post(`users/c/${getSelectedCommunityId()}/search`, {keyword})
  return response.data?.data || {}
}

export const updateUserSearch = async (searchId: number, keyword: string) => {
  const response = await apiClient.put(`users/c/${getSelectedCommunityId()}/search/${searchId}`, {
    keyword,
  })
  return response.data?.data || {}
}

export const updateUserSearchOrder = async (searchId: number, order: number) => {
  const response = await apiClient.put(
    `users/c/${getSelectedCommunityId()}/search/${searchId}/order/${order}`
  )
  return response.data?.data || {}
}

export const deleteUserSearch = async (searchId: number) => {
  const response = await apiClient.delete(`users/c/${getSelectedCommunityId()}/search/${searchId}`)
  return response.data?.data || {}
}
