import React, {useState} from 'react'
import {useAddFeedLike} from '../../../queries/feeds'
import {useAuth} from '../../../modules/auth'

function FeedCommentLikeAction({feed, setShowLevelDetailModal}: any) {
  const {currentUser} = useAuth()

  const hasCurrentUserLiked = feed.feedLikes?.find((like: any) => like.userId === currentUser?.id)
  const [liked, setLiked] = useState(false)
  const {mutateAsync: postNewFeedLike} = useAddFeedLike()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleLikeClick = async () => {
    if (isSubmitting) return

    try {
      setIsSubmitting(true)
      setLiked(true)
      await postNewFeedLike(feed?.id)

      setTimeout(() => {
        setIsSubmitting(false)
        setLiked(false)
      }, 500)
    } catch (err) {
      console.error(err, 'An error occurred')
      setIsSubmitting(false)
      setLiked(false)
    }
  }

  return (
    <div>
      <div className='d-flex flex-row align-items-center'>
        <div>
          <div className='mx-2 d-flex align-items-center flex-row'>
            <button
              disabled={isSubmitting}
              onClick={handleLikeClick}
              className={`border-0 bg-transparent m-0 p-0 ${liked ? 'feed-liked' : ''} ${
                isSubmitting ? 'cursor-not-allow' : 'cursor-pointer'
              }`}
              style={{color: hasCurrentUserLiked ? '#009ef7' : '#a1a5b7'}}
            >
              {!hasCurrentUserLiked && (
                <img src='/media/svg/general/thumb-up.svg' alt='thumb-up-icon' />
              )}
              {hasCurrentUserLiked && (
                <img src='/media/svg/general/highlighted-thumb.svg' alt='thumb-up-icon' />
              )}
            </button>
            <span
              onClick={() => {
                setShowLevelDetailModal(true)
              }}
              className='text-fs-12 mx-1 fw-bold text-muted cursor-pointer text-decoration-none link-primary'
            >
              {feed.feedLikes.length}
            </span>
          </div>
        </div>

        <div className='mx-2 cursor-pointer d-flex flex-row'>
          <button className='border-0 bg-transparent fs-6 fw-bold'>
            <img src='/media/svg/general/message.svg' alt='thumb-up-icon' />
            <span className='mx-2 text-fs-12 text-muted'>{feed?.comments.length}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default FeedCommentLikeAction
