import {FormatedDateDifferenceComment} from '../../../modules/common/utils'
import Image from '../../../modules/common/Image'

const MessageRow = ({
  messageData,
  isRecipientMessage,
}: {
  messageData: any
  isRecipientMessage: boolean
}) => {
  const formatMessage = (message: string) => {
    const messages = message?.split(' ')
    return messages.map((message, index) => {
      if (message.startsWith('http://') || message.startsWith('https://')) {
        return (
          <a
            className={`${
              isRecipientMessage
                ? 'text-white text-decoration-underline'
                : 'text-info text-decoration-underline'
            }`}
            key={index}
            href={message}
            target='_blank'
            rel='noopener noreferrer'
          >
            {index === 0 ? message : ` ${message}`}
          </a>
        )
      } else {
        return <span key={index}>{index === 0 ? message : ` ${message}`}</span>
      }
    })
  }

  return (
    <div
      className={`chat-item d-block pb-3 ${isRecipientMessage ? 'text-end' : ''}`}
      key={messageData.id}
    >
      <div
        className={`d-flex ${isRecipientMessage ? 'justify-content-end' : 'justify-content-start'}`}
      >
        {!isRecipientMessage && (
          <div className='me-2'>
            <Image src={`${messageData.sender.absoluteProfilePath}`} />
          </div>
        )}
        <div>
          <div
            className={`chat-msg p-3 rounded text-fs-12 ${
              isRecipientMessage
                ? 'bg-primary text-white text-center'
                : 'border border-primary text-primary bg-transparent text-center'
            }`}
            style={{maxWidth: '400px'}}
          >
            {formatMessage(messageData.message)}
          </div>
          <p
            className={`text-fs-10 fw-normal mt-2 ${
              isRecipientMessage ? 'text-end me-1' : 'text-start'
            }`}
          >
            {FormatedDateDifferenceComment(messageData.createdAt)}
          </p>
        </div>
        {isRecipientMessage && (
          <div className='ms-2'>
            <Image src={`${messageData.sender.absoluteProfilePath}`} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MessageRow
