import React, {ChangeEvent, useRef} from 'react'

import apiClient from '../../apis/apiClient'
import {useAuth} from '../auth/core/Auth'
interface FileUploaderProps {
  resourceType: string
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>
  isUploading: boolean
  onFileUpload: (result: any) => void
  btn?: React.ReactNode
}

const FileUploader: React.FC<FileUploaderProps> = ({
  resourceType,
  setIsUploading,
  isUploading,
  onFileUpload,
  btn,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const {selectedCommunity} = useAuth()

  const handleCustomButtonClick = () => {
    if (isUploading) {
      return
    }
    // Trigger the hidden file input when the custom button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || ''

    // Set isUploading to true using the prop updater
    setIsUploading(true)

    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('resourceType', resourceType)
      const response = await apiClient.post(
        `/c/${selectedCommunity?.uuid}/resources/upload-file`,
        formData
      )

      const result = response.data?.data

      // Call the callback function with the uploaded file information
      onFileUpload(result)
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      // Set isUploading to false using the prop updater after the upload is complete (success or failure)
      setIsUploading(false)
    }
  }

  return (
    <>
      <input
        type='file'
        onChange={handleUpload}
        className='d-none'
        ref={fileInputRef}
        accept='image/*, .pdf, .xls, .xlsx, .doc, .docx'
      />
      {btn && <div onClick={handleCustomButtonClick}>{btn}</div>}
      {!btn && (
        <img
          onClick={handleCustomButtonClick}
          src='/media/svg/general/link.svg'
          alt=''
          className='cursor-pointer mx-3'
        />
      )}
    </>
  )
}

export default FileUploader
