/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {Spinner} from 'react-bootstrap'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

import {useUpdatePipelineOrder} from '../../../queries/pipelines'
import GenericModal from '../../../modules/common/Modal'
import PipeLineForm from './PipeLineForm'
import PipelineItem from './PipelineItem'
import {useAuth} from '../../../modules/auth'

const UserPipelines = ({
  pipelineNotificationsResult,
  setShowUserPipeLineModal,
  data,
  isLoading,
}: {
  pipelineNotificationsResult: any
  setShowUserPipeLineModal: (show: boolean) => void
  data: any
  isLoading: boolean
}) => {
  const [showPipeLineModal, setShowPipeLineModal] = useState(false)
  const [reorderedPipelines, setReorderedPipelines] = useState<any>([])
  const {selectedCommunity} = useAuth()
  const pipelines = data?.pipelines
  const sharedPipelines = data?.sharedPipelines
  const {mutateAsync: updateOrder} = useUpdatePipelineOrder()

  useEffect(() => {
    if (pipelines?.length) {
      setReorderedPipelines(pipelines)
    }
  }, [pipelines])

  const handleDragEndPipelines = async (result: any) => {
    if (!result.destination) {
      return
    }

    const [oldPipelines, _reorderedPipelines] = [
      Array.from(reorderedPipelines),
      Array.from(reorderedPipelines),
    ]
    const [removed] = _reorderedPipelines.splice(result.source.index, 1)
    _reorderedPipelines.splice(result.destination.index, 0, removed)

    setReorderedPipelines(_reorderedPipelines)

    try {
      await updateOrder({
        pipelineId: result.draggableId,
        order: result.destination.index + 1,
      })
    } catch (error) {
      console.error('Error updating pipeline order', error)
      setReorderedPipelines(oldPipelines)
    }
  }

  return (
    <>
      {!selectedCommunity?.isTeamMember && (
        <div
          className='p-5 rounded overflow-y-scroll mr10 pipelines-container'
          style={{
            backgroundColor: '#F9F9F9',
            minWidth: '250px',
            minHeight: '250px',
            maxHeight: '300px',
          }}
        >
          <div className='d-flex justify-content-between align-items-baseline mb-3'>
            <h5 className='text-center fw-700'>My Pipelines</h5>
            <button
              className='border-0 bg-primary px-3 py-1 rounded ms-3'
              onClick={() => setShowPipeLineModal(true)}
            >
              <i className='fa-solid fa-add text-light'></i>
            </button>
          </div>

          <DragDropContext onDragEnd={handleDragEndPipelines}>
            <Droppable droppableId='pipelineList'>
              {(provided) => (
                <ul
                  className='nav flex-column'
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {!isLoading && pipelines?.length === 0 && (
                    <p className='fw-bold fs-6 text-center'>No pipelines found.</p>
                  )}
                  {isLoading && (
                    <div className='d-flex justify-content-between'>
                      <Spinner animation='border' role='status'></Spinner>
                    </div>
                  )}
                  {reorderedPipelines?.map((pipeline: any, index: number) => (
                    <Draggable key={pipeline.uuid} draggableId={pipeline.uuid} index={index}>
                      {(provided) => (
                        <li
                          className='nav-item my-1 cursor-pointer'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <PipelineItem
                            pipeline={pipeline}
                            pipelineNotificationsResult={pipelineNotificationsResult}
                            setShowUserPipeLineModal={setShowUserPipeLineModal}
                          />
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}

      {showPipeLineModal && (
        <GenericModal
          title={'Add Pipeline'}
          showModal={showPipeLineModal}
          onHideModal={() => setShowPipeLineModal(false)}
          hideFooter={true}
          modalProps={{
            size: 'md',
            centered: true,
          }}
        >
          <PipeLineForm setShowPipeLineModal={() => setShowPipeLineModal(false)} />
        </GenericModal>
      )}

      {sharedPipelines?.length > 0 && (
        <div
          className='p-5 rounded overflow-y-scroll mr10 mt-5 pipelines-container'
          style={{
            backgroundColor: '#F9F9F9',
            minWidth: '250px',
            minHeight: '250px',
            maxHeight: '300px',
          }}
        >
          <h5 className='mb-2 mt-4 fw-700'>Shared with me</h5>
          <ul className='nav flex-column mt-4'>
            {sharedPipelines?.map((pipeline: any) => (
              <li className='nav-item my-1 cursor-pointer' key={pipeline.id}>
                <PipelineItem
                  pipeline={pipeline}
                  isSharedPipeline
                  pipelineNotificationsResult={pipelineNotificationsResult}
                  setShowUserPipeLineModal={setShowUserPipeLineModal}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

export default UserPipelines
