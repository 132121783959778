import {useState} from 'react'
import {useSearchParams} from 'react-router-dom'

import useQueryParam from '../../hooks/useQueryParam'

const NoteFilter = () => {
  const {addQueryParam} = useQueryParam()
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type') || ''
  const [filterValue, setFilterValue] = useState(type)

  const filters = [
    {id: 1, name: 'All', value: ''},
    {id: 2, name: 'General', value: 'general'},
    {id: 3, name: 'Pipelines', value: 'pipelines'},
    {id: 4, name: 'Classroom', value: 'classroom'},
  ]

  const onFilterChange = (value: string) => {
    setFilterValue(value)
    addQueryParam('type', value)
  }

  return (
    <div className='d-flex flex-wrap'>
      {filters.map((filter) => (
        <div key={filter.id} className='m-1'>
          <button
            className={`filter-btn text-fs-13 rounded-pill fw-bold text-white rounded-pill ${
              filterValue === filter.value ? 'filter-btn-active' : ''
            }`}
            onClick={() => onFilterChange(filter.value)}
          >
            {filter.name}
          </button>
        </div>
      ))}
    </div>
  )
}

export default NoteFilter
