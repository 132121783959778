import {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'

import FolderForm from './FolderForm'
import CustomDropdown from '../../../modules/common/CustomDropDown'
import UploadResource from './UploadResource'

import {useNavigate, useSearchParams} from 'react-router-dom'

interface IResourceHeader {
  canManageResources: boolean
}

const ResourcesHeader = ({canManageResources}: IResourceHeader) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState('')
  const [showFolderModal, setShowFolderModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState<any>(null)
  const [showUploadResourcesModal, setShowUploadResourcesModal] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const handleClose = () => setShowFolderModal(false)

  return (
    <div>
      <div className='community-container flex-wrap justify-content-between align-items-center d-flex'>
        <div className='d-flex align-items-center'>
          <h1>Resources</h1>
        </div>
        <div className='d-flex flex-row gap-3 flex-wrap align-items-center'>
          <div className='d-flex input-search'>
            <span
              style={{
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
              className='align-items-center d-flex px-3 h50'
            >
              <img src='/media/svg/general/search.svg' alt='' />
            </span>
            <input
              style={{
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
              onChange={(event) => setSearch(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  let params = `?search=${search}`
                  if (searchParams.get('folderId')) {
                    params += `&folderId=${searchParams.get('folderId')}`
                  }
                  navigate(params)
                }
              }}
              value={search}
              className='border-0 input-field outline-none'
              type='text'
              name='resourcesSearch'
              placeholder='Search'
            />
          </div>
          <div>
            {canManageResources && (
              <>
                <CustomDropdown
                  elementId={'resources'}
                  dropdownTitle={
                    <button className='header-button'>
                      <i className='fa-solid text-white fs-6 add-resourses-icon fa-plus'></i> Add
                      new
                    </button>
                  }
                  options={[
                    {
                      label: 'Folder Upload',
                      handler: () => {
                        setSelectedResource(null)
                        setShowFolderModal(true)
                      },
                    },
                    {
                      label: 'Files Upload',
                      handler: () => setShowUploadResourcesModal(true),
                    },
                  ]}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {showFolderModal && (
        <FolderForm
          showFolderModal={showFolderModal}
          handleClose={handleClose}
          selectedResource={selectedResource}
        />
      )}
      {showUploadResourcesModal && (
        <Modal
          show={showUploadResourcesModal}
          onHide={() => setShowUploadResourcesModal(false)}
          size='lg'
          centered
          backdrop='static'
        >
          <Modal.Body>
            <UploadResource isUploading={isUploading} setIsUploading={setIsUploading} />
            <div className='d-end'>
              <Button
                variant='secondary'
                disabled={isUploading}
                onClick={() => setShowUploadResourcesModal(false)}
              >
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default ResourcesHeader
