import {useState} from 'react'
import {Card, Dropdown} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

import {useDeleteNotes} from '../../queries/notes'
import moment from 'moment'
import {ConfirmationModal} from '../../modules/common/ConfirmationModal'
import NoteForm from './NoteForm'

import './notes.scss'

function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text
  }
  return text.slice(0, maxLength) + '...'
}

function NoteCard({note, index}: {note: any; index: number}) {
  const navigate = useNavigate()
  const [showEditNoteModal, setShowEditNoteModal] = useState(false)
  const [showDropdown, setShowDropdown] = useState(Array(3).fill(false))
  const {mutateAsync: deleteNote, isPending: isDeletingNote} = useDeleteNotes()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const maxLength = 200

  const handleDeleteNote = async (id: any) => {
    try {
      await deleteNote(note.id)
      setShowDeleteModal(false)
    } catch (error) {
      console.error('Error deleting note:', error)
    }
  }

  const handleDropdownToggle = (index: any) => {
    const newShowDropdown = [...showDropdown]
    newShowDropdown[index] = !newShowDropdown[index]
    setShowDropdown(newShowDropdown)
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const navigateCourseModule = (module: any) => {
    let params = `?moduleId=${module.id}`
    if (module.sectionId) {
      params += `&sectionId=${module.sectionId}`
    }
    navigate(`/courses/detail/${module.course.uuid}${params}`)
  }
  const isExternalNote = note.courseModule?.course || note.pipeline
  return (
    <div>
      <Card className='notes-card'>
        <div className={`d-flex align-items-center justify-content-between`}>
          {note.courseModule?.course && (
            <div
              className='pipeline-card-badge text-fs-12 text-white m-0 rounded cursor-pointer'
              onClick={() => navigateCourseModule(note.courseModule)}
            >
              Classroom: {note.courseModule?.course.title}
            </div>
          )}
          {note.pipeline && (
            <div
              className='pipeline-card-badge text-fs-12 text-white m-0 rounded cursor-pointer'
              onClick={() => navigate(`/pipeline?pipelineId=${note.pipeline.uuid}`)}
            >
              Pipeline: {note.pipeline.name}
            </div>
          )}

          {!isExternalNote && (
            <div className='pipeline-card-badge text-fs-12 text-white m-0 rounded cursor-pointer'>
              General
            </div>
          )}

          <div>
            <Dropdown show={showDropdown[index]} onToggle={() => handleDropdownToggle(index)}>
              <Dropdown.Toggle variant='none' id={`dropdown-basic-${index}`} size='sm'>
                <i className='fa-solid fs-2 feed-menu-icon fa-ellipsis p-0 m-0'></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className='cursor-pointer'
                  onClick={() => setShowEditNoteModal(true)}
                >
                  <i className='fa fa-pencil mx-2'></i> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className='cursor-pointer text-danger'
                  onClick={() => setShowDeleteModal(true)}
                >
                  <i className='fa fa-trash mx-2 text-danger'></i> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div onClick={toggleExpand}>
          <div>
            <h1 className='text-break text-fs-28 fw-medium'>{note.title}</h1>
          </div>
          <p
            className='text-fs-18 fw-normal'
            dangerouslySetInnerHTML={{
              __html: expanded ? note.description : truncateText(note.description, maxLength),
            }}
          />
          {note.description.length > maxLength && (
            <button
              className='expand-btn text-info bg-transparent border-0 p-0 text-fs-13'
              onClick={toggleExpand}
            >
              {expanded ? 'Less' : 'Expand'}
            </button>
          )}
        </div>
        <div>
          <p className='text-gray fw-bold text-fs-13'>{moment(note?.createdAt).fromNow()}</p>
        </div>
      </Card>
      {showEditNoteModal && (
        <NoteForm
          show={showEditNoteModal}
          handleClose={() => setShowEditNoteModal(false)}
          note={note}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Delete Note'
          subtitle='Are you sure you want to delete this note?'
          onConfirm={() => handleDeleteNote(note.id)}
          disableBtns={isDeletingNote}
        />
      )}
    </div>
  )
}

export default NoteCard
