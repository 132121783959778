import {useState} from 'react'
import {Formik, Form, Field} from 'formik'
import FormSelect from 'react-bootstrap/Form'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'

import FeedAttachments from './FeedAttachments'
import EditorIndex from '../../../modules/editor/Editor'
import {useGetCategories} from '../../../queries/categories'
import {useCreateFeed, useUpdateFeed} from '../../../queries/feeds'

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
})

interface FeedFormProps {
  feed?: any
  setShowCreateFeedModal: any
}

const FeedForm: React.FC<FeedFormProps> = (props) => {
  const createFeedMutation = useCreateFeed()
  const updateFeedMutation = useUpdateFeed()
  const {feed = null} = props
  const [isLoading, setIsLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [attachments, setAttachments] = useState<any>(feed?._attachments || [])
  const categoriesResult = useGetCategories()
  const categories = categoriesResult?.data?.categories || []

  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(
    feed?.categoryId || categories?.[0]?.id
  )
  const navigate = useNavigate()

  const initialValues = {
    title: feed?.title || '',
    description: feed?.description || '',
  }

  const onFileUpload = (result: any) => {
    setAttachments([...attachments, result])
  }

  const handleDeleteFile = (index: number) => {
    const updatedAttachments = [...attachments]
    updatedAttachments.splice(index, 1)
    setAttachments(updatedAttachments)
  }

  const handleOnSubmit = async (values: any, {resetForm}: any) => {
    try {
      setIsLoading(true)
      const trimmedTitle = values.title.trim()
      if (feed?.id) {
        await updateFeedMutation.mutateAsync({
          feedId: feed.id,
          feedData: {
            title: trimmedTitle,
            description: values.description,
            attachments,
            categoryId: selectedCategoryId,
          },
        })
      } else {
        await createFeedMutation.mutateAsync({
          title: trimmedTitle,
          description: values.description,
          attachments,
          categoryId: selectedCategoryId,
        })
      }
      resetForm()
      props.setShowCreateFeedModal(false)
    } catch (error: any) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({errors, touched, handleChange, values}: any) => (
          <Form>
            <div className={`${feed?.id && 'card py-3'}`}>
              <div>
                <Field
                  type='text'
                  name='title'
                  className='form-control text-fs-20 fw-bolder title-field text-black'
                  placeholder='Title'
                />
                <div>
                  <div>
                    {errors.title && touched.title && (
                      <div className='text-danger'>{errors.title}</div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <EditorIndex
                  value={values.description}
                  name='description'
                  handleChange={handleChange}
                  initialValue={initialValues.description}
                  resourceType='feeds'
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  onFileUpload={onFileUpload}
                />
                <FeedAttachments
                  handleDeleteFile={handleDeleteFile}
                  isUploading={isUploading}
                  attachments={attachments}
                />
                {errors.description && touched.description && (
                  <div className='text-danger'>{errors.description}</div>
                )}
              </div>
              <div className='d-flex justify-content-end my-2'>
                <FormSelect.Select
                  className='w200 cursor-pointer rounded-pill'
                  aria-label='Default select example'
                  value={selectedCategoryId}
                  onChange={(e) => setSelectedCategoryId(parseInt(e.target.value))}
                >
                  {categories.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </FormSelect.Select>

                <button
                  onClick={() => {
                    const hasUnsavedChanges = (
                      Object.keys(initialValues) as Array<keyof typeof initialValues>
                    ).some((fieldName) => initialValues[fieldName] !== values[fieldName])

                    if (attachments.length > 0 || hasUnsavedChanges) {
                      const confirmDiscard = window.confirm(
                        "You haven't finished your post yet. Do you want to leave without finishing?"
                      )
                      if (confirmDiscard) {
                        props.setShowCreateFeedModal(false)
                      }

                      if (!confirmDiscard) {
                        return
                      }
                    } else if (props.setShowCreateFeedModal) {
                      props.setShowCreateFeedModal(false)
                    } else {
                      navigate('/feeds/public')
                    }
                  }}
                  className='btn btn-light mx-3'
                  type='button'
                  disabled={isUploading === true}
                >
                  Discard
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={
                    isLoading === true ||
                    isUploading === true ||
                    values.title.trim() === '' ||
                    values.description === ''
                  }
                >
                  {isLoading && 'Loading...'}
                  {!isLoading && 'Submit'}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default FeedForm
