import React, {useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Formik, Field, Form as FormikForm, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import {useCreatePipelineStageCard, useUpdatePipelineStageCard} from '../../../queries/pipelines'
import EditorIndex from '../../../modules/editor/Editor'
import Attachments from '../../feeds/components/FeedAttachments'
import {useAuth} from '../../../modules/auth'
import {rewriteDateInOtherTimezone} from '../../../modules/common/utils'
import {statusOptions, statusClassMap} from '../pipelines.utils'

import 'react-datepicker/dist/react-datepicker.css'

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  preBidDate: Yup.date().nullable().optional(),
  questionDeadline: Yup.date().nullable().optional(),
  dueDate: Yup.date().nullable().optional(),
  contractValue: Yup.number().optional(),
  workScope: Yup.string().required('Scope of Work is required'),
  contractLink: Yup.string().required('Contract Link is required'),
  agency: Yup.string().required('Agency is required'),
  contractLocation: Yup.string().required('Place of Performance is required'),
  status: Yup.string().required('Status is required'),
  description: Yup.string().optional(),
  attachments: Yup.array()
    .of(
      Yup.object().shape({
        originalFileName: Yup.string().required(),
        signedFileName: Yup.string().required(),
        fileType: Yup.string().required(),
      })
    )
    .optional(),
})

function PipeLineCardForm({
  pipelineId,
  stageId,
  pipelineStages = [],
  stagesCardValues,
  showCardModal,
  setShowCardModal,
  setShowPipeLineDetailModal,
}: {
  pipelineId?: any
  stageId?: any
  pipelineStages?: any
  stagesCardValues?: any
  showCardModal: boolean
  setShowCardModal: (show: boolean) => void
  setShowPipeLineDetailModal?: any
}) {
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const {getUserTimezone} = useAuth()

  const stageOptions = pipelineStages.map((stage: any) => ({value: stage.uuid, label: stage.name}))
  const updateCard = useUpdatePipelineStageCard()
  const createCard = useCreatePipelineStageCard()
  const currentTimezone = getUserTimezone()

  const handleCloseCardModal = () => {
    setShowCardModal(false)
    if (setShowPipeLineDetailModal) setShowPipeLineDetailModal(false)
  }

  const agencyOptions = [
    {value: 'federal', label: 'Federal Contract'},
    {value: 'state', label: 'State Contract'},
  ]
  return (
    <Modal show={showCardModal} onHide={handleCloseCardModal} centered size='lg' backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{stagesCardValues ? 'Update Contract' : 'Create a Contract'}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          stageId: stagesCardValues?.stageId || '',
          name: stagesCardValues?.name || '',
          description: stagesCardValues?.description || '',
          contractValue: stagesCardValues?.contractValue || '',
          preBidDate: stagesCardValues?.preBidDate ? new Date(stagesCardValues.preBidDate) : null,
          questionDeadline: stagesCardValues?.questionDeadline
            ? new Date(stagesCardValues.questionDeadline)
            : null,
          dueDate: stagesCardValues?.dueDate ? new Date(stagesCardValues.dueDate) : null,
          workScope: stagesCardValues?.workScope || '',
          contractLink: stagesCardValues?.contractLink || '',
          agency: stagesCardValues?.agency || '',
          contractLocation: stagesCardValues?.contractLocation || '',
          cover: stagesCardValues?.cover || undefined,
          status: stagesCardValues?.status || 'newContract',
          attachments: stagesCardValues?.attachments || [],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, {resetForm}) => {
          try {
            const payload: any = {
              name: values.name,
              preBidDate: rewriteDateInOtherTimezone(values.preBidDate, currentTimezone),
              questionDeadline: rewriteDateInOtherTimezone(
                values.questionDeadline,
                currentTimezone
              ),
              dueDate: rewriteDateInOtherTimezone(values.dueDate, currentTimezone),
              workScope: values.workScope,
              contractLink: values.contractLink,
              contractValue: parseInt(values.contractValue),
              agency: values.agency,
              contractLocation: values.contractLocation,
              description: values.description,
              status: values.status,
              cover: values.cover,
              attachments: values.attachments,
            }

            if (stagesCardValues) {
              await updateCard.mutateAsync({
                pipelineId: pipelineId,
                stageId: stageId,
                cardId: stagesCardValues?.uuid,
                data: payload,
              })
              handleCloseCardModal()
              resetForm()
            } else {
              if (!values.stageId) {
                setShowError(true)
              } else {
                await createCard.mutateAsync({
                  pipelineId: pipelineId,
                  stageId: values.stageId.value,
                  data: payload,
                })
                handleCloseCardModal()
                resetForm()
              }
            }
          } catch (error) {
            console.error(error)
          }
        }}
      >
        {({setFieldValue, values, handleChange}) => (
          <FormikForm>
            <Modal.Body>
              <Form.Group className='mb-3' controlId='formGroupName'>
                <label htmlFor='name' className='fw-bold fs-6 required'>
                  Name of Contract
                </label>
                <Field
                  as={Form.Control}
                  name='name'
                  type='text'
                  placeholder='Contract Name....'
                  maxLength={50}
                />
                <ErrorMessage name='name' component='div' className='text-danger' />
              </Form.Group>
              <div className='row'>
                <div className='col-6 my-3'>
                  <label htmlFor='preBidDate' className='fw-bold fs-6'>
                    Pre-bid Meeting
                  </label>
                  <DatePicker
                    className='form-control'
                    selected={values.preBidDate}
                    placeholderText='Select pre bid date'
                    onChange={(date) => setFieldValue('preBidDate', date)}
                    dateFormat='yyyy-MM-dd HH:mm:ss'
                    timeFormat='HH:mm:ss'
                    timeIntervals={30}
                    showTimeSelect
                  />
                </div>

                <div className='col-6 my-3'>
                  <label htmlFor='questionDeadline' className='fw-bold fs-6'>
                    Questions Deadline
                  </label>
                  <DatePicker
                    className='form-control'
                    selected={values.questionDeadline}
                    placeholderText='Select questions deadline date'
                    onChange={(date) => setFieldValue('questionDeadline', date)}
                    dateFormat='yyyy-MM-dd HH:mm:ss'
                    timeFormat='HH:mm:ss'
                    timeIntervals={30}
                    showTimeSelect
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-6 my-3'>
                  <label htmlFor='contractValue' className='fw-bold fs-6'>
                    Contract Value
                  </label>
                  <Form.Control
                    type='number'
                    placeholder='Contract Value....'
                    value={values.contractValue}
                    onChange={(e) => setFieldValue('contractValue', e.target.value)}
                  />
                  <ErrorMessage name='contractValue' component='div' className='text-danger' />
                </div>
                <div className='col-6 my-3'>
                  <label htmlFor='dueDate' className='fw-bold fs-6 required'>
                    Bid Due Date
                  </label>
                  <DatePicker
                    className='form-control'
                    selected={values.dueDate}
                    placeholderText='Select bid due date'
                    onChange={(date) => setFieldValue('dueDate', date)}
                    dateFormat='yyyy-MM-dd HH:mm:ss'
                    timeFormat='HH:mm:ss'
                    timeIntervals={30}
                    showTimeSelect
                  />
                  <ErrorMessage name='dueDate' component='div' className='text-danger' />
                </div>
              </div>
              <div className='row'>
                <div className='col-6 my-3'>
                  <label htmlFor='workScope' className='fw-bold fs-6 required'>
                    Scope of Work
                  </label>
                  <Form.Control
                    type='text'
                    placeholder='Scope of Work....'
                    value={values.workScope}
                    onChange={(e) => setFieldValue('workScope', e.target.value)}
                  />
                  <ErrorMessage name='workScope' component='div' className='text-danger' />
                </div>
                <div className='col-6 my-3'>
                  <label htmlFor='contractLink' className='fw-bold fs-6 required'>
                    Contract Link
                  </label>
                  <Form.Control
                    type='text'
                    placeholder='Contract Link....'
                    value={values.contractLink}
                    onChange={(e) => setFieldValue('contractLink', e.target.value)}
                  />
                  <ErrorMessage name='contractLink' component='div' className='text-danger' />
                </div>
              </div>
              <div className='row'>
                <div className='col-6 my-3'>
                  <label htmlFor='agency' className='fw-bold fs-6 required'>
                    Agency
                  </label>
                  <Select
                    options={agencyOptions}
                    value={agencyOptions.find((option) => option.value === values.agency)}
                    onChange={(selectedOption) => setFieldValue('agency', selectedOption?.value)}
                  />
                  <ErrorMessage name='agency' component='div' className='text-danger' />
                </div>
                <div className='col-6 my-3'>
                  <label htmlFor='contractLocation' className='fw-bold fs-6 required'>
                    Place of Performance/Location
                  </label>
                  <Form.Control
                    type='text'
                    placeholder='Place of Performance/Location'
                    value={values.contractLocation}
                    onChange={(e) => setFieldValue('contractLocation', e.target.value)}
                  />
                  <ErrorMessage name='contractLocation' component='div' className='text-danger' />
                </div>
              </div>
              <div className='row'>
                {pipelineStages?.length > 0 && (
                  <div className='col-12 my-3'>
                    <label htmlFor='stageId' className='fw-bold fs-6 required'>
                      Select Stage
                    </label>
                    <Select
                      options={stageOptions}
                      className='text-black'
                      name='stageId'
                      onChange={(selectedOptions) => {
                        setFieldValue('stageId', selectedOptions)
                      }}
                    />
                    {showError && <p className='text-danger'>Stage is required</p>}
                  </div>
                )}
              </div>
              <div className='my-3'>
                <label htmlFor='status' className='fw-bold fs-6'>
                  Status
                </label>
                <div className='d-flex mt-2 mb-4 overflow-x-scroll'>
                  {statusOptions?.map((option: any) => (
                    <button
                      key={option.value}
                      type='button'
                      className='mx-2 btn px-2 py-1 text-fs-12 fw-bold status-btn'
                      style={{
                        backgroundColor:
                          values.status === option.value
                            ? statusClassMap[values.status]
                            : 'transparent',
                        borderLeft: `4px solid ${statusClassMap[option.value]}`,
                        color: option.value === values.status ? 'white' : 'black',
                      }}
                      onClick={() => setFieldValue('status', option.value)}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
                <ErrorMessage name='status' component='div' className='text-danger' />
              </div>
              <div className='mt-5'>
                <label htmlFor='description' className='fw-bold fs-6'>
                  Additional Notes
                </label>
                <EditorIndex
                  value={values.description}
                  name='description'
                  handleChange={handleChange}
                  initialValue={stagesCardValues?.description}
                  resourceType='pipelines'
                  isUploading={isFileUploading}
                  setIsUploading={setIsFileUploading}
                  onFileUpload={(result) =>
                    setFieldValue('attachments', [...values.attachments, result])
                  }
                />
                <ErrorMessage name='description' component='div' className='text-danger' />
              </div>
              <div className='mt-3'>
                <Attachments
                  attachments={values.attachments || stagesCardValues?.attachments}
                  hideDeleteIcon={true}
                  handleDeleteFile={(index: number) => {
                    const updatedAttachments = [...values.attachments]
                    updatedAttachments.splice(index, 1)
                    setFieldValue('attachments', updatedAttachments)
                  }}
                  isUploading={isFileUploading}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseCardModal}
                disabled={updateCard.isPending || isFileUploading}
              >
                Close
              </Button>
              <Button
                variant='primary'
                type='submit'
                disabled={updateCard.isPending || isFileUploading}
              >
                {updateCard.isPending ? 'Updating...' : 'Submit'}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  )
}

export default PipeLineCardForm
