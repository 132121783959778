import PipelineCardCommentsRow from './PipelineCardCommentsRow'
import {useGetAllPipelineStageCardComments} from '../../../queries/pipelines'
import PipelineCardCommentForm from './PipelineCardCommentForm'
import {useState} from 'react'

const PipelineCardComments = ({
  pipelineId,
  stageId,
  cardId,
  pipelineNotificationsResult,
}: {
  pipelineId: string
  stageId: string
  cardId: string
  pipelineNotificationsResult: any
}) => {
  const [replyOpenId, setReplyOpenId] = useState<string | null>(null) // Change to track comment ID
  const {data: commentsResult} = useGetAllPipelineStageCardComments(pipelineId, stageId, cardId)

  const comments = commentsResult?.comments

  const toggleReplyField = (commentId: string) => {
    setReplyOpenId((prevId) => (prevId === commentId ? null : commentId))
  }

  return (
    <div className='mt-5'>
      <div className='content-divider mb-3'></div>
      <h3>Discussion:</h3>
      <div className='comments-section'>
        {comments?.length < 1 && 'Be the first to start discussion!'}
        {comments?.map((comment: any) => (
          <div key={comment.id}>
            <PipelineCardCommentsRow
              comment={comment}
              pipelineId={pipelineId}
              stageId={stageId}
              cardId={cardId}
              pipelineNotificationsResult={pipelineNotificationsResult}
              toggleReplyField={toggleReplyField}
            />
            {comment.replies?.map((reply: any) => (
              <PipelineCardCommentsRow
                comment={reply}
                key={reply.id}
                isReply={true}
                pipelineId={pipelineId}
                stageId={stageId}
                cardId={cardId}
                pipelineNotificationsResult={pipelineNotificationsResult}
                toggleReplyField={toggleReplyField}
              />
            ))}
            {replyOpenId === comment.id && (
              <PipelineCardCommentForm
                pipelineId={pipelineId}
                stageId={stageId}
                cardId={cardId}
                parentComment={comment}
                toggleReplyField={() => toggleReplyField(comment.id)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default PipelineCardComments
