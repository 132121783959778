import {useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {Card, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'

import './HeaderWrapper.css'
import Image from '../../../modules/common/Image'
import {useGetUserNotifications, useReadUserNotification} from '../../../queries/notifications'
import moment from 'moment'

const HeaderNotificationMenu = ({notifications}: {notifications: any; isFetching: boolean}) => {
  const notificationMenuRef = useRef<HTMLDivElement>(null)
  const [userNotifications, setUserNotifications] = useState(notifications)
  const {selectedCommunity, unreadNotificationsCount} = useAuth()
  const readUserNotificationMutation = useReadUserNotification()
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL

  useEffect(() => {
    setUserNotifications(notifications)
  }, [notifications])

  const readUserNotification = async function (notificationId?: number) {
    const previousNotifications = [...userNotifications]
    try {
      const newNotifications = userNotifications.map((notification: any) => {
        if (notification.id === notificationId || !notificationId) {
          return {...notification, readStatus: true}
        }
        return notification
      })
      setUserNotifications(newNotifications)
      await readUserNotificationMutation.mutateAsync({})
    } catch {
      setUserNotifications(previousNotifications)
    }
  }

  return (
    <div
      ref={notificationMenuRef}
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 notification-card mt-3'
      data-kt-menu='true'
    >
      <Card>
        <div className='container d-flex justify-content-between align-items-center py-3'>
          <div>
            <h3>Notifications</h3>
          </div>
          <div className='d-flex align-items-center'>
            {unreadNotificationsCount > 0 && (
              <div
                onClick={() => readUserNotification()}
                className='text-primary cursor-pointer fs-7 me-3'
              >
                Mark all as read
              </div>
            )}
          </div>
        </div>
        <Card.Body className='p-0'>
          <div className='content-divider'></div>
          <div className='overflow-y-scroll' style={{height: '400px'}}>
            {userNotifications?.map((notification: any) => (
              <div
                key={notification.id}
                className='d-flex align-items-center cursor-pointer border-bottom border-dark-subtle py-4 px-6 container user-card'
              >
                <Image
                  className='img-fluid h40 w40 rounded-circle me-3 object-cover object-top'
                  src={
                    notification.sender?.absoluteProfilePath ||
                    `${s3BaseUrl}${selectedCommunity?.iconPic}`
                  }
                  alt='user-img'
                />
                <div className='flex-grow-1'>
                  <div className='d-flex align-items-center mb-1'>
                    <p className='m-0 fw-bold fs-6'>{notification.title}</p>
                    <p className='fw-bold fs-7 mx-1 my-0 text-muted'>
                      • {moment(notification.createdAt).fromNow()}
                    </p>
                  </div>
                  <p className='m-0 fw-normal'>{notification.description}</p>
                </div>
                {!notification.readStatus && (
                  <div className='ms-2'>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip id='tooltip-top'>Mark as read</Tooltip>}
                    >
                      <div
                        style={{width: '12px', height: '12px'}}
                        className='rounded-circle bg-primary cursor-pointer'
                        onClick={() => readUserNotification(notification.id)}
                      ></div>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            ))}
            {notifications?.length === 0 && (
              <div className='py-6 px-9'>
                <p>You currently have no notifications!</p>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

const HeaderNotification = ({btnClass}: any) => {
  const notificationResult = useGetUserNotifications()
  const {unreadNotificationsCount} = useAuth()

  const notifications = notificationResult?.data?.notifications || []
  const userAvatarClass = 'symbol-30px symbol-md-40px'
  return (
    <div>
      <div
        className={clsx(
          'cursor-pointer symbol',
          userAvatarClass,
          notifications.length && 'position-relative'
        )}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
      >
        <div className='position-relative'>
          <img
            src='/media/svg/general/bell.svg'
            alt=''
            width={'26px'}
            height={'26px'}
            className='mx-1'
          />
          {unreadNotificationsCount > 0 && (
            <span
              className='position-absolute mt-2
               start-50 translate-middle badge rounded-circle bg-danger border border-secondary messages-badge'
              style={{marginLeft: '6px'}}
            >
              <span className='visually-hidden'>New notifications</span>
            </span>
          )}
        </div>
      </div>
      <HeaderNotificationMenu
        notifications={notifications}
        isFetching={notificationResult.isFetching}
      />
    </div>
  )
}

export default HeaderNotification
