import {useState} from 'react'
import {Modal} from 'react-bootstrap'

import FeedComments from './FeedComments'
import {useAuth} from '../../../modules/auth'
import {FormatedDateDifference} from '../../../modules/common/utils'
import FeedAttachments from './FeedAttachments'
import FeedForm from './FeedForm'
import {useDeleteFeed, useUpdateFeed} from '../../../queries/feeds'
import {useDisableComment} from '../../../queries/feedComments'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import Image from '../../../modules/common/Image'
import GenericModal from '../../../modules/common/Modal'
import FeedLikesCard from './FeedLikesCard'
import FeedCommentLikeAction from './FeedCommentLikeAction'
import FeedCommentsForm from './FeedCommentsForm'

interface FeedDetailModalProps {
  feed: any
  showFeedDetailModal: boolean
  handleModalClose: any
}

const FeedDetailModal: React.FC<FeedDetailModalProps> = (props) => {
  const {currentUser, selectedCommunity} = useAuth()

  const [showDescription, setShowDescription] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [showEditFeedModal, setShowEditFeedModal] = useState(false)
  const [showLevelDetailModal, setShowLevelDetailModal] = useState(false)
  const [commentsEnabled, setCommentsEnabled] = useState(props.feed.commentsEnabled)

  const {mutateAsync: deleteFeedMutation} = useDeleteFeed()
  const updateFeedMutation = useUpdateFeed()
  const updateDisableCommentMutation = useDisableComment()

  const handleFeedPin = async () => {
    updateFeedMutation
      .mutateAsync({
        feedId: props.feed?.id,
        feedData: {
          title: props.feed?.title,
          description: props.feed?.description,
          attachments: props.feed?._attachments,
          isPinned: !props.feed?.isPinned,
        },
      })
      .catch((e: any) => {
        console.error(e, 'could not pin the feed')
      })
  }

  const disableComment = async () => {
    updateDisableCommentMutation
      .mutateAsync({
        feedId: props.feed?.id,
        feedData: {
          commentsEnabled: !commentsEnabled,
        },
      })
      .then(() => {
        setCommentsEnabled(!commentsEnabled)
      })
      .catch((e: any) => {
        console.error(e, 'could not disable comments')
      })
  }

  const RenderEditFeedModal = () => {
    return (
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter-edit'
        centered
        show={showEditFeedModal}
        onHide={() => setShowEditFeedModal(false)}
        enforceFocus={false}
        backdrop='static'
      >
        <Modal.Header
          style={{paddingTop: '16px', paddingBottom: '1s0px'}}
          className='m-0 px-5 border-bottom-0'
        >
          <div className='d-flex'>
            <Image src={`${currentUser?.absoluteProfilePath}`} />
            <div className='d-block mx-4'>
              <h3 className='m-0 text-white text-fs-16'>
                {currentUser?.firstName} {currentUser?.lastName}
              </h3>
              <p className='text-white m-0 text-fs-12'>{'updating feed...'}</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='px-5 py-3'>
          <FeedForm feed={props.feed} setShowCreateFeedModal={() => setShowEditFeedModal(false)} />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={props.showFeedDetailModal}
        onHide={() => props.handleModalClose()}
      >
        <Modal.Header className='feed-detail-header'>
          <div className='d-flex justify-content-between align-items-start w-100 mx-2'>
            <div className='d-flex align-items-start'>
              <Image
                src={`${props.feed?.user?.absoluteProfilePath}`}
                className='feed-profile-pic rounded-circle object-cover'
              />
              <div className='mx-3'>
                <p className='mx-2 text-white fw-bold my-0 text-fs-16'>
                  {props.feed?.user?.firstName} {props.feed?.user?.lastName}
                </p>
                <p className='mx-2 text-white text-fs-12'>
                  {' '}
                  Posted {FormatedDateDifference(props.feed?.createdAt)}
                </p>
              </div>
            </div>
            <div className='d-flex flex-row justify-content-end gap-2 centered-class'>
              {(selectedCommunity?.isCommunityAdmin || currentUser?.id === props.feed?.userId) && (
                <>
                  <div className='dropdown m-0 p-0'>
                    <button
                      className='btn btn-transparent btn-sm'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <i className='fa-solid fs-2 text-white fa-ellipsis p-0 m-0'></i>
                    </button>
                    <ul className='dropdown-menu'>
                      {currentUser?.id === props.feed?.userId && (
                        <>
                          <li
                            className='dropdown-item text-fs-13 cursor-pointer'
                            onClick={() => setDeleteConfirmation(true)}
                          >
                            <i className='fa fa-trash mx-2'></i> Delete
                          </li>
                          <li
                            className='dropdown-item text-fs-13 cursor-pointer'
                            onClick={() => {
                              props.handleModalClose()
                              setShowEditFeedModal(true)
                            }}
                          >
                            <i className='fa fa-pencil mx-2'></i> Edit
                          </li>
                        </>
                      )}
                      {selectedCommunity?.isCommunityAdmin && (
                        <li
                          className='dropdown-item text-fs-13 cursor-pointer'
                          onClick={handleFeedPin}
                        >
                          <i className='thumbtach-icon fa-solid fa-thumbtack mx-2'></i>{' '}
                          {props.feed?.isPinned ? 'Unpin from feed' : 'Pin to feed'}
                        </li>
                      )}
                      {selectedCommunity?.isCommunityAdmin && (
                        <li
                          className='dropdown-item text-fs-13 cursor-pointer'
                          onClick={disableComment}
                        >
                          <i className=' fa-solid fa-power-off mx-2'></i>{' '}
                          {commentsEnabled ? 'Disable Comments' : 'Enable Comments'}
                        </li>
                      )}
                    </ul>
                  </div>
                </>
              )}
              <div>
                <i
                  className='fa-solid fa-xmark fs-2 text-white cursor-pointer'
                  onClick={() => props.handleModalClose()}
                ></i>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='modal-body-scroll'>
          <div>
            <p className='text-fs-20 fw-bolder'>{props.feed?.title}</p>
            <div className='my-3'>
              {showDescription && (
                <p className='fs-4' dangerouslySetInnerHTML={{__html: props.feed?.description}}></p>
              )}
              {!showDescription && (
                <div>
                  <p
                    className='text-fs-16'
                    style={{fontWeight: '400'}}
                    dangerouslySetInnerHTML={{
                      __html:
                        props.feed?.description?.length > 500
                          ? `${props.feed?.description.slice(0, 500)}...`
                          : props.feed?.description.slice(0, 500),
                    }}
                  ></p>
                </div>
              )}
              {props.feed?.description?.length > 500 && (
                <span
                  onClick={() => setShowDescription(!showDescription)}
                  style={{color: '#0BAACA'}}
                  className='m-0 cursor-pointer fs-5 d-inline'
                >
                  {showDescription ? 'Read Less' : 'Read More'}
                </span>
              )}
              <div className='d-flex align-items-center overflow-x-scroll w-100'>
                <FeedAttachments attachments={props.feed?._attachments} hideDeleteIcon={false} />
              </div>
            </div>
            <div>
              <FeedCommentLikeAction
                feed={props.feed}
                setShowLevelDetailModal={setShowLevelDetailModal}
              />

              <div className='my-5'>
                <FeedComments feedId={props.feed?.id} commentsEnabled={commentsEnabled} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='feed-comment-input'>
          {commentsEnabled && <FeedCommentsForm feedId={props.feed?.id} />}
          {!commentsEnabled && (
            <div>
              <p className='text-muted fw-bold mt-8'>Comments are turned off for this feed</p>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      {deleteConfirmation && (
        <ConfirmationModal
          showModal={deleteConfirmation}
          onHideModal={() => setDeleteConfirmation(false)}
          disableBtns={false}
          onConfirm={async () => {
            await deleteFeedMutation(props.feed.id)
            setDeleteConfirmation(false)
            props.handleModalClose()
          }}
          onCancel={() => setDeleteConfirmation(false)}
        />
      )}

      {RenderEditFeedModal()}
      <GenericModal
        hideFooter
        title={`${props.feed.feedLikes.length} Likes`}
        onHideModal={() => setShowLevelDetailModal(false)}
        backdrop={true}
        showModal={showLevelDetailModal}
      >
        <FeedLikesCard likes={props.feed.feedLikes} />
      </GenericModal>
    </>
  )
}

export default FeedDetailModal
