import {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {useAuth} from '../../modules/auth'
import {ProfileDetails} from '../../modules/accounts/components/settings/cards/ProfileDetails'
import ChangePassword from '../../modules/accounts/components/settings/cards/ChangePassword'
import {isProfileIncomplete} from '../../modules/common/utils'
import NotificationSettings from '../../modules/notification/NotificationSettings'
import TeamMembers from '../members/TeamMembers'

export function SettingsIndex() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const tab = searchParams.get('tab')
  const [currentTab, setCurrentTab] = useState(tab || 'profile')
  const {currentUser, selectedCommunity} = useAuth()
  const canManageInvites = selectedCommunity?.permissions.canManageInvites
  const canManageCategories = selectedCommunity?.permissions.canManageCategories

  useEffect(() => {
    if (tab === 'invites' && !canManageInvites) {
      navigate('?tab=profile')
    }

    if (tab === 'categories' && !canManageCategories) {
      navigate('?tab=profile')
    }
  }, [])

  useEffect(() => {
    setCurrentTab(tab || 'profile')
  }, [tab])

  const isProfileIncompleted = isProfileIncomplete(currentUser)

  return (
    <>
      <div className='d-flex' id='kt_profile_details_view'>
        <div className='p-9 rounded w300 h-fit mr10 bg-white'>
          <h2 className='text-center mb-3'>Your Settings</h2>
          <ul className={`nav flex-column ${isProfileIncompleted && 'incomplete-profile'}`}>
            <li className='nav-item'>
              <button
                onClick={() => navigate('?tab=profile')}
                className={`${
                  currentTab === 'profile' ? 'btn-secondary' : 'btn-transparent'
                } btn rounded-0 m-1 p-4 w-100`}
              >
                Profile
              </button>
            </li>
            <li className='nav-item'>
              <button
                onClick={() => navigate('?tab=password')}
                className={`${
                  currentTab === 'password' ? 'btn-secondary' : 'btn-transparent'
                } btn rounded-0 m-1 p-4 w-100`}
              >
                Password
              </button>
            </li>
            <li className='nav-item'>
              <button
                onClick={() => navigate('?tab=notification')}
                className={`${
                  currentTab === 'notification' ? 'btn-secondary' : 'btn-transparent'
                } btn rounded-0 m-1 p-4 w-100`}
              >
                Notifications
              </button>
            </li>
            {!selectedCommunity?.isCommunityAdmin &&
              !selectedCommunity?.isModerator &&
              !selectedCommunity?.isTeamMember && (
                <li className='nav-item'>
                  <button
                    onClick={() => navigate('?tab=members')}
                    className={`${
                      currentTab === 'members' ? 'btn-secondary' : 'btn-transparent'
                    } btn rounded-0 m-1 p-4 w-100`}
                  >
                    Team Members
                  </button>
                </li>
              )}
          </ul>
        </div>
        {currentTab === 'profile' && <ProfileDetails />}
        {currentTab === 'password' && <ChangePassword />}
        {currentTab === 'notification' && <NotificationSettings />}
        {!selectedCommunity?.isCommunityAdmin &&
          !selectedCommunity?.isModerator &&
          !selectedCommunity?.isTeamMember &&
          currentTab === 'members' && <TeamMembers />}
      </div>
    </>
  )
}
