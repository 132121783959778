import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import * as usersApi from '../apis/users'
import {USER_STRIPE_PORTAL_QUERY_KEY, USER_SEARCHES_QUERY_KEY} from '../constants/reactQueryKeys'
import {parseError} from '../modules/common/utils'

export const useUpdateUserPassword = () => {
  return useMutation({
    mutationFn: (data: {oldPassword: string; newPassword: string}) =>
      usersApi.updateUserPassword(data),
    onSuccess: () => {
      toast.success('Password updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useGetStripePortal = (userId: number | undefined) => {
  return useQuery({
    queryKey: [USER_STRIPE_PORTAL_QUERY_KEY, {userId}],
    queryFn: () => usersApi.getStripePortal(),
    enabled: !!userId,
    refetchInterval: 120 * 1000,
  })
}

export const useGetAllUserSearches = () => {
  return useQuery({
    queryKey: [USER_SEARCHES_QUERY_KEY],
    queryFn: () => usersApi.getAllUserSearches(1, 1000),
  })
}

export const useGetUserSearchByKeyword = (keyword: string) => {
  return useQuery({
    queryKey: [USER_SEARCHES_QUERY_KEY, keyword],
    queryFn: () => usersApi.getUserSearchByKeyword(keyword),
    enabled: !!keyword,
  })
}

export const useCreateUserSearch = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (keyword: string) => usersApi.createUserSearch(keyword),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [USER_SEARCHES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdateUserSearch = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({searchId, keyword}: {searchId: number; keyword: string}) =>
      usersApi.updateUserSearch(searchId, keyword),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [USER_SEARCHES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdateUserSearchOrder = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({searchId, order}: {searchId: number; order: number}) =>
      usersApi.updateUserSearchOrder(searchId, order),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [USER_SEARCHES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeleteUserSearch = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (searchId: number) => usersApi.deleteUserSearch(searchId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [USER_SEARCHES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}
