import {Formik, Form, Field, ErrorMessage} from 'formik'
import {Button} from 'react-bootstrap'
import * as Yup from 'yup'

import {useUpdateUserPassword} from '../../../../../queries/users'

const ChangePassword = () => {
  const {mutate: updateUserPassword, isPending} = useUpdateUserPassword()

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
      .required('Confirm Password is required'),
  })

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  return (
    <div className='w-100'>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, {resetForm}) => {
          updateUserPassword(values)
          resetForm()
        }}
      >
        {() => (
          <Form>
            <div>
              <label htmlFor='oldPassword' className='m-1 fw-bold fs-6 required'>
                Current Password
              </label>
              <Field
                type='password'
                name='oldPassword'
                className='form-control m-1'
                placeholder='Current Password'
              />
              <ErrorMessage name='oldPassword' component='div' className='text-danger' />
            </div>

            <div className='mt-5'>
              <label htmlFor='newPassword' className='m-1 fw-bold fs-6 required'>
                New Password
              </label>
              <Field
                type='password'
                name='newPassword'
                className='form-control m-1'
                placeholder='New Password here'
              />
              <ErrorMessage name='newPassword' component='div' className='text-danger' />
            </div>

            <div className='mt-5'>
              <label htmlFor='confirmNewPassword' className='m-1 fw-bold fs-6 required'>
                Confirm New Password
              </label>
              <Field
                type='password'
                name='confirmNewPassword'
                className='form-control m-1'
                placeholder='Confirm New Password here'
              />
              <ErrorMessage name='confirmNewPassword' component='div' className='text-danger' />
            </div>

            <div className='d-flex justify-content-end my-2 mt-5'>
              <Button variant='primary' type='submit' disabled={isPending}>
                Change Password
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ChangePassword
