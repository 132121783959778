import {useEffect} from 'react'
import {useSearchParams, useNavigate} from 'react-router-dom'

import {useGetAllPipelines} from '../../queries/pipelines'
import PipeLineStages from './components/PipeLineStages'

import './PipeLines.scss'

function PipeLinesIndex() {
  const [searchParams] = useSearchParams()
  const pipelineId: any = searchParams.get('pipelineId')
  const status: any = searchParams.get('status')
  const navigate = useNavigate()

  const {data, isLoading: isPipelinesLoading} = useGetAllPipelines()

  useEffect(() => {
    if (!pipelineId && data?.pipelines?.length) {
      navigate(`?pipelineId=${data?.pipelines[0].uuid}`)
    } else if (!pipelineId && data?.sharedPipelines?.length) {
      navigate(`?pipelineId=${data?.sharedPipelines[0].uuid}`)
    }
  }, [pipelineId, data?.pipelines, navigate])
  return (
    <div className='d-flex flex h-100' id='kt_pipeline_details_view'>
      <PipeLineStages
        pipelines={data}
        isPipelinesLoading={isPipelinesLoading}
        status={status}
        pipelineId={pipelineId}
      />
    </div>
  )
}

export default PipeLinesIndex
