import {Link, useNavigate} from 'react-router-dom'
import moment from 'moment'
import './CommunityIndex.css'
import Image from '../../modules/common/Image'

const CommunityCard = ({users}: any) => {
  const navigate = useNavigate()
  return (
    <div className='d-flex flex-wrap gap-3 w-100 h-fit mt-5 justify-content-center'>
      {users &&
        users.map((user: any) => (
          <div
            key={user?.id}
            className='col-4 px-2 mb-4 connection-card text-center py-2 px-5 mt-8 member-card'
            style={{maxWidth: '260px'}}
          >
            <div>
              <Image
                src={`${user?.absoluteProfilePath}`}
                className='community-card-image rounded-circle object-cover'
              />
            </div>
            <div className='community-card-main-div'>
              <div className='community-card-content-div'>
                <Link to={`/view-profile/${user?.uuid}`}>
                  <p className='p-0 m-0 text-fs-13 text-black fw-bold mt-2'>
                    {user.firstName?.length > 9 ? user.firstName.slice(0, 9) : user.firstName}{' '}
                    {user.lastName?.length > 9 ? user.lastName.slice(0, 9) + '...' : user.lastName}
                  </p>
                </Link>
                <p className='m-0 p-0 text-fs-13 fw-normal'>
                  {user.createdAt && (
                    <>Joined {moment.unix(user.createdAt).format('MMM DD, YYYY')}</>
                  )}
                </p>
                <p
                  className='text-fs-12 d-flex flex-row gap-3 p-0 m-0 justify-content-center
                '
                >
                  {(user.state || user.city) && (
                    <img src='/media/svg/general/locations.svg' alt='' />
                  )}
                  {user.state || ''}
                  {user.state && user.city && ', '}
                  {user.city || ''}
                </p>
                <p className='text-fs-13 fw-bold m-0 p-0'>
                  {user.bio?.length > 30 ? user.bio?.slice(0, 30) + '...' : user.bio || ''}
                </p>
              </div>
              <button
                onClick={() => navigate(`/messages/${user.id}`)}
                className='p-2 mt-2 rounded border-0 bg-primary text-white mb-2 fw-bold align-items-center w-100'
              >
                <img src='/media/svg/general/chatCommunity.svg' alt='' /> Chat
              </button>
            </div>
          </div>
        ))}
    </div>
  )
}

export default CommunityCard
